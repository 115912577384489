import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfirmComponent } from '../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { SmsScheduledUnnamedService } from '../../../services/sms-scheduled-unnamed.service';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-named',
  templateUrl: './named.component.html',
  styleUrls: ['./named.component.scss']
})
export class NamedComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('frame', { static: true }) frame: ModalDirective;
  modalRef: MDBModalRef;

  private resp: any;
  private resp2: any;
  private payload: any;
  private messageContent: any = [];
  private messageReschedule: any = [];
  public countBatch: number;
  public countMsg: number;
  public messagesScheduled: number;
  public idOfSelected: number;
  public clicked: boolean;
  public submitted: boolean;
  public batchNameSelected: any;
  scheduledForm: FormGroup;

  public loading: boolean;
  public loadingfail: boolean;

  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = Date.now();
  scheduledDate = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');

  nameQueue: any = [];
  messageQueue: any = [];
  headMessageQueue = ['Cell Number', 'Message', 'Date to Send', 'Time To Send', ''];
  headNameGroups = ['Batch Name', 'Number of Messages', 'Date to Send', 'Time To Send', '', '', '', ''];

  constructor(
    private smsService: SmsScheduledUnnamedService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
    this.loading = false;
    this.loadingfail = false;
    this.payload = {};
    this.resp = {};
    this.resp2 = {};
    this.countBatch = 0;
    this.countMsg = 0;
    this.messagesScheduled = 0;
    this.idOfSelected = 0;
    this.clicked = false;
    this.submitted = false;
    this.batchNameSelected = {};
    this.loadScheduledGroups();
    

    this.scheduledForm = this.fb.group({
      sms_dateToSend: ['', Validators.required],
      sms_timeToSend: ['11:00', Validators.required]
    });
  }

  get s() {return this.scheduledForm.controls; }
  get sms_dateToSend() {return this.scheduledForm.get('sms_dateToSend'); }
  get sms_timeToSend() {return this.scheduledForm.get('sms_timeToSend'); }

  loadScheduledGroups() {
    this.smsService.getAllScheduledNames()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp = data;
        for (let index = 0; index < this.resp.length; index++) {
          const element = this.resp[index];
          this.messageContent[index] = element;
          this.messageContent[index].sms_dateToSend = this.pipe.transform(element.sms_dteTime2Send, 'yyyy-MM-dd');
          this.messageContent[index].sms_timeToSend = this.pipe.transform(element.sms_dteTime2Send, 'HH:mm:ss');
        }
        this.nameQueue = this.messageContent;
        this.messageContent = [];
        this.countBatch = this.nameQueue.length;
      }, error => {
        this.loadingfail = true;
      }
    ); 
  }

  onViewBatch(id) {
    this.batchNameSelected = {
     sms_batchName:  this.nameQueue[id].sms_batchName
    }
    this.loadScheduledSMS();
    this.clicked = true;
    this.nameQueue = [];
  }

  onSubmitSend() {
    this.submitted = true;

    if (this.scheduledForm.invalid) {
      return;
    }

    this.onRescheduleBatch();
  }

  onRescheduleBatch() {
    this.scheduledDate = this.sms_dateToSend.value + ' ' + this.sms_timeToSend.value + ':00';
    this.messageReschedule = {
      sms_dteTime2Send: this.scheduledDate,
      sms_batchName: this.batchNameSelected.sms_batchName
    }
    this.smsService.RescheduleScheduledBatch(this.messageReschedule)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.frame.hide();
      this.openModalSuccess();
      this.ngOnInit();
    });
    
  }

  onSendBatchNow(id) {
    this.batchNameSelected = {
      sms_batchName:  this.nameQueue[id].sms_batchName
     }
    this.smsService.SendNowScheduledBatch(this.batchNameSelected)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.openModalSuccessNow();
      this.nameQueue.splice(id, 1);
      this.countBatch--;
    });
  }

  onDeleteBatch(id) {
    this.batchNameSelected = {
      sms_batchName:  this.nameQueue[id].sms_batchName
     }
    this.smsService.deleteScheduledBatch(this.batchNameSelected)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.openModalSuccessDelete();
      this.nameQueue.splice(id, 1);
      this.countBatch--;
    });
  }


  onDeleteOne(id) {
    this.payload = {
      sms_batchName: this.batchNameSelected.sms_batchName,
      sms_id:        this.messageQueue[id].sms_id
    }
    this.smsService.deleteOneScheduledMessage(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.openModalSuccessDeleteOne();
      this.messageQueue.splice(id, 1);
      this.countMsg--;
    });
  }


  loadScheduledSMS() {
    this.smsService.getAllScheduledSMSNamed(this.batchNameSelected)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp2 = data;
        for (let index = 0; index < this.resp2.count; index++) {
          const element = this.resp2.rows[index];
          this.messageContent[index] = element;
          this.messageContent[index].sms_dateToSend = this.pipe.transform(element.sms_dteTime2Send, 'yyyy-MM-dd');
          this.messageContent[index].sms_timeToSend = this.pipe.transform(element.sms_dteTime2Send, 'HH:mm:ss');
        }
        this.messageQueue = this.messageContent;
        this.countMsg = this.messageQueue.length;
        this.messageContent = [];
      }
    ); 
  }

  onGoBack() {
    this.clicked = false;
    this.messageQueue = [];
    this.countMsg = 0;
    this.loadScheduledGroups();
  }


  showModal(id) {
    this.batchNameSelected = {
      sms_batchName:  this.nameQueue[id].sms_batchName
     }
    this.frame.show();
  }


  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', amount: 0, description: '', btnText: ''}
    }
  }

  openModalConfirm(id: any) {
    this.modalOptions.data = {
      heading: 'Send this batch now?', 
      content: {
        heading: 'Please confirm that you want to reschedule this ',
        amount: 1,
        description: ' entire batch to be sent now?',
        btnText: 'Do it'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.onSendBatchNow(id);
      } 
    });
  }

  openModalConfirmDelete(id: any) {
    this.modalOptions.data = {
      heading: 'Really remove this entire batch?', 
      content: {
        heading: 'Are you sure you want to unschedule and completely remove this ',
        amount: 1,
        description: ' message batch? Never to be heard from again?',
        btnText: 'Remove it'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.onDeleteBatch(id);
      } 
    });
  }

  openModalConfirmDeleteOne(id: any) {
    this.modalOptions.data = {
      heading: 'Really remove this message?', 
      content: {
        heading: 'Are you sure you want to unschedule and completely remove this ',
        amount: 1,
        description: ' message? Never to be heard from again?',
        btnText: 'Remove it'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.onDeleteOne(id);
      } 
    });
  }


  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Rescheduled!',
      content: {
        heading: 'That message batch you wanted me to reschedule has been successfully rescheduled.',
        btnText: 'Fantastic'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
    this.ngOnInit();
  }

  openModalSuccessNow() {
    this.modalOptionsSuccess.data = {
      heading: 'Sending Batch Just-Now!',
      content: {
        heading: 'That message batch you wanted me to send now has been successfully added to the queue and will be sent Now-Now.',
        btnText: 'now-now?'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  openModalSuccessDelete() {
    this.modalOptionsSuccess.data = {
      heading: 'Removed!',
      content: {
        heading: 'That message batch you wanted me to remove has been been terminated without notice.',
        btnText: 'much obliged'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  openModalSuccessDeleteOne() {
    this.modalOptionsSuccess.data.content.heading = 'Selected message has been successfully removed from the current batch.'
    this.modalOptionsSuccess.data = {
      heading: 'Removed!',
      content: {
        heading: 'That message you wanted liquidated has been turned to water and shipped to Cape Town.',
        btnText: 'happy to help'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  //  ,{
  //   id: '',
  //   title: '',
  //   text: '.<br><br>\
  //   .<br><br>\
  //   <i>- </i><br><br>\
  //   <mark>- </mark>',
  //   attachTo: {element: '.t', on: 'top'},
  //   advanceOn: {selector: '.', event: 'click'},
  //   canClickTarget: false,
  //   modalOverlayOpeningPadding: 10,
    // beforeShowPromise: function() { 
    //   return new Promise(function(resolve) {
    //     setTimeout(function() {
    //       resolve();
    //     }, 200);
    //   });
    // },
  //   buttons: [
  //     {action() {return this.next();},text: 'Next',}
  //   ],
  // }

  ngAfterViewInit() {
  }

}
