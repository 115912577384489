import { Injectable } from '@angular/core';
import Shepherd from "shepherd.js";
import { Router } from "@angular/router";

const phonebookTour2 = new Shepherd.Tour ({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: 'smooth', block: 'center' },
  }, 
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root'
})
export class TutPhonebookViewService {

  constructor(private router: Router) {}

  phonebookTour2() {
    phonebookTour2.addSteps([{
      id: 'start',
      title: 'PART2: Manage Phone Books',
      text: 'This is part 2/2 of the interactive tutorial on phone books.<br><br>\
      Part 2 will cover Managing your phone books - Removing, Adding, Exporting, etc... Real cutting edge stuff.<br><br>\
      <mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {return this.next();},text: 'Next'}
      ],
    },{
      id: 'wut',
      title: 'Before we can start...',
			text: 'I noticed you noticing this green box back here.<br><br> For this guide to work I require your undivided attention.<br><br>\
      <i>- This opened up when you successfully created that phone book that one time.</i><br><br>',
      attachTo: {element: '.succPop', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Let me close it!',}
      ],
    },{
      id: 'wut2',
      title: 'Please close this...',
			text: 'Please click on "AWESOME" to close this meddlesome box.<br><br>\
      <mark>- Do It!</mark>',
			attachTo: {element: '.succBtn', on: 'bottom'},
      advanceOn: {selector: '.succBtn', event: 'click'},
    },{
      id: 'step1',
      title: 'Manage Phone Books',
      text: 'Thank you, If only I could roll my eyes....<br>\
      Anyway, from this page you can manage all of your existing phone books.<br><br>\
      - Specifically this book you just made.<br><br>',
      attachTo: {element: '.mainS9', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step2',
      title: 'Your Phone Book Collection',
      text: 'This is where I like to keep all of your phone books.<br><br>\
      <i>- You may have as many phone books as you like.</i><br><br>',
      attachTo: {element: '.pb2', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step3',
      title: 'Phone Book Name',
      text: 'This column displays the names of all your phone books.<br><br>\
      <i>- I like to use descriptive names so that I can easily identify my phone books.</i><br><br>',
      attachTo: {element: '.pb3', on: 'right'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step4',
      title: 'Total Numbers',
      text: 'This column displays the total amount of contacts contained inside each of your phone books.<br><br>\
      <i>- You may have as many contacts per phone book as you like.</i><br><br>',
      attachTo: {element: '.pb4', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step5',
      title: 'Remove Phone Book',
      text: 'This column contains a button which when pressed removes a phone book completely.<br><br>\
      Use this with caution though, as this action is permanent.<br><br>\
      <i>- Sometimes a clean start is easier than trying to manage chaos.</i><br><br>',
      attachTo: {element: '.pb5', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step6',
      title: 'Edit Phone Book',
      text: 'This column contains a button which will allow you to edit the respective phone book.<br><br>\
      <i>- Because change is the only constant.</i><br><br>',
      attachTo: {element: '.pb6', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step7',
      title: 'Export Phone Book',
      text: 'This column contains a button that when pressed exports the respective phone book in a excel file for you to keep.<br><br>\
      <i>- Neat-o!</i><br><br>',
      attachTo: {element: '.pb7', on: 'left'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step8',
      title: 'Paging Buttons',
      text: 'Once you start racking up loads of phone books you can use these buttons to page through your collection.<br><br>\
      <i>- Like turning the page of a book of phone books.</i><br><br>',
      attachTo: {element: '.pb8', on: 'bottom'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step9',
      title: 'Editing a book',
      text: 'Lets try editing your phone book.<br><br>\
      You can do this by clicking on the "EDIT" button we just discussed.<br><br>\
      <mark>- Please click on "Edit" to continue.</mark><br><br>',
      attachTo: {element: '.pb9', on: 'bottom'},
      advanceOn: {selector: '.pb9a', event: 'click'},
    },{
      id: 'step10',
      title: 'The flip side...',
      text: 'From this page you can manage the contacts of the selected phone book.<br><br>\
      <i>- The name of the current phone book will be displayed in the middle on top.</i><br><br>',
      attachTo: {element: '.pb10', on: 'bottom'},
      beforeShowPromise: function() { 
        return new Promise(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step12',
      title: 'Adding Contacts',
      text: 'To add new contacts into a selected phone book you needn\'t look any further than this button.<br><br>\
      <mark>- Please click on "Add Contacts" when you are ready.</mark>',
      attachTo: {element: '.pb12', on: 'bottom'},
      advanceOn: {selector: '.pb12', event: 'click'},
      modalOverlayOpeningPadding: 5,
    },{
      id: 'step13',
      title: 'Upload Contact File',
      text: 'If you have a contact list handy you have the option to upload your file.<br><br>\
      Please ensure that your file is in the correct format.<br><br>\
      <i>- Pretty cool right! I am sure that this will save you loads of time.</i><br><br>',
      attachTo: {element: '.pb13', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step14',
      title: 'Add Contact Manually',
      text: 'Alternatively - if you prefer the more old-school approach or just like the sound the keys make when you rapidly punch in details.<br><br>\
      Regardless of your reason - you can click on this button to manually enter contact details and add them to the current phone book.<br><br>\
      <i>- This is a safe space free of any judgement.</i><br><br>',
      attachTo: {element: '.pb12', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step15',
      title: 'Example File',
      text: 'If you are not sure about the file format to upload you can click on this button to download an example file.<br><br>',
      attachTo: {element: '.pb15', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next'}
      ],
    },{
      id: 'step16',
      title: 'Your Contacts',
      text: 'This table will contain all of your contacts.<br><br>\
      <i>-  You may add as many contacts to a phone book as you wish.</i><br><br>',
      attachTo: {element: '.pb16', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step17',
      title: 'Contact Numbers',
      text: 'This column displays all your contact numbers.<br><br>\
      <i>- This column is the only required column when creating a phone book and will always be present.</i><br><br>',
      attachTo: {element: '.pb17', on: 'right'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step18',
      title: 'Optional Custom Columns',
      text: 'Columns 2 through colum 5 are your optional column fields.<br><br>\
      <i>- When creating a new phone book you have the option of customizing these columns or to simply keep them empty.</i><br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step19',
      title: 'Remove Contact',
      text: 'This column will contain a remove button for every contact. Clicking on this will remove a contact from your phone book.<br><br>\
      Please note that this action is permanent.<br><br>\
      <i>- Don\'t let one rotten tomato make all the other potatoes go sour.</i><br><br>',
      attachTo: {element: '.pb19', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step20',
      title: 'Edit Contact',
      text: 'This column will contain an edit button for every contact.<br><br>\
      Clicking on this will open a form from which you may edit your contacts to your heart content.<br><br>',
      attachTo: {element: '.pb20', on: 'left'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step21',
      title: 'Paging Buttons',
      text: 'Once you start racking up countless contacts you can use these buttons to page through your collection.<br><br>\
      <i>- Like reading a book of contacts in a phone book.</i><br><br>',
      attachTo: {element: '.pb21', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step22',
      title: 'Well Done! (2/2)',
      text: 'You have just successfully completed Part 2: Managing Phone Books.<br><br>\
      <i>This concludes Part 2/2 of our tutorial - You showed some real skill today, you should be proud!</i><br><br>\
      <mark>*** Don\'t forget to check out the other 3x in depth SMS tutorials covering Sending SMS, Reports and Scheduled SMS respectively.</mark>',
      canClickTarget: false,
      buttons: [
        {action() {return this.complete();},text: 'End Tutorial',}
      ],
    },
  ]);

  
  phonebookTour2.start();

    
  }
}
