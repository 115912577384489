import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guards';

import { CampaignDashComponent } from './home/campaign-dash/campaign-dash.component';
import { NumberLookupComponent } from './sms/reports/number-lookup/number-lookup.component';
import { SentSmsComponent } from './sms/reports/sent-sms/sent-sms.component';
import { ReplySmsComponent } from './sms/reports/reply-sms/reply-sms.component';
import { BuyCreditsComponent } from './sms/credits/buy-credits/buy-credits.component';
import { PurchaseHistoryComponent } from './sms/credits/purchase-history/purchase-history.component';
import { ReplyRoutingComponent } from './sms/reply-routing/reply-routing.component';
import { ManageCampaignComponent } from './shortcode/manage-campaign/manage-campaign.component';
import { LiveReaderComponent } from './shortcode/live-reader/live-reader.component';
import { RandomWinnerComponent } from './shortcode/random-winner/random-winner.component';
import { FirstWinnerComponent } from './shortcode/first-winner/first-winner.component';
import { PrivacyPolicyComponent } from './general/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './general/terms-and-conditions/terms-and-conditions.component';
import { FaqComponent } from './general/faq/faq.component';
import { ContactUsComponent } from './contact/contact-us/contact-us.component';
import { PersonalDetailsComponent } from './account/personal-details/personal-details.component';
import { BillingDetailsComponent } from './account/billing-details/billing-details.component';
import { UsersComponent } from './admin/manage/users/users.component';
import { CampaignsComponent } from './admin/manage/campaigns/campaigns.component';
import { ShortcodesComponent } from './admin/manage/shortcodes/shortcodes.component';
import { ApproveComponent } from './admin/credits-and-voucers/approve/approve.component';
import { SmsBundlesComponent } from './admin/credits-and-voucers/sms-bundles/sms-bundles.component';
import { AddVoucherComponent } from './admin/credits-and-voucers/add-voucher/add-voucher.component';
import { AddTicketCodesComponent } from "./admin/credits-and-voucers/add-ticket-codes/add-ticket-codes.component";
import { Page404Component } from './page404/page404.component';
import { PreferencesComponent } from './account/settings/preferences/preferences.component';
import { ChangePasswordComponent } from './account/settings/change-password/change-password.component';
import { ViewBookComponent } from './sms/send/phone-books/view-book/view-book.component';
import { CreateBookComponent } from './sms/send/phone-books/create-book/create-book.component';
import { SingleSmsComponent } from './sms/send/single-sms/single-sms.component';
import { FromPhonebookComponent } from './sms/send/from-phonebook/from-phonebook.component';
import { UploadFileComponent } from './sms/send/upload-file/upload-file.component';
import { ShortcodeCampaignComponent } from './sms/send/shortcode-campaign/shortcode-campaign.component';
import { NewKeywordCampaignComponent } from './shortcode/new-campaign/new-keyword-campaign/new-keyword-campaign.component';
import { NewInstantWinnerCampaignComponent } from './shortcode/new-campaign/new-instant-winner-campaign/new-instant-winner-campaign.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ReceivedSmsComponent } from './shortcode/reports/received-sms/received-sms.component';
import { CampaignResponsesComponent } from "./shortcode/reports/campaign-responses/campaign-responses.component";
import { CampaignReportComponent } from './shortcode/reports/campaign-report/campaign-report.component';
import { TicketUsageComponent } from './shortcode/reports/ticket-usage/ticket-usage.component';
import { ValidEntriesComponent } from './shortcode/reports/valid-entries/valid-entries.component';
import { UnnamedComponent } from './sms/scheduled/unnamed/unnamed.component';
import { NamedComponent } from './sms/scheduled/named/named.component';
import { TemplatesComponent } from "./sms/templates/templates.component";
import { SetupComponent } from "./email2sms/setup/setup.component";
import { E2sReportComponent } from "./email2sms/e2s-report/e2s-report.component";
import { ViewOptoutComponent } from "./sms/optout/view-optout/view-optout.component";
import { ManageWinnerBlacklistComponent } from "./shortcode/winner-blacklist/manage-winner-blacklist/manage-winner-blacklist.component";


const routes: Routes = [
		{ path: '',
			component: CampaignDashComponent,
			canActivate: [AuthGuard]
		},
		{path: 'dashboard/campaign', component: CampaignDashComponent, canActivate: [AuthGuard]},
		{path: 'sms/send/single-sms', component: SingleSmsComponent, canActivate: [AuthGuard]},
		{path: 'sms/send/from-phonebook', component: FromPhonebookComponent, canActivate: [AuthGuard]},
		{path: 'sms/send/upload-file', component: UploadFileComponent, canActivate: [AuthGuard]},
		{path: 'sms/send/shortcode-campaign', component: ShortcodeCampaignComponent, canActivate: [AuthGuard]},
		{path: 'sms/send/shortcode-campaign/:camp_id', component: ShortcodeCampaignComponent, canActivate: [AuthGuard]},
		{path: 'sms/send/phone-book/view', component: ViewBookComponent, canActivate: [AuthGuard]},
		{path: 'sms/send/phone-book/create', component: CreateBookComponent, canActivate: [AuthGuard]},
		{path: 'sms/reports/number-lookup', component: NumberLookupComponent, canActivate: [AuthGuard]},
		{path: 'sms/reports/sent-messages', component: SentSmsComponent, canActivate: [AuthGuard]},
		{path: 'sms/reports/reply-messages', component: ReplySmsComponent, canActivate: [AuthGuard]},
		{path: 'sms/credits/buy-credits', component: BuyCreditsComponent, canActivate: [AuthGuard]},
		{path: 'sms/credits/purchase-history', component: PurchaseHistoryComponent, canActivate: [AuthGuard]},
		// {path: 'sms/reply-routing', component: ReplyRoutingComponent, canActivate: [AuthGuard]},
		{path: 'sms/templates/manage', component: TemplatesComponent, canActivate: [AuthGuard]},
		{path: 'sms/scheduled/messages/single', component: UnnamedComponent, canActivate: [AuthGuard]},
		{path: 'sms/scheduled/messages/named', component: NamedComponent, canActivate: [AuthGuard]},
		{path: 'sms/optout/view', component: ViewOptoutComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/new/keyword', component: NewKeywordCampaignComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/new/instant-winner', component: NewInstantWinnerCampaignComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/manage', component: ManageCampaignComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/manage/:camp_id', component: ManageCampaignComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/live-reader', component: LiveReaderComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/random-winner', component: RandomWinnerComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/first-winner', component: FirstWinnerComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/reports/received-sms', component: ReceivedSmsComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/reports/campaign-responses', component: CampaignResponsesComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/reports/report', component: CampaignReportComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/reports/report/:camp_id', component: CampaignReportComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/reports/ticket-usage', component: TicketUsageComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/reports/valid-entries', component: ValidEntriesComponent, canActivate: [AuthGuard]},
		{path: 'general/privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthGuard]},
		{path: 'general/terms-and-conditions', component: TermsAndConditionsComponent, canActivate: [AuthGuard]},
		{path: 'general/frequently-asked-questions', component: FaqComponent, canActivate: [AuthGuard]},
		{path: 'contact-us', component: ContactUsComponent, canActivate: [AuthGuard]},
		{path: 'settings/preferences', component: PreferencesComponent, canActivate: [AuthGuard]},
		{path: 'settings/change-password', component: ChangePasswordComponent, canActivate: [AuthGuard]},
		{path: 'account/user-details', component: PersonalDetailsComponent, canActivate: [AuthGuard]},
		{path: 'account/billing-details', component: BillingDetailsComponent, canActivate: [AuthGuard]},
		{path: 'landing', component: LandingPageComponent},
		{path: 'administrator/manage/users', component: UsersComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
		{path: 'administrator/manage/campaigns', component: CampaignsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
		{path: 'administrator/manage/shortcodes', component: ShortcodesComponent, canActivate: [AuthGuard]},
		{path: 'administrator/credits-and-vouchers/approve', component: ApproveComponent, canActivate: [AuthGuard]},
		{path: 'administrator/credits-and-vouchers/sms-bundles', component: SmsBundlesComponent, canActivate: [AuthGuard]},
		{path: 'administrator/credits-and-vouchers/add-vouchers', component: AddVoucherComponent, canActivate: [AuthGuard]},
		{path: 'administrator/credits-and-vouchers/add-ticket-codes', component: AddTicketCodesComponent, canActivate: [AuthGuard]},
		{path: 'email2sms/setup', component: SetupComponent, canActivate: [AuthGuard]},
		{path: 'email2sms/report', component: E2sReportComponent, canActivate: [AuthGuard]},
		{path: 'shortcode/campaign/winner-blacklist/manage', component: ManageWinnerBlacklistComponent, canActivate: [AuthGuard]},
		{ path: '**', component: Page404Component }
	
	
	];




@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload', useHash: true, scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
