import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import { FileUploader } from 'ng2-file-upload';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BlacklistUploadService } from "../../../services/file/blacklist-upload.service";
import { environment } from '../../../../environments/environment';
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';

const URL = environment.fileUpURL + 'blacklist';

@Component({
  selector: 'app-upload-blacklist-modal',
  templateUrl: './upload-blacklist-modal.component.html',
  styleUrls: ['./upload-blacklist-modal.component.scss']
})
export class UploadBlacklistModalComponent implements OnInit, OnDestroy {
  
  modalRef2: MDBModalRef;
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject<any> = new Subject();

  content: {camp_id: number};

  public loading: boolean;

  @Input() progressLoaded: number = 0;
  public uploader: FileUploader = new FileUploader({url: URL, queueLimit: 1, itemAlias: 'file'});

  // Image Variables

public step2: boolean;
 public step3: boolean;
 public initial: boolean;
 public error: string;
 public showButton: boolean;
 public fileNameShow: string;
 public uploadStatus: boolean;
 public uploadError: boolean;
 public endStatus: boolean;
 public filename: string;
 public filenameToRequestRead: string;
 public filenameToConfirmQueue: any;
 public fileType: string;
 public payload: any;
 public confirmed: boolean;

 tempArr: any = [];
 contactCount = 0;
 bad = 0;
 previewContacts: any = []; 

  constructor(public uploadService: BlacklistUploadService, public modalRef: MDBModalRef, private modalService: MDBModalService) {}

  ngOnInit() {
    this.progressLoaded = 0;
    this.step2 = false;
    this.step3 = false;
    this.uploader = new FileUploader({url: URL, queueLimit: 1, itemAlias: 'file'});
    this.payload = {};
    this.loading = false;
    this.initial = false;
    this.showButton = false;
    this.uploadStatus = false;
    this.uploadError = false;
    this.endStatus = false;
    this.confirmed = false;
    this.error = '';
    this.fileNameShow = '';
    this.filename = '';
    this.filenameToRequestRead = '';
    this.fileType = '';
    this.contactCount = 0;
    this.uploader.onAfterAddingFile = (file) => { 
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }
      file.withCredentials = false;
      this.fileNameShow = file.file.name;
      
      this.showButton = true;
    };


    this.uploader.onProgressItem = (progress: any) => {
      this.progressLoaded = progress['progress'];
    };

    this.uploader.onErrorItem = (response) => {
      this.openModalErrorTimeout();
      this.ngOnInit();
      // this.frame2.show();
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.filenameToRequestRead = response;
         this.filename = item.file.name;
         this.uploadStatus = item.isUploaded;
         this.uploadError = item.isError;
         this.step2 = true;
         this.loading = false;
         if (!this.uploadError) {
           this.showButton = false;
         }
     };
  }

  uploadSelectedFile() {
    this.loading = true;
    this.uploader.uploadAll()
  }

  onUploadFileComplete() {
    this.loading = true;
    if (this.filenameToRequestRead === 'File type error') {
      this.openModalErrorFileType();
      this.modalRef.hide();
      this.action.next(false);
      return;
    }

    if (this.filenameToRequestRead === '') {
      window.alert('Please upload a file first');
      this.loading = false;
      return;
    }

    this.payload = {
      filenameToRequestRead: this.filenameToRequestRead,
      camp_id: this.content.camp_id
    }

    this.uploadService.testUpload(this.payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.tempArr = data;
        if (this.tempArr.error === 'All numbers seem invalid')
        {
          this.openModalErrorAllBadEntries();
          this.ngOnInit();
          this.loading = false;
          return;
				}
				
				if (this.tempArr.error === 'No data in file')
        {
          this.openModalErrorNoData();
          this.ngOnInit();
          this.loading = false;
          return;
        }
        this.contactCount = this.tempArr.total;
        this.filenameToConfirmQueue = {filenameToConfirmQueue: this.tempArr.newFilename} ;
       
        for (let index = 0; index < this.tempArr.length; index++) {
          const element = this.tempArr.firstTen[index];

          this.previewContacts.push({
            blacklist_cellno: element.blacklist_cellno,
            camp_id: element.camp_id
          });
        }
        this.loading = false;
        this.step2 = false;
        this.step3 = true;
        
      },
      err => {this.openModalErrorTimeout();
      this.loading = false;}
      );
  }

  onCommitToDb() {
    this.loading = true;
    this.uploadService.commitFile(this.filenameToConfirmQueue)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.loading = false
      this.contactCount = 0;
      this.previewContacts = [];
      this.modalRef.hide();
      this.action.next(true);
      this.openModalSuccess();
    },
    err => {this.openModalErrorTimeout();
    this.loading = false;}
    );
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorTimeout() {
    this.modalOptionsError.data = {
      heading: 'Message Failed To Send...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: 'Please check your network connectivity.',
        fix2: 'Try to refresh your browser.',
        fix3: 'Then try again.',
        btnText: 'it happens'
      }
    }
    this.modalRef2 = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }

  openModalErrorFileType() {
    this.modalOptionsError.data = {
      heading: 'File type error...',
      content: {
        heading: 'Your file was uploaded successfully! Unfortunately it was in the incorrect file format...',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that your file is in .XLSX or .XLS (Excel Spreadsheet).',
        fix2: 'Try to not upload any other format than specified.',
        fix3: 'Then try again.',
        btnText: 'try again'
      }
    }
    this.modalRef2 = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }

  openModalErrorAllBadEntries() {
    this.modalOptionsError.data = {
      heading: 'This is one bad file...',
      content: {
        heading: 'It looks like every single number in your file are bad.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that every number starts with either "0" OR "27".',
        fix2: 'Followed by 9 other digits.',
        fix3: 'Then try again.',
        btnText: 'try again'
      }
    }
    this.modalRef2 = this.modalService.show(ErrorComponent, this.modalOptionsError)
	}
	
	openModalErrorNoData() {
    this.modalOptionsError.data = {
      heading: 'Empty file...',
      content: {
        heading: 'It seems like the file that you have uploaded does not contain any data...',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that all data is on the first sheet of your file.',
        fix2: 'Ensure that the file contains at least one data entry.',
        fix3: '',
        btnText: 'try again'
      }
    }
    this.modalRef2 = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }


  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Numbers Blacklisted Successfully',
      content: {
        heading: 'The numbers you wanted blacklisted has been added to this campaign successfully!',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }
  

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
