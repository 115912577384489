import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MdbStepperComponent, MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { PhonebookService } from '../../../../services/phonebook.service';
import { Router } from "@angular/router";

import { SuccessModalComponent } from '../../../../modals/other/success-modal/success-modal.component';

@Component({
  selector: 'app-create-book',
  templateUrl: './create-book.component.html',
  styleUrls: ['./create-book.component.scss']
})
export class CreateBookComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('stepper', { static: false }) stepper: MdbStepperComponent;

  @HostListener('window:resize') onWindowResize() {

    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }

  modalRef: MDBModalRef;

  formData: FormData;

  private payload: any;
  private colArr: Array<any>;

  public tutorialActive: boolean;
  public isVertical: boolean;
  public loading: boolean;

  constructor(
    private phonebookService: PhonebookService,
    private router: Router,
    private modalService: MDBModalService
  ) {
   
   }

  firstFormGroup: FormGroup;
  customizeFormGroup: FormGroup;

  ngOnInit() {
    this.loading = false;
    this.isVertical = false;
    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
    this.tutorialActive = false;
    this.payload = {};
    this.colArr = [];

    this.firstFormGroup = new FormGroup({
      pbName: new FormControl('', [Validators.required, Validators.maxLength(50)])
    });

    this.customizeFormGroup = new FormGroup({
      pbAddCol1: new FormControl(''),
      pbAddCol2: new FormControl(''),
      pbAddCol3: new FormControl(''),
      pbAddCol4: new FormControl('')
    });
  }

  get f() {return this.firstFormGroup.controls; }
  get c() {return this.customizeFormGroup.controls; }

  get pbName() { return this.firstFormGroup.get('pbName'); }

  get pbAddCol1() {return this.customizeFormGroup.get('pbAddCol1'); }
  get pbAddCol2() {return this.customizeFormGroup.get('pbAddCol2'); }
  get pbAddCol3() {return this.customizeFormGroup.get('pbAddCol3'); }
  get pbAddCol4() {return this.customizeFormGroup.get('pbAddCol4'); }

  onSubmit() {
    this.loading = true;
    if(this.pbAddCol1.value) {
      this.colArr.push({ucgroup_col1: this.pbAddCol1.value});
    } else {this.colArr.push({ucgroup_col1: ''});}

    if(this.pbAddCol2.value) {
      this.colArr.push({ucgroup_col2: this.pbAddCol2.value});
    } else {this.colArr.push({ucgroup_col2: ''});}

    if(this.pbAddCol3.value) {
      this.colArr.push({ucgroup_col3: this.pbAddCol3.value});
    } else {this.colArr.push({ucgroup_col3: ''});}
    
    if(this.pbAddCol4.value) {
      this.colArr.push({ucgroup_col4: this.pbAddCol4.value});
    } else {this.colArr.push({ucgroup_col4: ''});}

    this.payload = {
      ucgoup_name: this.pbName.value,
      ucgroup_col1: this.colArr[0].ucgroup_col1,
      ucgroup_col2: this.colArr[1].ucgroup_col2,
      ucgroup_col3: this.colArr[2].ucgroup_col3,
      ucgroup_col4: this.colArr[3].ucgroup_col4,
    }

    this.phonebookService.createNewPhonebook(this.payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.openModalSuccess();
        this.router.navigate(['/sms/send/phone-book/view'])
      }, error => {this.loading = false;});
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Great Success!',
      content: {
        heading: 'Your phone book has been successfully created!',
        btnText: 'Awesome'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  ngAfterViewInit() {
    
  }

}
