import { Injectable } from '@angular/core';
import Shepherd from "shepherd.js";
import { Router } from "@angular/router";
import { TutorialThreeService } from "../guide/tutorial-three.service";
import { TutPhonebookCreateService } from "../guide/tut-phonebook-create.service";
import { TutPhonebookViewService } from "../guide/tut-phonebook-view.service";

const smsTour2 = new Shepherd.Tour ({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: 'smooth', block: 'center' },
  }, 
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root'
})
export class TutorialTwoService {

  constructor(private router: Router, private tutorialThree: TutorialThreeService, private phonebookService: TutPhonebookCreateService, private phonebookService2: TutPhonebookViewService) {}
		smsTour2() {
			var that = this;
    smsTour2.on('complete', () => {
			this.router.navigate(['/sms/send/upload-file']);
			this.tutorialThree.smsTour3();
		})
    smsTour2.addSteps([{
      id: 'start',
      title: 'PART2: Send To Phone Books',
      text: 'This is part 2/4 of the interactive tutorial covering every component related to sending SMS messages.<br><br>\
      Part 2 will cover Sending Bulk SMS to your contacts in phone books.<br><br>\
      <mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {return this.next();},text: 'Next'}
      ],
    },{
      id: 'step1',
      title: 'Send To Phonebook(s)',
      text: `Send SMS messages by providing the message and selecting contacts from one or multiple phone books to send to.<br><br>\
			<i>- Perfect for sending to specific contact groups</i>.<br><br>
			<mark>To <strong>Skip</strong> the Sending To Phone Books Tour click on "Skip".<br>
			To <strong>Start</strong> the Sending To Phone Books Tour click on "Next".</mark>`,
      attachTo: {element: '.mainS6', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.show('step12');},text: 'Skip',},
        {action() {return this.next();},text: 'Next'}
      ],
    },{
      id: 'step2',
      title: 'Message Field',
      text: 'First you enter the message that you would like to send.<br><br>\
			<i>Your message may only contain between 1 and 160 characters. Avoid using any special characters in your message.</i><br><br>\
			<mark>- Try typing in your own message before we move on to the next step.</mark>',
      attachTo: {element: '.sms2', on: 'top'},
      buttons: [
				{action() {return this.back();},text: 'Back',},
        {action() {{if(document.getElementById('phonebookcheck2') === null) {
					return this.show('step3')} else {
					return this.show('step4');  
					}}},text: 'Next',}
      ],
    },{
      id: 'step3',
      title: 'Phonebook Select',
			text: `Then you would select at least one of your phone books.<br><br> You may select as many phone books as you like.<br><br>
			<mark>- If you currently <strong>do not have any phone books</strong> to select from you can click on "I Don\'t Have Phone books".</mark><br><br>
			<mark>- If you <strong>have selected a phonebook</strong> you can click on "Next".</mark>`,
			attachTo: {element: '.sms3', on: 'top'},
      modalOverlayOpeningPadding: 20,
			canClickTarget: true,
      buttons: [
        {action() {return this.show('stepToPB');},text: 'I Don\'t Have Phone Books.',},
				{action() {return this.show('stepCheck');},text: 'Next',}
      ],
    },{
      id: 'stepCheck',
      title: 'Verifying...',
			text: `Just checking if you have selected a phonebook and that said phonebook contains at least 1x contact.`,
			canClickTarget: false,
      buttons: [
				{action() {if(document.getElementById('phonebookcheck') === null) {
					return this.show('step3')} else {
					return this.show('step4');  
					}},text: 'Next'}
			],
    },{
      id: 'stepToPB',
      title: 'Not To Worry!',
			text: `You have indicated that you don't have any phone books as of yet.<br><br>
			I can quickly take you through the steps to create and manage your own phonebook if you like?<br><br>
			<mark>- To <strong>start the Create Phonebook Tour</strong> click on "Create Phonebook".</mark><br>
			<mark>- To <strong>skip this for now</strong> and move to the next step of the current SMS Tour Click on "Maybe Later".</mark>`,
			canClickTarget: false,
      buttons: [
        {action() {return this.cancel(), that.router.navigate(['/sms/send/phone-book/create']),that.phonebookService.phonebookTour();},text: 'Create Phonebook',},
				{action() {return this.show('step12');},text: 'Maybe Later',}
      ],
    },{
      id: 'step4',
      title: 'Contacts Select',
			text: `After selecting your phonebook(s) all contacts in selected books will be listed here.<br><br> 
			You may select as many contacts to send to as you like.<br><br>
			<mark>- When you <strong>have selected at least 1x contact</strong> you can click on "Next".</mark>`,
			attachTo: {element: '.sms4', on: 'top'},
      modalOverlayOpeningPadding: 20,
			canClickTarget: true,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'stepCheck2',
      title: 'Verifying...',
			text: `Just checking if you have selected at least 1x contact.`,
			canClickTarget: false,
      buttons: [
				{action() {if(document.getElementById('phonebookcheck2') === null) {
					return this.show('step4')} else {
					return this.show('step5');  
					}},text: 'Next'}
			],
    },{
      id: 'step5',
      title: 'When To Send',
      text: 'Then you decide if the message should be sent either instantly or at a chosen time and date.<br><br>\
      <i>This is not 100% similar to scheduling single sms messages. Let\'s have a look see.</i><br><br>\
      <mark>- To continue to the next step please flip this switch over to "Scheduled".</mark>',
      attachTo: {element: '.sms5', on: 'top'},
      advanceOn: {selector: '.sms5a', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'step5Info',
      title: 'Batch Name',
      text: 'This is used to uniquely identify your SMS batches should you want to reschedule or remove them later.<br><br>\
      <i>This is normally a required field if you do schedule batches.</i><br><br>\
      <i>Interaction with the batch name field is disabled for this step.</i>',
			attachTo: {element: '.sms5i', on: 'top'},
			beforeShowPromise: function() {
        return new Promise(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
			canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step5Info2',
      title: 'Schedule Time & Date',
      text: 'Similar to Part 1 you then have to select a date and a time.<br><br>\
      <i>Normally you would select a date and time by clicking on the respective field and selecting a value.</i><br><br>',
      attachTo: {element: '.sms5i2', on: 'top'},
      canClickTarget: false,
      buttons: [
				{action() {return this.back();},text: 'Back',},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step5Info3',
      title: 'Sending messages instantly',
      text: 'Lets say that you want this message to be sent out ASAP; Hypothetically speaking of course.<br><br>\
      <i>Flipping this switch back to "Instantly" will ensure that I get this message out for you almost instantly.</i><br><br>\
      <mark>- To continue to the next step please flip this switch back to "Instantly" again.</mark>',
      attachTo: {element: '.sms5', on: 'top'},
      advanceOn: {selector: '.sms5a', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'step6',
      title: 'Add Message To Queue Button',
      text: 'After completing all of the previous steps you need to click on this button to add your SMS to your queue.<br><br>\
      <i>It should be noted that this queue is local and not yet final. It serves as a preview before you finally submit your queue.</i><br><br>\
      <mark>- Click on "Add Messages To Queue" to load your message into your preview queue.</mark>',
			attachTo: {element: '.sms6', on: 'top'},
      advanceOn: {selector: '.sms6', event: 'click'},
    },{
      id: 'stepCheck3',
      title: 'Verifying...',
      text: 'At this stage you will either see an error pop up or your queue will be displayed.<br><br>\
      If you see a red error popup it means that there are some invalid inputs.<br><br>\
      If not then it means everything is valid and your messages are now in your local queue',
      canClickTarget: false,
      buttons: [
        {action() {if(document.getElementById('errorPop')) {
          return this.show('stepError')} else {
          return this.show('step7');  
          }},text: 'Next'}
      ],
    },{
      id: 'stepError',
      title: 'Oops...',
      text: 'It seems like either your message field, number field, or scheduled switch might be incorrect.<br><br>\
      <mark>- Please click on the "Fix" button and we will circle back to the input fields to try again</mark>.<br><br>',
      attachTo: {element: '.errorBtn', on: 'bottom'},
      canClickTarget: true,
      advanceOn: {selector: '.errorBtn', event: 'click'},
    },{
      id: 'stepError2',
      title: 'Back to the drawing board',
      text: 'If you are sure that everything is correct - try reloading the page if this happens again.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.show('step2');},text: 'Start again',}
      ],
    },{
      id: 'step7',
      title: 'Your Message Queue',
      text: 'After adding a message to your queue it will be displayed here.<br><br>\
      Here you would be able to review your messages, add more messages, remove single messages, remove your entire queue or submit your queue to be sent.<br><br>',
			attachTo: {element: '.sms7', on: 'top'},
			canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step8',
      title: 'Remove A Single Message',
      text: `If you want to remove a single message from your queue you can simply click on "Remove".<br><br>`,
      attachTo: {element: '.sms8', on: 'left'},
      canClickTarget: false,
      buttons: [
        {action() {return this.back();},text: 'Back',},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step9',
      title: 'Submit My Queue',
      text: `Finally, when you are happy with your queue you would click on "Submit My Queue".<br><br>\
      <i>This will submit all messages contained in your queue to be processed to be sent on the date and time you chose.</i><br><br>\
      <i>I don't want to waste your credits so we will not be clicking on this button now.</i><br><br>`,
      attachTo: {element: '.sms9', on: 'top'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {return this.back();},text: 'Back',},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step10',
      title: 'Remove Entire Queue',
      text: 'If you want to remove all messages from your queue you would simply click on "Remove My Queue".<br><br>',
      attachTo: {element: '.sms10', on: 'top'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {return this.back();},text: 'Back',},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step11',
      title: 'Well Done! (2/4)',
      text: 'You have just successfully completed Part 2: Sending SMS To Phone Books.<br><br>\
      <i>This concludes Part 2/4 of our tutorial - Halfway there! I highly recommend that you complete all 4 Parts Of this tutorial.</i><br><br>\
      <mark>- Click on "Upload File" to start part 3 of this tutorial.</mark>',
      buttons: [
        {action() {return this.complete();},text: 'Part 3: Upload File'}
      ],
    },{
      id: 'step12',
      title: 'It\'s okay...',
      text: 'You can always come back to this tutorial at any time.<br><br>\
      <i>I highly recommend that you complete the remaining 2x Parts Of this tutorial.</i><br><br>\
      <mark>- Click on "Upload File" to start part 3 of this tutorial.</mark>',
      buttons: [
        {action() {return this.complete();},text: 'Part 3: Upload File'}
      ],
    },
  ]);

  
  smsTour2.start();

    
  }
   }