import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

const apiURL = environment.navbarUrl;

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };

  constructor(private httpClient: HttpClient) {}

  public getNavInfo() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(apiURL, this.httpOptions);
    }return;
  }

  public getNavCredits() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
          'skip': "true"
        }
      };
      return this.httpClient.get(apiURL + '/credit', this.httpOptions);
    }return;
  }

  public updateTutorial() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
          'skip': "true"
        }
      };
      return this.httpClient.get(apiURL + '/updateTutorial', this.httpOptions);
    }return;
  }
}
