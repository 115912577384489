import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { LoginService } from './login.service';
import { UserLoginModel } from './models/user-login-model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  title = 'Simchat';

  Authorization: UserLoginModel;

  values: string[] = ['Tag 1', 'Tag 2', 'Tag 4'];

  specialPage: boolean;

  private specialPages: any[] = [];

  private currentUrl = '';

  constructor(
    private router: Router,
    private location: Location,
    private authenticationService: LoginService
  ) {

    this.authenticationService.Authorization
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(x => this.Authorization = x);

    this.router.events
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((route: any) => {
      if (route.routerEvent) {
        this.currentUrl = route.routerEvent.url;
      } else {
        this.currentUrl = route.url;
      }
      if (this.currentUrl) {
        this.specialPages = this.currentUrl.match(/^(\/landing\?returnUrl=%2F).*$/);
      }
      this.specialPage = this.specialPages !== null || this.currentUrl === '/landing';
    });
  }

  ngOnInit(): void {
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/landing']);
}

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
