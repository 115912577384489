import { Component, OnInit, OnDestroy, ElementRef, HostListener, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ModalDirective } from 'ng-uikit-pro-standard';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { LoaderService } from '../../../services/loader.service';

import { AdminService } from '../../../services/admin.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss']
})
export class ApproveComponent implements OnInit, OnDestroy, AfterViewInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;
  modalRef: MDBModalRef;

  @ViewChild('frame', { static: true }) frame: ModalDirective;
  @ViewChild('frame2', { static: true }) frame2: ModalDirective;

  elements: Array<any>;
  headElements = ['id', 'first', 'last', 'handle'];

  searchText: string;
  previous: string;
  pipe = new DatePipe('en-ZA'); // Use your own locale
  maxVisibleItems: number = 8;


  private resp: any;
  public rejected: boolean;
  private rejectReason: string;
  private selectedID: number;
  public requests: Array<any>;
  public requestsPending: number;
  private idOfSelected: number;
  private requestInfo: any;
  public loading: boolean;
  public loadingfail: boolean;
  public processing: boolean;
  public isRejection: boolean;

  reasonForm: FormGroup;
  

  headRequests = ['Invoice', 'Request Date', 'User', 'Person',
                   'Contact Detail', 'Trading As', 'Package', 'Reject?', 'Approve?'];

  constructor(private fb: FormBuilder, private creditService: AdminService, private cdRef: ChangeDetectorRef, private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  @HostListener('input') oninput() {
    this.searchItems();
  }


  ngOnInit() {
    this.loadingfail = false;
    this.processing = false;
    this.elements = [];
    this.searchText = '';
    this.previous = '';
    this.resp = {};
    this.rejected = false;
    this.rejectReason = '';
    this.selectedID = 0;
    this.requests = [];
    this.requestsPending = 0;
    this.idOfSelected = 0;
    this.requestInfo = {};

    this.loadRequests();
    this.reasonForm = this.fb.group({
      reason: ['', Validators.required]
    })
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.requests = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.requests = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  get e() { return this.reasonForm.controls; }
  get reason() { return this.reasonForm.get('reason'); }

  loadRequests() {
    this.requests = [];
    this.creditService.getAllPendingRequests()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp = data;
        for (let index = 0; index < this.resp.count; index++) {
          const element = this.resp.rows[index];
          this.requests[index] = element;
          this.requests[index].eft_dteCreated = this.pipe.transform(element.eft_dteCreated, 'yyyy-MM-dd HH:mm:ss');
        }
        this.requestsPending = this.resp.count;
        this.mdbTable.setDataSource(this.requests);
        this.previous = this.mdbTable.getDataSource();

      },
      err => {this.loadingfail = true;}
    ); 
  }

  

  onRejectOne(id){
    this.frame.show();
    this.idOfSelected = this.requests[id].eft_id;
    this.selectedID = id;  
  }

  onConfirmReject() {
    this.rejected = true;

    if (this.reasonForm.invalid){
      return;
    }
    this.rejectReason = this.reason.value;
    this.requestsPending--;
    this.requestInfo = {
      eft_id: this.idOfSelected,
      eft_isRejected: 1,
      eft_reasonRejected: this.rejectReason,
      eft_dteCreated: this.requests[this.selectedID].eft_dteCreated,
      eft_email: this.requests[this.selectedID].eft_email,
      smsp_desc: this.requests[this.selectedID].SmsPack.smsp_desc,
      user_name: this.requests[this.selectedID].User.user_name
    };
    this.requests.splice(this.selectedID, 1);
    this.processing = true;
    this.isRejection = true;
    this.processRequest();
  }
 

  
  onApproveOne(id){
    this.frame2.show();
    this.idOfSelected = this.requests[id].eft_id;
    this.selectedID = id;  
  }


  onConfirmApprove() {
    this.requestsPending--;
    this.requestInfo = {
      eft_id: this.idOfSelected,
      eft_isRejected: 0,
      eft_dteCreated: this.requests[this.selectedID].eft_dteCreated,
      eft_email: this.requests[this.selectedID].eft_email,
      smsp_desc: this.requests[this.selectedID].SmsPack.smsp_desc,
      user_name: this.requests[this.selectedID].User.user_name
    };
    this.requests.splice(this.selectedID, 1);
    this.isRejection = false;
    this.processing = true;
    this.processRequest();
  }

  processRequest() {
    this.creditService.approveOrReject(this.requestInfo)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      if (this.isRejection) {
        this.frame.hide();
      } else {
        this.frame2.hide();
      }
      this.processing = false;
      this.openModalSuccess();
      this.ngOnInit();
    },
    err => {
      this.processing = false;
    }
    );
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Request Processed',
      content: {
        heading: 'Credit request has been successfully processed. An email has been dispatched to the client to notify on the verdict.',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }
  

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
