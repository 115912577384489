
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'ng-uikit-pro-standard';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LiveReaderService } from "../../services/live-reader.service";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ErrorComponent } from '../../modals/other/error/error.component';
import { ConfirmComponent } from "../../modals/other/confirm/confirm.component";
import { SuccessModalComponent } from "../../modals/other/success-modal/success-modal.component";
import { LoaderService } from '../../services/loader.service';
import { CampaignService } from "../../services/campaign.service";

const counter = interval(15000);
@Component({
  selector: 'app-live-reader',
  templateUrl: './live-reader.component.html',
  styleUrls: ['./live-reader.component.scss']
})
export class LiveReaderComponent implements OnInit, AfterViewInit, OnDestroy{
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  modalRef: MDBModalRef;

  elements: any = [];
  previous: any = [];
  headElements = ['Cellphone', 'Name', 'Message', 'Date Received', 'Campaign'];
  private ngUnsubscribe: Subject<any> = new Subject();
  private subscription: Subscription = new Subscription();
  private resp: any;
  private lim: number;
  private off: number;
  private payload: any;
	private currcount: number;
	private tempNr: string;
	private tempImg: string;

  public loading: boolean;
  public loadingtext: string;
  public loadingfail: boolean;
  public refreshCount: number;
  public entryCount: number;

  constructor(
    private liveReader: LiveReaderService,
    private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService,
		private loaderService: LoaderService,
		private campaignService: CampaignService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
		this.tempNr = '';
    this.loading = false;
    this.loadingtext = '';
    this.loadingfail = false;
    this.currcount = 0;
    this.refreshCount = 0;
    this.entryCount = 0;
    this.resp = [];
    this.lim = 2001;
    this.off = 1;
    this.loadAllEntries();
    this.subscription = counter
        .subscribe(() => {
          this.loadAllEntries()
        })
    }

    loadAllEntries() {
      this.loadingfail = false;
      this.loadingtext = 'Refreshing...'
      this.onUnsub()
      this.refreshCount++
      this.payload = {
        lim: this.lim - 1,
        off: this.off - 1
      }
      this.liveReader.getLive(this.payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
					this.resp = data;
					// console.log(this.resp)
					
          if (this.currcount === 0) {
						this.elements = this.resp;
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          } else if (this.resp[this.resp.length - 1] !== this.currcount ) {
            for (let index = (this.resp.length - 1 - this.currcount); index > 0 ; index--) {
              const element = this.resp[index];
              this.elements.unshift(element);
            }
            // this.elements = this.resp;
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          }
          this.entryCount = this.resp[this.resp.length - 1]; 
          this.currcount = this.resp[this.resp.length - 1]
          this.resp = [];
        },
        error => {
          this.loadingfail = true;
          this.loadingtext = 'Connection Error!'
        }
      );
    }

    ngAfterViewInit() {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(2000);
      
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.cdRef.detectChanges();
    }

    onNextPageClick(event) {
      this.lim = this.lim + 2000;
      this.off = this.off + 2000;
      this.currcount = 0;
      this.loadAllEntries();
    }

    onPreviousPageClick(event) {
      this.lim = this.lim - 2000;
      this.off = this.off - 2000;
      this.currcount = 0;
      this.loadAllEntries();
		}
		
		getLink(i) {
			return '<img src="'+this.elements[i].msg+'" width="200" height="200"/>'
    }

    ngOnDestroy(): any {
      this.subscription.unsubscribe();
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
		}
		
		// modalOptionsConfirm = {
    //   backdrop: true,
    //   keyboard: true,
    //   focus: true,
    //   show: false,
    //   ignoreBackdropClick: false,
    //   class: 'modal-dialog modal-notify modal-info',
    //   containerClass: 'modal fade',
    //   animated: true,
    //   data: {
    //       heading: '',
    //       content: { heading: '', amount: 0, description: '', btnText: ''}
    //   }
		// }
		
		// onBlacklistContact(id) {

		// 	this.tempNr = this.elements[id].cell
    //   this.campaignService.AddBlacklistNumber({blacklist_cellno: this.elements[id].cell, camp_id: this.elements[id].camp_id})
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe(
    //     data => {
		// 			this.openModalSuccess(id)	
    //     }
    //   )
    // }

    // openModalConfirmDelete(id: any) {
    //   this.modalOptionsConfirm.data = {
    //     heading: 'Really blacklist this contact?', 
    //     content: {
    //       heading: 'Are you sure you want to prevent ' + this.elements[id].cell + ' from entering',
    //       amount: 0,
    //       description: ' your campaign called ' + this.elements[id].camp + ' in the future?',
    //       btnText: 'Yes, blacklist!'
    //     }
    //   }
    //   this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm)
    //   this.modalRef.content.action
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe( (result: any) => { 
    //     if (result) {
    //       this.onBlacklistContact(id);
    //     } 
    //   });
		// }
		
		// modalOptionsSuccess = {
    //   backdrop: true,
    //   keyboard: true,
    //   focus: true,
    //   show: false,
    //   ignoreBackdropClick: false,
    //   class: 'modal-dialog modal-notify modal-success',
    //   containerClass: 'modal fade',
    //   animated: true,
    //   data: {
    //       heading: '',
    //       content: { heading: '', btnText: ''}
    //   }
    // }
  
    // openModalSuccess(id) {
    //   this.modalOptionsSuccess.data = {
    //     heading: 'No worries',
    //     content: {
    //       heading: 'I just verified that '+this.elements[id].cell+' will not be bothering you anymore.',
    //       btnText: 'Thanks'
    //     }
    //   }
    //   this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
    // }

    modalOptionsError = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-danger',
      containerClass: 'modal fade',
      animated: true,
      data: {
          heading: '',
          content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
      }
    }

    openModalErrorTimeout() {
      this.modalOptionsError.data = {
        heading: 'Refresh Failed...',
        content: {
          heading: 'That last refresh did not complete successfully... This could be due to poor network connectivity',
          suggest: 'Suggested Actions:',
          fix1: 'Please check your network connection.',
          fix2: 'Try to refresh your browser.',
          fix3: 'Then try again.',
          btnText: 'it happens'
        }
      }
      this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
    }

    onUnsub(): any {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

}
