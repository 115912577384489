import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'

const baseUrl   = environment.campaignsUrl;
const baseUrl2  = environment.campaignUrl;
const baseUrl3  = environment.smsInUrl;
@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };


  constructor(private httpClient: HttpClient) {}

  getNextTenBlacklistNumbers(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/blacklistten', payload, this.httpOptions);
    }return;
  }

  getUserCampaigns() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(baseUrl, this.httpOptions);
    }return;
  }

  getUserInstantCampaigns() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(baseUrl + '/instant', this.httpOptions);
    }return;
  }

  updateUserCampaignKeyword(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(baseUrl + '/update/sc', payload, this.httpOptions);
    }return;
  }

  updateUserCampaignInstant(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(baseUrl + '/update/inst', payload, this.httpOptions);
    }return;
  }


  tryToAddNewKeyword(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/keyword', payload, this.httpOptions);
    }return;
  }


  CheckCampaignNameForDuplicates(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
          'skip': "true"
        }
      };
      return this.httpClient.post(baseUrl + '/name/check', payload, this.httpOptions);
    }return;
  }

  CheckCampaignNameForDuplicatesWithID(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json',
          'skip': "true"
        }
      };
      return this.httpClient.post(baseUrl + '/name/checkid', payload, this.httpOptions);
    }return;
  }


  deleteKeywordFromCampaign(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(baseUrl + '/keyword', payload, this.httpOptions);
    }return;
  }


  setNewCampaignReportDays(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/report', payload, this.httpOptions);
    }return;
  }


  deleteOneReportDay(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(baseUrl + '/report', payload, this.httpOptions);
    }return;
  }


  getCampaignNumbers(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl3 + '/campaign/unique', id, this.httpOptions);
    }return;
  }

  getNonDedicatedShortcodes() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(baseUrl + '/keyword/shortcode', this.httpOptions);
    }return;
  }


  getNonDedicatedShortcodesAsAdmin(user_id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/keyword/shortcode', user_id, this.httpOptions);
    }return;
  }
  

  checkIfShortcodeIsSharedOrDedicated(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl2 + '/shortcode/check', id, this.httpOptions);
    }return;
  }

  getKeywordsForSelectedShortcode(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/keyword/check', id, this.httpOptions);
    }return;
  }

  addNewCampaign(content) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/keyword/new', content, this.httpOptions);
    }return;
  }


  addNewTicketCampaign(content) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/instant/new', content, this.httpOptions);
    }return;
  }


  getSelectedCampaignDetails(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/details', id, this.httpOptions);
    }return;
  }

  getInstantWinnerCampaignDetails(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post('/instantcampaigns/details', id, this.httpOptions);
    }return;
  }

  getSelectedCampaignKeywords(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/keywords', id, this.httpOptions);
    }return;
  }


  getSelectedCampaignReportDays(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/reports', id, this.httpOptions);
    }return;
  }

  getSelectedCampaignsms2email(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/sms2email', id, this.httpOptions);
    }return;
  }

  getSelectedCampaignBlacklist(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/blacklist', id, this.httpOptions);
    }return;
  }

  // updateUserCampaign(info) {
  //   if (this.token !== null) {
  //     this.httpOptions = {
  //       headers: {
  //         'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
  //         'Content-Type': 'application/json'
  //       }
  //     };
  //     return this.httpClient.put(baseUrl, info, this.httpOptions);
  //   }return;
  // }


  updateUserInstantWinnerCampaign(info) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(baseUrl + '/instant', info, this.httpOptions);
    }return;
  }

  DeleteKeyword(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(baseUrl + '/keywords', id, this.httpOptions);
    }return;
  }

  AddKeyword(info) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/keywords/add', info, this.httpOptions);
    }return;
  }

  DeleteReportDay(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/reports/delete', id, this.httpOptions);
    }return;
  }

  DeleteAllReportDays(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/reports/delete/all', id, this.httpOptions);
    }return;
  }

  AddReportDay(info) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/reports/add', info, this.httpOptions);
    }return;
  }


  AddReportDaysDaily(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/reports/add/daily', id, this.httpOptions);
    }return;
  }


  DeleteBlacklistNumber(id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(baseUrl + '/blacklist', id, this.httpOptions);
    }return;
  }


  AddBlacklistNumber(info) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/blacklist/add', info, this.httpOptions);
    }return;
  }
}



