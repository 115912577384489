import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MdbTablePaginationComponent, MdbTableDirective } from 'ng-uikit-pro-standard';
import { MDBModalRef, MDBModalService, IMyOptions } from 'ng-uikit-pro-standard';
import {ExcelService} from '../../services/excel.service';
import { LoaderService } from '../../services/loader.service';
import { PhonebookService } from '../../services/phonebook.service';

import { ConfirmComponent } from '../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../modals/other/error/error.component';
import { RandomWinnerEmailModalComponent } from "../../modals/random-winner-email-modal/random-winner-email-modal.component";

import { RandomWinnerService } from '../../services/random-winner.service';

@Component({
  selector: 'app-first-winner',
  templateUrl: './first-winner.component.html',
  styleUrls: ['./first-winner.component.scss']
})
export class FirstWinnerComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;
  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;

  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    closeAfterSelect: true,
    editableDateField: false
  };
  
  public totalOrSelected: boolean;
  public winnersGenerated: boolean;
  public submitted: boolean;
	public loading: boolean;
	public markedWinners: boolean;
  public loadingfail: boolean;
  public totalEntries: number;
	public totalWinners: number;
	public selectCounter: number;
  private selectedCampaignId: number;
  private resp: any;
  private resp2: any;

  
  randomWinnerForm: FormGroup;
  datePickerForm: FormGroup;
  
  tempArr: Array<any>;
  campArr: Array<any>;
	winnerArr: Array<any>;
	selectedWinnerArr: Array<any>;
	optionsMonthsToGoBack: Array<any>;
	selectedValue = '3';
	editField: string;
	public campaignName: string;
	
	payload: any;
	payload2: any;

  headWinners = ['ID', 'Cellphone Number', 'Message', 'Date Received', 'Winner Name', 'Winner?'];

  optionsCampaign: Array<any> = [];

  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = Date.now();
	dateCreated = this.pipe.transform(this.now, 'yyyy-MM-dd');
	dateStamp = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
	

  headReports = ['Cell Number', 'Message', 'Received Date'];

  constructor(
    private fb: FormBuilder,
		private randomWinnerService: RandomWinnerService,
		private phonebookService: PhonebookService,
    private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService,
    private excelService:ExcelService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
		this.optionsMonthsToGoBack = [
			{value: '1', label: '1'},
			{value: '2', label: '2'},
			{value: '3', label: '3', selected: true},
			{value: '6', label: '6'},
			{value: '12', label: '12'},
		]
    this.loading = false;
    this.loadingfail = false;
    this.totalOrSelected = false;
		this.winnersGenerated = false;
		this.markedWinners = false;
    this.submitted = false;
    this.totalEntries = 0;
    this.totalWinners = 0;
		this.selectedCampaignId = 0;
		this.selectCounter = 0;
    this.resp = {};
    this.resp2 = {};
    this.tempArr = [];
		this.campArr = [];
		this.selectedWinnerArr = [];
    this.winnerArr = [];
		this.payload = {};
		this.payload2 = {};
    
    this.randomWinnerForm = this.fb.group({
      campaigns: ['', Validators.required],
      winnerAmount: ['10', [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.maxLength(3)]]
    });

    this.datePickerForm = this.fb.group({
      beginDate: ['', Validators.required],
      endDate: ['', Validators.required]
    });

    this.getActiveCampaigns();
    this.selectedCampaign();
  }

  ngAfterViewInit() {
  }

  get p() {return this.randomWinnerForm.controls; }
  get s() {return this.datePickerForm.controls; }
  get campaigns() {return this.randomWinnerForm.get('campaigns'); }
  get winnerAmount() {return this.randomWinnerForm.get('winnerAmount'); }
  get beginDate() {return this.datePickerForm.get('beginDate'); }
  get endDate() {return this.datePickerForm.get('endDate'); }

  onSubmitSend() {
    this.submitted = true;

    if (this.randomWinnerForm.invalid) {
      this.openModalErrorInput();
      return;
    }


    this.openModalConfirm();
  }

  selectedCampaign() {
    this.randomWinnerForm.get('campaigns').valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (index) => {
				this.selectedCampaignId = index;
        this.datePickerForm.setValue ({
          beginDate:  this.dateCreated,//this.optionsCampaign[index].camp_startDte,
          endDate: this.dateCreated//this.optionsCampaign[index].camp_endDte
        })
    });
	}
	
	onChange(event,i) {
		if(event.checked) {
			this.winnerArr[i].selected = true;
		} else {
			this.winnerArr[i].selected = false;
		}
		console.log(this.winnerArr[i])
	}

	updateList(id: number, property: string, event: any) {
		const editField = event.target.textContent;
		this.winnerArr[id][property] = editField;
	}

	changeValue(id: number, property: string, event: any) {
		this.editField = event.target.textContent;
	}

  getActiveCampaigns() {
    this.randomWinnerService.getActiveCampaigns()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp = data;
        for (let index = 0; index < this.resp.campaigns.count; index++) {
            const element = this.resp.campaigns.rows[index];
            this.campArr.push({
              value: index,
              camp_id: element.camp_id,
              label: element.camp_name,
              camp_startDte: this.pipe.transform(element.camp_startDte, 'yyyy-MM-dd'),
              camp_endDte: this.pipe.transform(element.camp_endDte, 'yyyy-MM-dd')
            });
          }
        this.optionsCampaign = this.campArr;
        this.tempArr = [];
        this.campArr = [];
      },
      err => {
        this.loadingfail = true;
      }
      );
  }

  getRandomWinners() {
		this.winnerArr = [];
		this.campaignName = this.optionsCampaign[this.selectedCampaignId].label;

      this.payload = {
        camp_id: this.optionsCampaign[this.selectedCampaignId].camp_id,
        amount: this.winnerAmount.value,
        selected: this.totalOrSelected,
        fromDate: this.dateCreated,
				toDate: this.dateCreated,
				monthsToGoBack: this.selectedValue
      }
    
    this.randomWinnerService.getFirstWinners(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
				this.resp2 = data;
				// console.log(data)
        if (this.resp2.winners.rows.length < this.winnerAmount.value) {this.totalWinners = this.resp2.winners.rows.length;} else {this.totalWinners = this.winnerAmount.value;}
        this.totalEntries = this.resp2.winners.count;
        for (let index = 0; index < this.totalWinners; index++) {
            const element = this.resp2.winners.rows[index];
            this.winnerArr.push({
              "Cellphone Number": element.smsi_cell,
              Message: element.smsi_msg,
							"Date and Time Received": this.pipe.transform(element.smsi_dteReceived, 'yyyy-MM-dd HH:mm:ss'),
							Name: 'Not Provided',
							selected: false,
							"Date and Time Generated": this.dateStamp,
							Campaign: this.campaignName
						});
          }
					
          if (this.resp2.winners.count === 0) {
            this.openModalErrorNoWinners();
            return;
          } else {
            this.winnersGenerated = true;
            
            this.openModalSuccess();
          }
        
        
      },
      err => {
        this.ngOnInit();
      }
      );
	}
	
	markWinnersAsWin() {
		this.markedWinners = true;
		this.selectCounter = 0;
		for (let index = 0; index < this.winnerArr.length; index++) {
			const element = this.winnerArr[index];
			if(element.selected) {
				this.payload2 = {
							bl_number: element["Cellphone Number"],
							bl_name: element.Name , //THis should come from the editable field
							bl_surname: 'Winner', //this should come from the editable field
							bl_date_won: this.dateStamp,
				}
				this.selectCounter+= 1;
				this.phonebookService.addBlacklistWinner(this.payload2)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					data => {
						// this.emailMeWinners()
						
					})
			} else {

			}
		}
		if(this.selectCounter === 0) {
			this.openModalErrorNotSelected()
			this.markedWinners = false;
		} else {
			this.openModalSuccessWinnersSelected()
			this.markedWinners = true;
		}
	}

  onExport() {
    this.excelService.exportAsExcelFile(this.winnerArr, 'First_Generated_Winners_'+this.campaignName);
	}

	modalOptionsEmail = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
        content: { winnerArr: []}
    }
	}
	
	emailMeWinners() {
		this.modalOptionsEmail.data = {
      content: {
        winnerArr: this.winnerArr
      }
    }
    this.modalRef = this.modalService.show(RandomWinnerEmailModalComponent, this.modalOptionsEmail)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        
      } 
    });
		
	}

	

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', amount: 0, description: '', btnText: ''}
    }
	}

  openModalConfirm() {
    this.modalOptions.data = {
      heading: 'Are you sure?', 
      content: {
        heading: 'Are you sure you want to generate ',
        amount: this.winnerAmount.value,
        description: 'x random winners?',
        btnText: 'Generate!'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.getRandomWinners();
      } 
    });
  }

  onConfirmBack() {
    this.modalOptions.data = {
      heading: 'Really go back?', 
      content: {
        heading: 'Be sure to export the results first! ',
        amount: 0,
        description: 'Going back will reset this data.',
        btnText: 'Go Back'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.ngOnInit();
      } 
    });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Here are your winners!',
      content: {
        heading: 'I have successfully selected the first '+ this.totalWinners +'x winners out of ' + this.totalEntries + 'x total entries.',
        btnText: 'Let\'s see'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
	}
	

	openModalSuccessWinnersSelected() {
    this.modalOptionsSuccess.data = {
      heading: 'Winners added!',
      content: {
        heading: 'I have just added the selected winners to the Winner Generator blacklist.',
        btnText: 'Dandy!'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }


  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorTimeout() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update Failed Successfully...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: 'Please check your network connectivity.',
        fix2: 'Try to refresh your browser.',
        fix3: 'Then try again.',
        btnText: 'it happens'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorInput() {
    this.modalOptionsErrorInput.data = {
      heading: 'I have failed successfully...',
      content: {
        heading: 'It seems that you have forgotten to provide me with some crucial information.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have selected a campaign.',
        fix2: 'Ensure that you have provided me with an amount of winners to generate.',
        fix3: 'Ensure that you only provide a number value.',
        btnText: 'Fix it'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorNotSelected() {
    this.modalOptionsErrorInput.data = {
      heading: 'No Winners Selected',
      content: {
        heading: 'It seems like you are trying to add winners to the winner generator blacklist. You have not selected any winners to add.',
        suggest: 'Suggested Actions:',
        fix1: 'Identify the winner(s) that you would like to select.',
        fix2: 'Provide a suitable name for said winner(s) by clicking on "Not Provided" then typing a name.',
        fix3: 'After providing winner(s) name(s) the checkbox to the right will become clickable.',
        btnText: 'Dandy!'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorNoWinners() {
    this.modalOptionsErrorInput.data = {
      heading: 'No entries found...',
      content: {
        heading: 'I regret to inform you that I could not find a single entry for this campaign...',
        suggest: 'Suggested Actions:',
        fix1: 'Wait for entries to roll in.',
        fix2: 'Launch an advertisement campaign to raise awareness for this campaign.',
        fix3: 'Be patient.',
        btnText: 'Wait'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
	}
	
}