import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BillingDetail } from "../models/credit-buy.model";
import { Users } from '../models/users';
import { environment } from "../../environments/environment";

const apiURL = environment.usersUrl;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };

  constructor(private httpClient: HttpClient) {}

  public getAccountDetails(): Observable<BillingDetail> {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
    return this.httpClient.get<BillingDetail>(apiURL, this.httpOptions);
    }
  }

  public updateAccountDetails(formData) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
    return this.httpClient.put(apiURL, formData, this.httpOptions);
    }
  }


  public getEmail2SMS() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
    return this.httpClient.get(apiURL + '/email2sms', this.httpOptions);
    }
	}
	
	public getEmail2SMSStats(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
    return this.httpClient.post(apiURL + '/email2sms/stats', payload, this.httpOptions);
    }
  }


  public updateEmail2SMS(email) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
    return this.httpClient.put(apiURL + '/email2sms', email, this.httpOptions);
    }
  }


  public deleteEmail2SMS(email) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
    return this.httpClient.post(apiURL + '/email2sms', email, this.httpOptions);
    }
	}
	

	public requestEmail2SMSActivation() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
    return this.httpClient.get(apiURL + '/email2sms/activate', this.httpOptions);
    }
	}
	

	public cancelEmail2SMSActivation() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
    return this.httpClient.get(apiURL + '/email2sms/cancel', this.httpOptions);
    }
  }
}
