import { Injectable } from '@angular/core';
import Shepherd from "shepherd.js";
import { Router } from "@angular/router";
import { TutorialFourService } from "../guide/tutorial-four.service";

const smsTour3 = new Shepherd.Tour ({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: 'smooth', block: 'center' },
  }, 
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root'
})
export class TutorialThreeService {

  constructor(private router: Router,
     private tutorialFour: TutorialFourService
	) {}

	smsTour3() {
	smsTour3.on('complete', () => {
		this.router.navigate(['/sms/send/shortcode-campaign']);
		this.tutorialFour.smsTour4();
	})
	smsTour3.addSteps([{
		id: 'start',
		title: 'PART3: Upload Message File',
		text: 'This is part 3/4 of the interactive tutorial covering every component related to sending SMS messages.<br><br>\
		Part 3 will cover Sending Bulk SMS by uploading a file with your messages and numbers.<br><br>\
		<mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
		buttons: [
			{action() {return this.next();},text: 'Next'}
		],
	},{
		id: 'step1',
		title: 'Upload Message File',
		text: 'Send bulk SMS messages by uploading a file containing numbers and messages to be sent.<br><br>\
      <i>- Perfect for reaching your entire audience.</i><br><br>',
		attachTo: {element: '.mainS7', on: 'bottom'},
		canClickTarget: false,
		buttons: [
			{action() {return this.next();},text: 'Next'}
		],
	},{
		id: 'step2',
		title: 'Example File Formats',
		text: 'Your file should be in one of these formats - .xls or .xlsx. These are download links to example files.<br><br>\
		<i>- feel free to download any of the examples to ensure correct file format respectively.</i><br><br>',
		attachTo: {element: '.sms2', on: 'bottom'},
		modalOverlayOpeningPadding: 10,
		buttons: [
			{action() {return this.next();},text: 'Next',}
		],
	},{
		id: 'step3',
		title: '.XLSX & .XLS Files',
		text: 'There are 3x things that should be noted when uploading .xlsx/.xls files:<br><br>\
		<i>- Do NOT provide headings for your columns.</i><br>\
		<i>- Contact numbers should be in Column A and messages in Column B.</i><br>\
		<i>- First entry starts in row 1.</i><br>',
		attachTo: {element: '.sms2', on: 'bottom'},
		modalOverlayOpeningPadding: 10,
		buttons: [
			{action() {return this.next();},text: 'Next',}
		],
	},{
		id: 'stepGoOrSkip',
		title: 'Do you want to?',
		text: 'Do you want to go through the file upload tutorial?<br><br>\
		<i>If you do not have a suitable file to test you can skip to Step 4.</i><br><br>\
		<i>If you want to do this tutorial you are more than welcome!</i><br><br>\
		<mark>Click on "Next" to start the File Upload Tutorial; Click on "Skip" to skip to the next Step.</mark>',
		buttons: [
			{action() {return this.show('step12a');},text: 'Skip',},
			{action() {return this.show('step4');},text: 'Next',}
		],
	},{
		id: 'step4',
		title: 'Select your file',
		text: 'Once your file is in the correct format you can upload it for processing.<br><br>\
		First you need to select the file that you want to upload.<br><br>\
		<i>- To do this simply click on "Select File To Upload" and select your file.</i><br><br>\
		<mark>- Try selecting a file to upload before we can move on.</mark>',
		attachTo: {element: '.sms4', on: 'bottom'},
		advanceOn: {selector: '.sms4', event: 'click'},
		modalOverlayOpeningPadding: 10,
	},{
		id: 'stepWait',
		title: 'Waiting on you...',
		text: 'Tell me when you have selected your file.<br><br>',
		canClickTarget: false,
		buttons: [
			{action() {return this.next();},text: 'Next',}
		],
	},{
		id: 'step5',
		title: 'Selected File Name',
		text: 'After you have selected your file I will display your File name here.<br><br>',
		attachTo: {element: '.sms5', on: 'bottom'},
		canClickTarget: false,
		modalOverlayOpeningPadding: 10,
		beforeShowPromise: function() {
			return new Promise(function(resolve) {
				setTimeout(function() {
					resolve();
				}, 200);
			});
		},
		buttons: [
			{action() {return this.next();},text: 'Next',}
		],
	},{
		id: 'step6',
		title: 'Change File',
		text: 'If you wanted to select a different file you could simply click on "Change File".<br><br>',
		attachTo: {element: '.sms6', on: 'bottom'},
		canClickTarget: false,
		modalOverlayOpeningPadding: 10,
		buttons: [
			{action() {return this.next();},text: 'Next',}
		],
	},{
		id: 'step7',
		title: 'Upload File',
		text: 'After you have selected your file this button will become active.<br><br>\
		When you click on this button your file will be uploaded for processing.<br><br>\
		<i>- Any entries in your file that is not in the correct format will be removed. I will inform you on the amount of rows I have excluded.</i><br><br>\
		<mark>- To move on to the next step please click on "Upload File"</mark>',
		attachTo: {element: '.sms7', on: 'bottom'},
		advanceOn: {selector: '.sms7', event: 'click'},
		modalOverlayOpeningPadding: 10,
	},{
		id: 'wait1',
		title: 'Uploading...',
		text: 'Your file is being uploaded...<br><br>\
		<i>- This will usually happen in a matter of milliseconds, but depending on your file size and network speed this could take longer.</i><br><br>\
		<mark>- Once your file has been uploaded successfully you can move on to the next step.</mark>',
		buttons: [
			{action() {return this.next();},text: 'Next'}
		],
	},{
		id: 'step8',
		title: 'When To Send?',
		text: 'After I have received your file I will require you to indicate if this batch should be sent either instantly or at a chosen time and date.<br><br>\
		<i>- Scheduling File upload messages is exactly the same as Part 2: Send to Phone Books. To save you some time we will skip this.</i><br><br>',
		attachTo: {element: '.sms8', on: 'top'},
		modalOverlayOpeningPadding: 10,
		canClickTarget: false,
		buttons: [
			{action() {return this.next();},text: 'Next'}
		],
		beforeShowPromise: function() { 
			return new Promise(function(resolve) {
				setTimeout(function() {
					resolve();
				}, 200);
			});
		},
	},{
		id: 'step9',
		title: 'Add Message To Queue Button',
		text: 'After completing all of the previous steps you need to click on this button to add your SMS to your queue.<br><br>\
		<i>It should be noted that this queue is local and not yet final. It serves as a preview before you finally submit your queue.</i><br><br>\
		<mark>- Click on "Add To Send Queue" to continue.</mark>',
		attachTo: {element: '.sms9', on: 'top'},
		advanceOn: {selector: '.sms9', event: 'click'},
		modalOverlayOpeningPadding: 10,
	},{
		id: 'stepCheck',
		title: 'Verifying...',
		text: 'At this stage you will either see an error pop up or your queue will be displayed.<br><br>\
		If you see a red error popup it means that your file is in the incorrect format.<br><br>\
		If not then it means everything is valid and your message is now in your local queue ',
		canClickTarget: false,
		buttons: [
			{action() {if(document.getElementById('errorPop')) {
				return this.show('stepError')} else {
				return this.show('step10');  
				}},text: 'Next'}
		],
	},{
		id: 'stepError',
		title: 'Oops...',
		text: 'It seems like there was some issues with your file.<br><br>\
		This could be due to uploading a wrong file, formatting said file wrong or just contains bad rows\
		Please click on the "Try Again" button and we will take it from there.<br><br>',
		attachTo: {element: '.errorBtn', on: 'bottom'},
		canClickTarget: true,
		advanceOn: {selector: '.errorBtn', event: 'click'},
	},{
		id: 'stepCheck2',
		title: 'Processing Error...',
		text: 'If the error was fatal - Wrong file type or format, We will have to circle back and try again.<br><br>\
		If the error was minor - Having a couple of bad rows (invalid numbers/ messages), We can continue!.<br><br>',
		canClickTarget: false,
		buttons: [
			{action() {if(document.getElementById('sms10') === null) {
				return this.show('step3')} else {
				return this.show('step10');  
				}},text: 'Next'}
		],
	},{
		id: 'step10',
		title: 'Your Message Queue',
		text: 'After adding a message to your queue it will be displayed similar to Part1: Send Single SMS.<br><br>\
		You can review your batch of messages, remove entire queue or submit your queue to be sent.<br><br>',
		canClickTarget: false,
		attachTo: {element: '.sms10', on: 'bottom'},
		buttons: [
			{action() {return this.next();},text: 'Next',}
		],
	},{
		id: 'step11',
		title: 'Submit Or Remove',
		text: 'Submitting or Removing your message queue can be done with this two buttons respectively.<br><br>',
		canClickTarget: false,
		attachTo: {element: '.sms11', on: 'bottom'},
		buttons: [
			{action() {return this.show('step12b');},text: 'Next',}
		],
	},{
		id: 'step12a',
		title: 'It\'s Okay...',
		text: 'You can always come back and do the File Upload Tour at any time.<br><br>\
		<i>I highly recommend that you complete the last Part Of this tutorial.</i><br><br>\
		<mark>- Click on "Campaign Entries" to start part 4 of this tutorial.</mark>',
		buttons: [
			{action() {return this.complete();},text: 'Part 4: Campaign Entries'}
		],
	},{
		id: 'step12b',
		title: 'Well Done! (3/4)',
		text: 'You have just successfully completed Part 3: Uploading SMS File.<br><br>\
		<i>This concludes Part 3/4 of our tutorial! I highly recommend that you complete the last Part Of this tutorial.</i><br><br>\
		<mark>- Click on "Campaign Entries" to start part 4 of this tutorial.</mark>',
		buttons: [
			{action() {return this.complete();},text: 'Part 4: Campaign Entries'}
		],
	},
]);


smsTour3.start();

	
}
}
