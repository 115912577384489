import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { AdminService } from '../../../services/admin.service';
import { ConfirmComponent } from '../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  public userSelected: boolean;
  private resp: any;
  private users: Array<any>;
  private tempCreditBal: number;
  private userHasCredits: boolean;
  private userPermission: number;
  private payload: any;
	private selectedUserId: any;
	private isEmail2SMSActivated: boolean;
	private isEmail2SMSDeactivated: boolean;
	private email2SMSobj: {before: number, after: number}

  public loading: boolean;
  public loadingfail: boolean;

  userDetailsForm: FormGroup;
  usersForm: FormGroup;
  public submitted: boolean;
  private passwordCheck: boolean;

  constructor(
    private usersService: AdminService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  optionsUser: Array<any>;
  optionsUserType: Array<any>;
  optionsStatus: Array<any>;

  ngOnInit() {
		this.email2SMSobj = {before: 0, after: 0}
		this.isEmail2SMSActivated = false;
		this.isEmail2SMSDeactivated = false;
    this.userSelected = false;
    this.loadingfail = false;
    this.resp = {};
    this.users = [];
    this.optionsUser = [];
    this.tempCreditBal = 0;
    this.userPermission = 1;
    this.payload = {};
    this.submitted = false;
    this.passwordCheck = false;

    this.loadAllUsers();
    this.usersForm = this.fb.group({
      user_name: ['', Validators.required],
    });

    this.userDetailsForm = this.fb.group({
      utype_id: ['', Validators.required],
      user_pw: ['', [Validators.minLength(6), Validators.maxLength(20)]],
      user_pw_conf: ['', [Validators.minLength(6), Validators.maxLength(20)]],
      user_status: ['', Validators.required], 
      user_Email2SMS: ['', Validators.required], 
      uc_amt: [''],
      user_credits_add: ['', Validators.pattern(/^([-]|[0-9])+$/)],
      user_pName: ['', [Validators.maxLength(50)]],
      user_pEmail: ['', [Validators.email, Validators.maxLength(100)]],
      user_pCell: ['', [Validators.minLength(10), Validators.maxLength(12), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
      user_tradingName: ['', [Validators.maxLength(50)]],
      user_Charity: [''],
      user_NonBillable: [''],
      user_vatNum: ['', [Validators.maxLength(10), Validators.pattern(/^([0-9]|[0-9])*$/)]],
      user_accPerson: ['', [Validators.maxLength(50)]],
      user_email: ['', [Validators.email, Validators.maxLength(100)]],
      user_phone: ['', [Validators.minLength(10), Validators.maxLength(12), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]],
      user_physAddr: ['', [Validators.maxLength(200)]],
      user_physAddrArea: ['', [Validators.maxLength(50)]],
      user_physAddrCity: ['', [Validators.maxLength(50)]],
      user_physAddrAreaCode: ['', [Validators.maxLength(20)]]
    });

    this.selectedUser();

    this.optionsUserType = [
      { value: 1, label: 'Administrator' },
      { value: 5, label: 'Default'}
      ];

    this.optionsStatus = [
      { value: 'Active', label: 'Active' },
      { value: 'NotActive', label: 'NotActive' }
      ];
    }

    get a() {return this.usersForm.controls; }
    get p() {return this.userDetailsForm.controls; }

    get pass1()             {return this.userDetailsForm.get('user_pw'); }
    get pass2()             {return this.userDetailsForm.get('user_pw_conf'); }
    get utype_id()          {return this.userDetailsForm.get('utype_id'); }
    get user_status()       {return this.userDetailsForm.get('user_status'); }
    get user_Email2SMS()    {return this.userDetailsForm.get('user_Email2SMS'); }
    get uc_amt()            {return this.userDetailsForm.get('uc_amt'); }
    get user_credits_add()  {return this.userDetailsForm.get('user_credits_add'); }
    get user_pName()        {return this.userDetailsForm.get('user_pName'); }
    get user_pEmail()       {return this.userDetailsForm.get('user_pEmail'); }
    get user_pCell()        {return this.userDetailsForm.get('user_pCell'); }
    get user_tradingName()  {return this.userDetailsForm.get('user_tradingName'); }
    get user_Charity()      {return this.userDetailsForm.get('user_Charity'); }
    get user_NonBillable()  {return this.userDetailsForm.get('user_NonBillable'); }
    get user_vatNum()       {return this.userDetailsForm.get('user_vatNum'); }
    get user_accPerson()    {return this.userDetailsForm.get('user_accPerson'); }
    get user_email()        {return this.userDetailsForm.get('user_email'); }
    get user_phone()       {return this.userDetailsForm.get('user_phone'); }
    get user_physAddr()     {return this.userDetailsForm.get('user_physAddr'); }
    get user_physAddrArea()     {return this.userDetailsForm.get('user_physAddrArea'); }
    get user_physAddrCity()     {return this.userDetailsForm.get('user_physAddrCity'); }
    get user_physAddrAreaCode()     {return this.userDetailsForm.get('user_physAddrAreaCode'); }

    loadAllUsers() {
      this.usersService.getAllUsers()
      .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
				this.resp = data;
        for (let index = 0; index < this.resp.count; index++) {
            const element = this.resp.rows[index];
            this.users.push({
              value: element.user_id,
              label: element.user_name,
            });
          }
        this.optionsUser = this.users;
        this.users = [];
        this.resp = {};
      },
      err => {
      this.loadingfail = true;
    }
    );
    }

    selectedUser() {
      this.usersForm.get('user_name').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (value) => {
        if ( value === null || value.length === 0) {
          return;
        } else
        {
          this.selectedUserId = {user_id: value};
          this.getSelectedUserDetails();
          this.userSelected = true;
        }
      }
    );
    }

    getSelectedUserDetails() {
      this.usersService.getSelectedUserDetails(this.selectedUserId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.resp = data;
          if (this.resp.user.credit === null) {
            this.userHasCredits = false;
            this.tempCreditBal = 0;
          } else {
            this.userHasCredits = true;
            this.tempCreditBal = this.resp.user.credit.uc_amt
          }

          if (this.resp.user.utype_id === 5) {
            this.userPermission = 1;
          } else if (this.resp.user.utype_id === 1) {
            this.userPermission = 0;
          }
          if (this.resp.user.user_status === 'NotActive') {
            this.resp.user.user_status = 1;
          } else {
            this.resp.user.user_status = 0;
					}
					
					this.email2SMSobj.before = this.resp.user.user_Email2SMS;
					
          this.userDetailsForm.setValue({
            utype_id: this.optionsUserType[this.userPermission].value,
            user_pw: '',
            user_pw_conf: '',
            user_status: this.optionsStatus[this.resp.user.user_status].value,
            user_Email2SMS: this.resp.user.user_Email2SMS,
            uc_amt: this.tempCreditBal,
            user_credits_add: '',
            user_pName: this.resp.user.user_pName,
            user_pEmail: this.resp.user.user_pEmail,
            user_pCell: this.resp.user.user_pCell,
            user_tradingName: this.resp.user.user_tradingName,
            user_Charity: Number(this.resp.user.user_Charity),
            user_NonBillable: Number(this.resp.user.user_NonBillable),
            user_vatNum: this.resp.user.user_vatNum,
            user_accPerson: this.resp.user.user_accPerson,
            user_email: this.resp.user.user_email,
            user_phone: this.resp.user.user_phone,
            user_physAddr: this.resp.user.user_physAddr,
            user_physAddrArea: this.resp.user.user_physAddrArea,
            user_physAddrCity: this.resp.user.user_physAddrCity,
            user_physAddrAreaCode: this.resp.user.user_physAddrAreaCode
          });
          this.resp = {};
        }
      );
    }


    onSubmitDetails() {
      this.submitted = true;

      // stop if invalid
      if (this.selectedUserId === undefined || this.selectedUserId.length === 0) {
        this.openModalErrorSelectUser();
        return;
      }

      if (this.pass1.value !== this.pass2.value) {
        this.openModalErrorPassword();
        this.passwordCheck = true;
        return;
      }

      if (this.userDetailsForm.invalid) {
        this.openModalErrorInvalid()
        return;
      }

      this.openModalConfirm();
    }

    updateUserDetails() {
			if (this.user_Email2SMS.value === 1 || this.user_Email2SMS.value === true){
				this.email2SMSobj.after = 1;
			} else {
				this.email2SMSobj.after = 0;
			}

				if ((this.email2SMSobj.before === 0) && this.email2SMSobj.after === 1){
					this.isEmail2SMSActivated = true;
					this.isEmail2SMSDeactivated = false;
				} else if(this.email2SMSobj.before === 1 && this.email2SMSobj.after === 0){
					this.isEmail2SMSActivated = false;
					this.isEmail2SMSDeactivated = true;
				} else {
					this.isEmail2SMSActivated = false;
					this.isEmail2SMSDeactivated = false;
				}

        this.payload = {
          uc_amt:           this.uc_amt.value,
          userHasCredits:   this.userHasCredits,
          user_Charity:     this.user_Charity.value,
          user_Email2SMS:   this.user_Email2SMS.value,
          user_NonBillable: this.user_NonBillable.value,
          user_accPerson:   this.user_accPerson.value,
          user_credits_add: this.user_credits_add.value,
          user_email:       this.user_email.value,
          user_id:          this.selectedUserId.user_id,
          user_pCell:       this.user_pCell.value,
          user_pEmail:      this.user_pEmail.value,
          user_pName:       this.user_pName.value,
          user_phone:       this.user_phone.value,
          user_physAddr:    this.user_physAddr.value,
          user_physAddrArea: this.user_physAddrArea.value,
          user_physAddrCity: this.user_physAddrCity.value,
          user_physAddrAreaCode: this.user_physAddrAreaCode.value,
          user_pw:          this.pass1.value,
          user_pw_conf:     this.pass2.value,
          user_status:      this.user_status.value,
          user_tradingName: this.user_tradingName.value,
          user_vatNum:      this.user_vatNum.value,
					utype_id:         this.utype_id.value,
					E2Sactivated:			this.isEmail2SMSActivated,
					E2Sdeactivated:		this.isEmail2SMSDeactivated
        }
        this.usersService.updateSelectedUserDetails(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
          this.openModalSuccess();
          this.selectedUserId = undefined;
          this.userSelected = false;
          this.submitted = false;
          this.ngOnDestroy();
          this.ngOnInit();
        }
      );
    }
    modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-info',
      containerClass: 'modal fade',
      animated: true,
      data: {
          heading: '',
          content: { heading: '', amount: 0, description: '', btnText: ''}
      }
    }

    openModalConfirm() {
      this.modalOptions.data = {
        heading: 'Are you sure?', 
        content: {
          heading: 'Are you sure you want to save all changes',
          amount: 0,
          description: ' to this user\'s profile?',
          btnText: 'Yes'
        }
      }
      this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
      this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (result: any) => { 
        if (result) {
          this.updateUserDetails();
        } 
      });
    }

    openModalConfirmBack() {
      this.modalOptions.data = {
        heading: 'Are you sure?', 
        content: {
          heading: 'If you go back any unsaved changes will be discarded.',
          amount: 0,
          description: ' Please confirm.',
          btnText: 'Confirm'
        }
      }
      this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
      this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe( (result: any) => { 
        if (result) {
          this.ngOnInit();
        } 
      });
    }

    modalOptionsSuccess = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-success',
      containerClass: 'modal fade',
      animated: true,
      data: {
          heading: '',
          content: { heading: '', btnText: ''}
      }
    }

    openModalSuccess() {
      this.modalOptionsSuccess.data = {
        heading: 'Update Successful',
        content: {
          heading: 'This user\'s account has been updated successfully',
          btnText: 'Thank you'
        }
      }
      this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
    }


    modalOptionsError = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-notify modal-danger',
      containerClass: 'modal fade',
      animated: true,
      data: {
          heading: '',
          content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
      }
    }

    openModalErrorSelectUser() {
      this.modalOptionsError.data = {
        heading: 'Oops...',
        content: {
          heading: 'Please select an user before trying to save.',
          suggest: 'Suggested Actions:',
          fix1: 'Select an user.',
          fix2: 'Make changes.',
          fix3: 'Then try again.',
          btnText: 'lets see'
        }
      }
      this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
    }

    openModalErrorPassword() {
      this.modalOptionsError.data = {
        heading: 'Oops...',
        content: {
          heading: 'The new passwords provided does not seem to match.',
          suggest: 'Suggested Actions:',
          fix1: 'Retype the password.',
          fix2: 'Verify the password by retyping the same password.',
          fix3: 'Then try again.',
          btnText: 'lets see'
        }
      }
      this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
    }

    openModalErrorInvalid() {
      this.modalOptionsError.data = {
        heading: 'Oops...',
        content: {
          heading: 'Some of the required fields may have been left out or invalid.',
          suggest: 'Suggested Actions:',
          fix1: 'Verify all fields are provided & valid.',
          fix2: 'Look for the red text to guide you.',
          fix3: 'Then try again.',
          btnText: 'lets see'
        }
      }
      this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
    }

    

    ngOnDestroy(): any {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
}
