import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../environments/environment";

const url = environment.contactUsUrl;

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };

  constructor(private http: HttpClient) { }

  sendMessageLanding(messageContent: any) {
    return this.http.post(url + '/landing', messageContent);
  }

  sendMessage(messageContent: any) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.http.post(url, messageContent, this.httpOptions);
    }return;
}
}
