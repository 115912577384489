import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

const baseUrl = environment.optoutUrl;

@Injectable({
  providedIn: 'root'
})
export class OptOutService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };


  constructor(private httpClient: HttpClient) {}

  getUserPhonebooks() {
    if (this.token !== null) {
        this.httpOptions = {
          headers: {
            'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
            'Content-Type': 'application/json'
          }
        };
        return this.httpClient.get(baseUrl, this.httpOptions);
      }return;
}


getUserListsAndCount() {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(baseUrl + '/count', this.httpOptions);
    }return;
}

checkListForConflicts(payload) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/numbers/dupe', payload, this.httpOptions);
    }return;
}


checkListForConflictsOnlyEdit(payload) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/numbers/dupe2', payload, this.httpOptions);
    }return;
}

createNewList(payload) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl, payload, this.httpOptions);
    }return;
}

  deleteList(id) {
    if (this.token !== null) {
        this.httpOptions = {
          headers: {
            'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
            'Content-Type': 'application/json'
          }
        };
        return this.httpClient.put(baseUrl + '/remove', id, this.httpOptions);
      }return;
  }

  getListNumbers(id) {
    if (this.token !== null) {
        this.httpOptions = {
          headers: {
            'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
            'Content-Type': 'application/json'
          }
        };
        return this.httpClient.post(baseUrl + '/numbers', id, this.httpOptions);
      }return;
  }

  getPhonebooksContacts(id) {
    if (this.token !== null) {
        this.httpOptions = {
          headers: {
            'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
            'Content-Type': 'application/json'
          }
        };
        return this.httpClient.post(baseUrl + '/numbers/multi', id, this.httpOptions);
      }return;
  }

  updateNumber(payload) {
    if (this.token !== null) {
        this.httpOptions = {
          headers: {
            'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
            'Content-Type': 'application/json'
          }
        };
        return this.httpClient.put(baseUrl + '/numbers', payload, this.httpOptions);
      }return;
  }

  deleteNumber(id) {
    if (this.token !== null) {
        this.httpOptions = {
          headers: {
            'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
            'Content-Type': 'application/json'
          }
        };
        return this.httpClient.put(baseUrl + '/numbers/delete', id, this.httpOptions);
      }return;
  }

  addNumber(payload) {
    if (this.token !== null) {
        this.httpOptions = {
          headers: {
            'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
            'Content-Type': 'application/json'
          }
        };
        return this.httpClient.post(baseUrl + '/numbers/add', payload, this.httpOptions);
      }return;
  }
}
