import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

const apiURL = environment.passwordUrl;

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

 constructor(private httpClient: HttpClient) {}

 submitUsername(payload) {
   return this.httpClient.post(apiURL, payload);
}
}
