import { Component, OnInit, AfterViewInit, EventEmitter, OnDestroy, ViewChild, HostListener, ChangeDetectorRef  } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { IMyOptions, MdbStepperComponent } from 'ng-uikit-pro-standard';
import { DatePipe } from '@angular/common';
import { Router } from "@angular/router";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfirmComponent } from '../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { CampaignService } from '../../../services/campaign.service';
import { LoaderService } from '../../../services/loader.service';


@Component({
  selector: 'app-new-keyword-campaign',
  templateUrl: './new-keyword-campaign.component.html',
  styleUrls: ['./new-keyword-campaign.component.scss']
})
export class NewKeywordCampaignComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('stepper', { static: true }) stepper: MdbStepperComponent;
  @ViewChild(MdbTablePaginationComponent, { static: false }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: false }) mdbTable: MdbTableDirective;
  @HostListener('window:resize') onWindowResize() {

    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }

  modalRef: MDBModalRef;


  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    closeAfterSelect: true,
    editableDateField: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  public isVertical: boolean;

  // VALIDATION FLAGS
  public submitted      : boolean;
  public step1error     : boolean;
  public step2error        : boolean;
  public step1dateError : boolean;
  public keywordAdded   : boolean;
  public step3error     : boolean;
  public blacklistAdded : boolean;
  public step4error     : boolean;
  public step5error     : boolean;
  public loading: boolean;
  // VALIDATION FLAGS

  // DATE & TIME FORMATS
  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = Date.now();
  dateCreated = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  scheduledDate = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  public day = this.pipe.transform(this.now, 'yyyy-MM-dd');
  // DATE & TIME FORMATS

  

  
    private reportDayArr: Array<any>;
    private payload: any;
    private tempArr: any;
    private scArr: any;
    public keywordList: any;
    private selectedScId: any;
    public blacklistedList: any;
    private campaignInfo: any;
    private resp: any;
    private scResp: any;
    private keywordResp: any;
    public isShortcodeDedicated: boolean;

  step1Form: FormGroup;
  public checkCampaignName: any;
  public campNameTaken: any;
  
  step2Form: FormGroup;
  public scValueLessThan: boolean;
  private runningReplyVar: string;
  public reportSchedule: number = 0;
  public campaignType: number = 1;
  public currentStep: number;
  public sms2email :boolean;
  private tempemail: string;
  private tempemail2: number;
  step3Form: FormGroup;
  step4Form: FormGroup;
  step5Form: FormGroup;
  step6Form: FormGroup;
  public blacklistCount: number;
  public blacklistList: Array<any>;
  public previous: Array<any>;
  step7Form: FormGroup;

  optionsSmsValue: Array<any> = [];
  optionsDays: Array<any>;
  private reportDays: Array<any>;

  headKeywords = ['Keyword', 'Description', 'Delete'];
  headBlacklist = ['#', 'Blacklisted Number', 'Remove'];

  constructor(
    private campaignService: CampaignService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
    private cdRef: ChangeDetectorRef,
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}
 

  ngOnInit() {
    this.reportDays = [];
    this.payload = {};
    this.keywordResp = {};
    this.reportDayArr = [];
    this.blacklistCount = 0;
    this.blacklistList = [];
    this.runningReplyVar = '';
    this.scValueLessThan = false;
    this.loading = false;
    this.isVertical = false;
    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
    this.submitted = false;
    this.step1error = false;
    this.step2error = false;
    this.step1dateError = false;
    this.keywordAdded = false;
    this.step3error = false;
    this.blacklistAdded = false;
    this.step4error = false;
    this.step5error = false;
    this.isShortcodeDedicated = false;
    this.sms2email = false;
    this.tempArr = [];
    this.scArr = [];
    this.keywordList = [];
    this.selectedScId = {};
    this.blacklistedList = [];
    this.campaignInfo = {};
    this.resp = {};
    this.scResp = {};
    this.reportSchedule = 0;
    this.campaignType = 1;
    this.currentStep = 1;
    this.tempemail2 = 0;
    this.tempemail = '';
    this.myDatePickerOptions.disableUntil.year = (new Date()).getFullYear();
    this.myDatePickerOptions.disableUntil.month = (new Date()).getMonth() + 1;
    this.myDatePickerOptions.disableUntil.day = (new Date()).getDate() - 1;
    this.getShortcodes();

    this.step1Form = this.fb.group({
      camp_name: ['', [Validators.required, Validators.maxLength(70), Validators.pattern(/^([0-9]|[a-z]|[A-Z]|[ ])+$/)]],
      camp_desc: ['', [Validators.required, Validators.maxLength(200)]],
      camp_startDte: [this.day, [Validators.required, Validators.pattern(/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/)]],
      camp_endDte: [this.day, [Validators.required, Validators.pattern(/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/)]],
      camp_startTime: ['00:00', Validators.required],
      camp_endTime: ['23:59', Validators.required]
    });

    this.step2Form = this.fb.group({
      sc_id : ['', Validators.required],
      camp_replyMsg: ['', Validators.maxLength(160)],
      camp_replyMsgEnd: ['Sorry, this competition is closed.', [Validators.required, Validators.maxLength(160)]]
    });

    this.step3Form = this.fb.group({
      ckeyw_word: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^\S*$/)]],
      ckeyw_desc: ['', [Validators.required, Validators.maxLength(100)]]
    });

    this.step4Form = this.fb.group({
      camp_ReportMail: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
      camp_rep_day: ['', Validators.required],
      camp_rep_hour: ['', [Validators.required, Validators.pattern(/^([0-9]{2})\:([0-9]{2})\:{0,1}([0-9]{0,2})$/)]]
    });

    this.step5Form = this.fb.group({
      email_to: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
      email_cc: ['', [Validators.maxLength(255), Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]]
    });

    this.step6Form = this.fb.group({
      blacklist_cellno: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]]
    });

    this.step7Form = this.fb.group({
      tc: ['', Validators.requiredTrue]
    });

    this.optionsSmsValue = [];

    this.optionsDays = [
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
        ];
    this.selectedShortcode();

  }

  onCheckForm1() {
    if (this.step1Form.invalid) {
      this.openModalErrorStep1();
      this.step1error = true;
      return;
    }

    if (this.campNameTaken) {
      this.openModalErrorStep1Taken();
      this.step1error = true;
      return;
    }

    if (this.camp_startDte.value > this.camp_endDte.value) {
      this.openModalErrorStep1Time();
      this.step1error = true;
      this.step1dateError = true;
      return;
    } else if (this.camp_startDte.value === this.camp_endDte.value) {
      if (this.camp_startTime.value >= this.camp_endTime.value) {
        this.openModalErrorStep1Time2();
        this.step1error = true;
        this.step1dateError = true;
        return;
      }
    }

    this.step1error = false;
    this.step1dateError = false;
    this.currentStep = 2;
    this.stepper.next();
    window.scroll(0,0)
  }

  onBlurCampaignName() {
    this.checkCampaignName = this.camp_name.value;
    this.campaignService.CheckCampaignNameForDuplicates({camp_name: this.checkCampaignName})
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.resp = data;
      if (this.resp.error) {
        this.campNameTaken = true;
      } else {
        this.campNameTaken = false;
      }
    })
  }

  onKeydown(event) {
      this.campNameTaken = false;
  }

  onCheckForm2() {
    if (this.step2Form.invalid) {
      this.openModalErrorStep2();
      this.step2error = true;
      return;
    }

    this.step2error = false;
    this.stepper.next();
    window.scroll(0,0)
  }

  getShortcodes() {
    this.campaignService.getNonDedicatedShortcodes()
    .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
		data => {
      this.resp = data;
      if (this.resp.dedicated.count > 0) {
        this.scArr[0] = {value: '', label: 'Dedicated Shortcodes', group: true};
        for (let a = 0; a < this.resp.dedicated.count; a++) {
          const elementa = this.resp.dedicated.rows[a];
          this.scArr.push({
            value: elementa.sc_id,
            label: (elementa.sc_number + ' - Value = R' + elementa.sc_value.toFixed(2))
          })
        }
      } else {
        this.scArr[0] = {value: '', label: '***For dedicated shortcodes please call us: 011 782 9720 or Sales@SIMCHAT.co.za***',group: true}
      }
      this.scArr.push({value: '', label: 'Shared Shortcodes', group: true})
      for (let b = 0; b < this.resp.shared.count; b++) {
        const elementb = this.resp.shared.rows[b];
        this.scArr.push({
          value: elementb.sc_id,
          label: (elementb.sc_number + ' - Value = R' + elementb.sc_value.toFixed(2))
        })
      }
      this.optionsSmsValue = this.scArr;
      this.resp = {};
      this.scArr = [];
		},
    err => {this.ngOnInit();}
    );
  }

  selectedShortcode() {
    this.step2Form.get('sc_id').valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (value) => {
        this.selectedScId = {sc_id: value};
        this.campaignService.checkIfShortcodeIsSharedOrDedicated(this.selectedScId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
		    data => {
          this.scResp = data;
          this.keywordList = [];
          if (this.scResp.sc_value < 1.50) {
            this.scValueLessThan = true;
            this.camp_replyMsg.setValue('***Please email Contactus@Simchat.co.za if you would like to add a Reply SMS***')
          } else {
            this.scValueLessThan = false;
            this.camp_replyMsg.setValue('')
					}
					// console.log(this.scResp)
          if (this.scResp === null) {return this.isShortcodeDedicated = false}
          if (this.scResp.sc_type === 'd' || this.scResp.sc_type === 'ds' || this.scResp.sc_type === 'sd') {
						if(this.scResp.sc_assignedb4 === 1) {
							this.isShortcodeDedicated = false;
						} else {
							this.isShortcodeDedicated = true;
						}
						
          } else { this.isShortcodeDedicated = false}
        },
        err => {}
        );
    });
  }

  onAddKeyword() {
    this.keywordAdded = true;

    // stop if invalid
    if (this.step3Form.invalid) {
      this.openModalErrorStep3();
        return;
      }
    
    if (!this.selectedScId) {
      this.openModalErrorStep3NoShortcode();
      return;
    }

    if (this.keywordList.length >= 3 && !this.isShortcodeDedicated)
    {
      this.openModalErrorStep3MaxKeywords();
      this.form2Clear();
      return;
    }
      this.getKeywords();
  }
  

  getKeywords() {
    this.payload = {
      sc_id: this.selectedScId.sc_id,
      ckeyw_word: this.ckeyw_word.value
    }
    this.campaignService.getKeywordsForSelectedShortcode(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.keywordResp = data;
        if (this.keywordResp.error) {
          this.openModalErrorStep3KeywordTaken();
          this.form2Clear();
          return;
        }

        for (let index = 0; index < this.keywordList.length; index++) {
          const element = this.keywordList[index];
          if (this.ckeyw_word.value === element.ckeyw_word) {
            this.openModalErrorStep3KeywordUsed();
            this.form2Clear();
            return;
          }
        }

        if (!this.keywordResp.error) {
          this.addKeywordToList();
          return
        }
      },
      err => {}
      )
  }

  addKeywordToList() {
    this.keywordList.push({
      camp_id: '',
      sc_id: this.selectedScId.sc_id,
      ckeyw_word: this.ckeyw_word.value,
      ckeyw_del: 0,
      ckeyw_desc: this.ckeyw_desc.value,
    });
    this.form2Clear();
  }

  onCheckForm3() {
    if (this.keywordList.length === 0 && !this.isShortcodeDedicated) {
      this.step3error = true;
      this.openModalErrorStep3NoKeyword();
      return;
    }

    this.step3error = false;
    this.stepper.next();
    window.scroll(0,0)
  }


  onAddReportDay() {
    if (this.camp_rep_day.valid && this.camp_rep_hour.valid) {
      this.reportDays.push({
        camp_rep_day: this.camp_rep_day.value,
        camp_rep_hour: this.camp_rep_hour.value
      })
      this.camp_rep_day.reset();
      this.camp_rep_hour.reset();
    } else {
      this.openModalErrorStep4Email2();
      return 
    }
  }

  onDeleteOneReportDay(id) {
    this.reportDays.splice(id, 1);
  }

  onCheckReports() {
    
    if (this.reportSchedule === 0) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    } 
    
    if ((this.reportSchedule > 0) && this.camp_ReportMail.invalid) {
      this.openModalErrorStep4Email();
      return;
    }

    if ((this.reportSchedule === 1) && this.camp_ReportMail.valid) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    }

    if ((this.reportSchedule === 2) && this.reportDays.length === 0) {
      this.openModalErrorStep4Email2();
      return;
    }
    
    if ((this.reportSchedule === 2)) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    }

    
  }

  onCheckSms2Email() {
    if (this.sms2email === false) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    }

    if ((this.sms2email === true) && this.email_to.valid) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    } else {
      this.openModalErrorStep5();
      this.step4error = true;
    }
  }

  onDeleteOne(id) {
    this.keywordList.splice(id, 1);
  }

  form2Clear() {
    this.step3Form.reset();
    this.keywordAdded = false;
  }

  get s1() {return this.step1Form.controls; }
  get s2() {return this.step2Form.controls; }
  get s3() {return this.step3Form.controls; }
  get s4() {return this.step4Form.controls; }
  get s5() {return this.step5Form.controls; }
  get s6() {return this.step6Form.controls; }
  get s7() {return this.step7Form.controls; }

  // STEP 1 GETTERS
  get camp_name() {return this.step1Form.get('camp_name'); }
  get camp_desc() {return this.step1Form.get('camp_desc'); }
  get camp_startDte() {return this.step1Form.get('camp_startDte'); }
  get camp_endDte() {return this.step1Form.get('camp_endDte'); }
  get camp_startTime() {return this.step1Form.get('camp_startTime'); }
  get camp_endTime() {return this.step1Form.get('camp_endTime'); }

  // STEP 2 GETTERS
  get sc_id() {return this.step2Form.get('sc_id'); }
  get camp_replyMsg() {return this.step2Form.get('camp_replyMsg'); }
  get camp_replyMsgEnd() {return this.step2Form.get('camp_replyMsgEnd'); }

  // STEP 3 GETTERS
  get ckeyw_word() {return this.step3Form.get('ckeyw_word'); }
  get ckeyw_desc() {return this.step3Form.get('ckeyw_desc'); }

  // STEP 4 GETTERS
  get camp_ReportMail() {return this.step4Form.get('camp_ReportMail'); }
  get camp_rep_day() {return this.step4Form.get('camp_rep_day'); }
  get camp_rep_hour() {return this.step4Form.get('camp_rep_hour'); }

  // STEP 5 GETTERS
  get email_to() {return this.step5Form.get('email_to'); }
  get email_cc() {return this.step5Form.get('email_cc'); }

  // STEP 6 GETTERS
  get blacklist_cellno() { return this.step6Form.get('blacklist_cellno'); }


  // STEP 7 GETTER
  get tc() { return this.step7Form.get('tc'); }

  onAddBlacklistNumber() {
    if (this.blacklist_cellno.invalid) {
      this.openModalErrorNoBlacklistNumberProvided();
      return
    }

    this.blacklistList.push(this.blacklist_cellno.value)
      this.mdbTable.setDataSource(this.blacklistList);
      this.blacklistList = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.cdRef.detectChanges();

      this.blacklistCount++
     
    this.step6Form.reset();
    // this.getBlacklisted();
  }


  onDeleteOneBlacklist(id) {    

    this.blacklistList.splice(id, 1);
    this.blacklistCount--;

    this.mdbTable.setDataSource(this.blacklistList);
    this.blacklistList = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

  
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }



  onSubmit() {
    if (this.keywordList.length === 0 && !this.isShortcodeDedicated) {
      this.openModalErrorStep6Keyword();
      return;
    }
    
    this.step5error = true;
    // do something here
    if (this.step7Form.valid) {
      this.openModalConfirm();
    } else {
      this.openModalErrorStep6TNC();
      return;
    }

  
  }

  onUploadNewCampaign() {
    if (this.reportSchedule === 0) {
      this.tempemail = '';
      this.reportDayArr = [];
    } else if (this.reportSchedule === 1) {
      this.tempemail = this.camp_ReportMail.value
      this.reportDayArr = [
        {camp_rep_day:'Monday',   camp_rep_hour:'07:00'},
        {camp_rep_day:'Tuesday',  camp_rep_hour:'07:00'},
        {camp_rep_day:'Wednesday',camp_rep_hour:'07:00'},
        {camp_rep_day:'Thursday', camp_rep_hour:'07:00'},
        {camp_rep_day:'Friday',   camp_rep_hour:'07:00'},
        {camp_rep_day:'Saturday', camp_rep_hour:'07:00'},
        {camp_rep_day:'Sunday',   camp_rep_hour:'07:00'}]
    } else {
      this.tempemail = this.camp_ReportMail.value
      this.reportDayArr = this.reportDays;
    }
    if (this.sms2email === true) {this.tempemail2 = 1} else {this.tempemail2 = 0}
    
    if (this.scValueLessThan) {this.runningReplyVar = ''} else {this.runningReplyVar = this.camp_replyMsg.value}
    

    this.campaignInfo = {
      sc_id: this.selectedScId.sc_id,
      camp_name: this.camp_name.value,
      camp_desc: this.camp_desc.value,
      camp_startDte: this.camp_startDte.value,
      camp_endDte: this.camp_endDte.value,
      camp_startTime: this.camp_startTime.value,
      camp_endTime: this.camp_endTime.value,
      camp_replyMsg: this.runningReplyVar,
      camp_replyMsgEnd: this.camp_replyMsgEnd.value,
      camp_hasReport: this.reportSchedule,
      camp_ReportMail: this.tempemail,
      camp_hasEmail: this.tempemail2,
      varBlacklistArr: this.blacklistList,
      varKeywordArr: this.keywordList,
      camp_rep_day: this.reportDayArr,
      email_to: this.email_to.value,
      email_cc: this.email_cc.value,
      camp_type: this.campaignType,
    }

    this.campaignService.addNewCampaign(this.campaignInfo)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.tempArr = data;
        this.tempArr = this.tempArr.data;
        this.openModalSuccess();
        this.router.navigate(['/dashboard/campaign'])
      },
      err => {
        
      }
      )
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', amount: 0, description: '', btnText: ''}
    }
  }

  openModalConfirm() {
    this.modalOptions.data = {
      heading: 'All done?', 
      content: {
        heading: 'Are you 100% sure that everything is correct?',
        amount: 0,
        description: ' Don\'t worry if you forgot something you can always make changes afterwards.',
        btnText: 'Create My Campaign'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.onUploadNewCampaign();
      } 
    });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Congratulations!',
      content: {
        heading: 'You are now the proud owner of a spectacular campaign. May you receive countless entries. Redirecting you to your campaign dashboard...',
        btnText: 'Easy-Peasy'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }


  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }


  
  openModalErrorStep1Taken() {
    this.modalOptionsErrorInput.data = {
      heading: 'Wait just one second...',
      content: {
        heading: 'The campaign name that you have provided is already in use by one of your other campaigns.',
        suggest: 'Suggested Actions:',
        fix1: 'Please use a different campaign name.',
        fix2: 'Try to add slight variations to remove the conflict.',
        fix3: 'Always remember to Never say "Always" and "Never".',
        btnText: 'Let\'s see'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }


  openModalErrorStep1() {
    this.modalOptionsErrorInput.data = {
      heading: 'Wait just one second...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two. This is preventing us from moving on to step #2.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required fields.',
        fix2: 'Look for any red text screaming at you.',
        fix3: 'Try to touch your elbow with your tongue.',
        btnText: 'Let\'s see'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep1Time() {
    this.modalOptionsErrorInput.data = {
      heading: 'Warning: Space-Time Violation!',
      content: {
        heading: 'I have just received confirmation that you want to end your campaign before it has even started. This is a direct violation of Section 356, Par.97 of the Laws of Time.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that the start date is equal to or before the ending date.',
        fix2: 'Ensure that the end date is equal to or after the starting date.',
        fix3: 'Don\'t take the little things in life for granted.',
        btnText: 'Crisis Averted'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep1Time2() {
    this.modalOptionsErrorInput.data = {
      heading: 'Warning: Space-Time Violation!',
      content: {
        heading: 'It seems that your campaign start- and end dates are on the same day - which is fine. But the start time is after the end time. The space-time continuum would not approve.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that the start time is before the ending time.',
        fix2: 'Ensure that the end time is after the starting time.',
        fix3: 'Watch a sunrise at least once a year.',
        btnText: 'Crisis Averted'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep2() {
    this.modalOptionsErrorInput.data = {
      heading: 'Wait just one second...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two. This is preventing us from moving on to step #3.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required fields.',
        fix2: 'Look for any red text screaming at you.',
        fix3: 'Always wear sunscreen when outside on hot days.',
        btnText: 'Let\'s see'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3() {
    this.modalOptionsErrorInput.data = {
      heading: 'Keyword problems!',
      content: {
        heading: 'What did the laptop say to the dentist? - "My Bluetooth hurts". No, but seriously, there is an error with your keyword.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you provide a keyword.',
        fix2: 'Ensure that you provide a description.',
        fix3: '*Keywords may not contain any spaces.',
        btnText: 'Fix Keyword'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3NoShortcode() {
    this.modalOptionsErrorInput.data = {
      heading: 'Task failed successfully.',
      content: {
        heading: 'It seems as though you might have slipped through the cracks and never chose a shortcode.',
        suggest: 'Suggested Actions:',
        fix1: 'Please go back to Step #2 and select a shortcode value.',
        fix2: 'After selecting a shortcode value come back to Step #3.',
        fix3: 'Try to add your shortcode again.',
        btnText: 'Lets go'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3MaxKeywords() {
    this.modalOptionsErrorInput.data = {
      heading: 'Task failed successfully.',
      content: {
        heading: 'While I admire your drive, we can only allow each campaign to have a maximum of three (3x) keywords.',
        suggest: 'Suggested Actions:',
        fix1: 'Delete one of your 3x keywords to make room for one.',
        fix2: 'Only have 3x keywords.',
        fix3: 'If you really need more - contact us and we\'ll make an exception just for you.',
        btnText: 'Oh Darn'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3KeywordTaken() {
    this.modalOptionsErrorInput.data = {
      heading: 'What are the odds?',
      content: {
        heading: 'There are around 218 632 words in the Oxford English Dictionary‬. Unfortunately, "' + this.ckeyw_word.value + '" is already being used by another campaign on the selected shortcode.',
        suggest: 'Suggested Actions:',
        fix1: 'Consider using a different keyword.',
        fix2: 'Consider using a different shortcode.',
        fix3: '',
        btnText: 'it happens'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3KeywordUsed() {
    this.modalOptionsErrorInput.data = {
      heading: 'Add keyword failed successfully.',
      content: {
        heading: '"' + this.ckeyw_word.value + '" is currently already in your keyword list. To keep everything nice and clean I can not allow you to add duplicate keywords.',
        suggest: 'Suggested Actions:',
        fix1: 'Don\'t use the same keyword multiple times.',
        fix2: 'Some people like to add slight variations of the same keyword in case of typing errors on the user\'s side.',
        fix3: 'Try removing "' + this.ckeyw_word.value + '" from your current list then you can add "' + this.ckeyw_word.value + '".',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3NoKeyword() {
    this.modalOptionsErrorInput.data = {
      heading: 'No keyword found.',
      content: {
        heading: 'You did not provide a keyword. Because you are either using a shared shortcode or you already have a campaign without keywords for your selected dedicated shortcode. You are required to provide at least one keyword.',
        suggest: 'Suggested Actions:',
        fix1: 'Provide at least one keyword.',
        fix2: 'You can even provide two or three keywords.',
        fix3: 'Select a dedicated shortcode.',
        btnText: 'on it'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep4Email() {
    this.modalOptionsErrorInput.data = {
      heading: '404 Email not found.',
      content: {
        heading: 'I can\'t seem to find the email address you provided for receiving daily reports.',
        suggest: 'Suggested Actions:',
        fix1: 'Provide a valid email address for receiving daily reports.',
        fix2: 'Multiple email addresses should be separated by ";" (semicolon).',
        fix3: 'Ensure that your keyboard is connected to your device.',
        btnText: 'Fix email'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep4Email2() {
    this.modalOptionsErrorInput.data = {
      heading: 'Report day or time not found.',
      content: {
        heading: 'It seems like you want a customized report but did not select any day or time to receive reports on.',
        suggest: 'Suggested Actions:',
        fix1: 'Select at a day of the week. Select a time of day.',
        fix2: 'If you want to receive reports everyday select "Get Daily Reports".',
        fix3: 'If you do not want any reports select "Get No Reports".',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep5() {
    this.modalOptionsErrorInput.data = {
      heading: 'Email address error.',
      content: {
        heading: 'It seems like you didn\'t provide a valid email address for receiving entry notifications.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you provide an email address.',
        fix2: 'Ensure that the provided email address is valid.',
        fix3: 'Multiple email addresses should be separated by ";" (semicolon).',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorNoBlacklistNumberProvided() {
    this.modalOptionsErrorInput.data = {
      heading: '404 number not found',
      content: {
        heading: 'My journey to blacklist that number you wanted was unfruitful.',
        suggest: 'Suggested Actions:',
        fix1: 'Please provide a number.',
        fix2: 'Ensure said number is valid.',
        fix3: 'Try again',
        btnText: 'Fix number'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep6Keyword() {
    this.modalOptionsErrorInput.data = {
      heading: 'Oops... My bad.',
      content: {
        heading: 'I just realized that you did not provide any keywords. Please return to Step #3 and add at least one keyword.',
        suggest: 'Suggested Actions:',
        fix1: 'Go back to step #3 and add at least one keyword.',
        fix2: 'After adding at least one keyword come back and try again.',
        fix3: '',
        btnText: 'i forgive you'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep6TNC() {
    this.modalOptionsErrorInput.data = {
      heading: 'Terms & Conditions.',
      content: {
        heading: 'While you were busy creating your campaign I went ahead and read through the terms & conditions for you. You only need to accept them.',
        suggest: 'Suggested Actions:',
        fix1: 'Read through the terms & conditions and accept them.',
        fix2: 'Say that you have read through the terms & conditions and accept them.',
        fix3: 'Ask a colleague to read the terms & conditions for you and accept them.',
        btnText: 'I will read it now'
      }
    } 
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorTimeout() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update Failed Successfully...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: 'Please check your network connectivity.',
        fix2: 'Try to refresh your browser.',
        fix3: 'Then try again.',
        btnText: 'it happens'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  //  ,{
  //   id: '',
  //   title: '',
  //   text: '.<br><br>\
  //   .<br><br>\
  //   <i>- </i><br><br>\
  //   <mark>- </mark>',
  //   attachTo: {element: '.t', on: 'top'},
  //   advanceOn: {selector: '.', event: 'click'},
  //   canClickTarget: false,
  //   modalOverlayOpeningPadding: 10,
  //   beforeShowPromise: function() { 
  //     return new Promise(function(resolve) {
  //       setTimeout(function() {
  //         resolve();
  //       }, 200);
  //     });
  //   },
  //   buttons: [
  //     {action() {return this.next();},text: 'Next',}
  //   ],
  // }

  ngAfterViewInit() {
  
  }

    
}
