import { Component, OnInit, AfterViewInit, EventEmitter, OnDestroy, ViewChild, HostListener  } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IMyOptions, MdbStepperComponent } from 'ng-uikit-pro-standard';
import { DatePipe } from '@angular/common';
import { Router } from "@angular/router";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
// import Shepherd from "shepherd.js";
import { ConfirmComponent } from '../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { LoaderService } from '../../../services/loader.service';

import { CampaignService } from '../../../services/campaign.service';
import { AdminService } from '../../../services/admin.service';

// const tour = new Shepherd.Tour ({
//   defaultStepOptions: {
//     cancelIcon: {
//       enabled: true
//     },
//     classes: 'shadow-md bg-purple-dark',
//     scrollTo: { behavior: 'smooth', block: 'center' },
//   }, 
//   confirmCancel: true,
//   confirmCancelMessage: 'If you end the tutorial now all data provided will be reset.',
//   exitOnEsc: false,
//   keyboardNavigation: false,
//   useModalOverlay: true,
// });

@Component({
  selector: 'app-new-instant-winner-campaign',
  templateUrl: './new-instant-winner-campaign.component.html',
  styleUrls: ['./new-instant-winner-campaign.component.scss']
})
export class NewInstantWinnerCampaignComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('stepper', { static: true }) stepper: MdbStepperComponent;
  private payload: any;
  @HostListener('window:resize') onWindowResize() {

    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }
  modalRef: MDBModalRef;

  public isVertical: boolean;
  
  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    closeAfterSelect: true,
    editableDateField: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };


  private selectedUserId: {user_id: number};
  public checkCampaignName: any;
  // VALIDATION FLAGS
  public submitted         : boolean;
  public step1error        : boolean;
  public step2error        : boolean;
  public step1dateError    : boolean;
  public keywordAdded      : boolean;
  public step3error        : boolean;
  public Reportserror      : boolean;
  public blacklistAdded    : boolean;
  public step4error        : boolean;
  public step5error        : boolean;
  public winnerOptionsError: boolean;
  public tutorialActive    : boolean;
  // VALIDATION FLAGS
  private keywordResp: any;

  // DATE & TIME FORMATS
  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = Date.now();
  dateCreated = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  scheduledDate = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  public day = this.pipe.transform(this.now, 'yyyy-MM-dd');
  // DATE & TIME FORMATS

  private reportDayArr: Array<any>;
  private reportTimeValue: string;
  private tempArr: any;
  private scArr: any;
  public keywordList: any;
  private selectedScId: any;
  public blacklistedList: any;
  private campaignInfo: any;
  private resp: any;
  private scResp: any;
  public isShortcodeDedicated: boolean;

  public loading: boolean;

  step1Form: FormGroup;
  
  step2Form: FormGroup;
  public reportSchedule: number;
  public sms2email: boolean;
  private tempemail: string;
  private tempemail2: number;
  step3Form: FormGroup;
  step4Form: FormGroup;
  public flag_winnerCrit : number;
  step5Form: FormGroup;
  step6Form: FormGroup;
  step7Form: FormGroup;
  

  optionsSmsValue: Array<any> = [];
  optionsDays: Array<any>;
   users: Array<any>;
  optionsUsers: Array<any>;
  public campNameTaken: any;

  headKeywords = ['Keyword', 'Description', 'Delete'];
  headBlacklist = ['#', 'Blacklisted Numbers', 'Remove'];
  optionsTicketPosVal: Array<any> = [
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
  ];

  optionsWinType: Array<any> = [
    {value: '1', label: 'Daily'},
    {value: '2', label: 'Monthly'},
    {value: '3', label: 'Yearly'},
    {value: '4', label: 'Entire Campaign'},
  ];

  constructor(
    private campaignService: CampaignService,
    private adminService: AdminService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
    this.reportTimeValue = '';
    this.reportDayArr = [];
    this.keywordResp = {};
    this.payload = {};
    this.users = [];
    this.loading = false;
    this.isVertical = false;
    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
    this.submitted = false;
    this.step1error = false;
    this.step2error = false;
    this.step1dateError = false;
    this.keywordAdded = false;
    this.step3error = false;
    this.blacklistAdded = false;
    this.step4error = false;
    this.Reportserror = false;
    this.step5error = false;
    this.tutorialActive = false;
    this.isShortcodeDedicated = false;
    this.sms2email = false;
    this.tempArr = [];
    this.scArr = [];
    this.keywordList = [];
    this.selectedScId = {};
    this.blacklistedList = [];
    this.campaignInfo = {};
    this.resp = {};
    this.scResp = {};
    this.reportSchedule = 0;
    this.tempemail2 = 0;
    this.tempemail = '';
    this.flag_winnerCrit = 0;
    this.myDatePickerOptions.disableUntil.year = (new Date()).getFullYear();
    this.myDatePickerOptions.disableUntil.month = (new Date()).getMonth() + 1;
    this.myDatePickerOptions.disableUntil.day = (new Date()).getDate() - 1;
    
    this.loadAllUsers();

    this.step1Form = this.fb.group({
      user_id: ['', Validators.required],
      camp_name: ['', [Validators.required, Validators.maxLength(70), Validators.pattern(/^([0-9]|[a-z]|[A-Z]|[ ])+$/)]],
      camp_desc: ['', [Validators.required, Validators.maxLength(200)]],
      camp_startDte: ['', [Validators.required, Validators.pattern(/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/)]],
      camp_endDte: ['', [Validators.required, Validators.pattern(/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/)]],
      flag_normAdv: [0, Validators.required],
      ticket_code_msgPos: [1, Validators.required]
    });

    this.step2Form = this.fb.group({
      sc_id : ['', Validators.required],
      notWin_replyMsg: ['', [Validators.required, Validators.maxLength(160)]],
      prize_won_replyMsg: ['', [Validators.required, Validators.maxLength(160)]],
      camp_replyMsgEnd: ['Sorry, this competition is closed.', [Validators.required, Validators.maxLength(160)]],
      invalid_ticket_replyMsg: ['Sorry, your ticket number was invalid.', [Validators.required, Validators.maxLength(160)]],
      used_ticket_replyMsg: ['Sorry, your ticket number was already used.', [Validators.required, Validators.maxLength(160)]],
      invalid_msg_replyMsg: ['Sorry, message was not in the correct format, please try again.', [Validators.required, Validators.maxLength(160)]],
    });

    this.step3Form = this.fb.group({
      ckeyw_word: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^\S*$/)]],
      ckeyw_desc: ['', [Validators.required, Validators.maxLength(100)]]
    });

    this.step4Form = this.fb.group({
      max_winPerCellno: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9]*$/)]],
      max_winPerCellno_type_id: ['', Validators.required],
      max_total_winners: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9]*$/)]],
      per_entry_winnerCount: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9]*$/)]],
      prizeIsAirtime: [0, Validators.required]
    })

    this.step5Form = this.fb.group({
      camp_ReportMail: ['', [Validators.required, Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
      camp_rep_day: ['', Validators.required],
      camp_rep_hour: ['', [Validators.required, Validators.pattern(/^([0-9]{2})\:([0-9]{2})\:{0,1}([0-9]{0,2})$/)]]
    });

    this.step6Form = this.fb.group({
      email_to: ['', [Validators.required, Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
      email_cc: ['', Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]
    });

    this.step7Form = this.fb.group({
      tc: ['', Validators.requiredTrue]
    });

    this.optionsSmsValue = [];

    this.optionsDays = [
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
        ];
    
    this.selectedUser();
    this.selectedShortcode();
  }

  loadAllUsers() {
    this.adminService.getAllUsers()
    .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    data => {
      this.resp = data;
      for (let index = 0; index < this.resp.count; index++) {
          const element = this.resp.rows[index];
          this.users.push({
            value: element.user_id,
            label: element.user_name,
          });
        }
      this.optionsUsers = this.users;
      this.users = [];
      this.resp = {};
    },
    err => {
    }
  );
  }

  selectedUser() {
    this.step1Form.get('user_id').valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (value) => {
        this.selectedUserId = {user_id: value};
        this.getShortcodes();
    });
  }

  onBlurCampaignName() {
    this.checkCampaignName = this.camp_name.value;
    this.campaignService.CheckCampaignNameForDuplicates({camp_name: this.checkCampaignName})
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.resp = data;
      if (this.resp.error) {
        this.campNameTaken = true;
      } else {
        this.campNameTaken = false;
      }
    })
  }

  onKeydown(event) {
      this.campNameTaken = false;
  }

  onCheckForm1() {
    if (this.step1Form.invalid) {
      this.openModalErrorStep1();
      this.step1error = true;
      return;
    }

    if (this.campNameTaken) {
      this.openModalErrorStep1Taken();
      this.step1error = true;
      return;
    }

    if (this.camp_startDte.value >= this.camp_endDte.value) {
      this.openModalErrorStep1Time();
      this.step1error = true;
      this.step1dateError = true;
      return;
    }

    this.step1error = false;
    this.step1dateError = false;
    this.stepper.next();
  }


  onCheckForm2() {
    if (this.step2Form.invalid) {
      this.openModalErrorStep2();
      this.step2error = true;
      return;
    }

    this.step2error = false;
    this.stepper.next();
    window.scroll(0,0)
  }

  getShortcodes() {
    this.campaignService.getNonDedicatedShortcodesAsAdmin(this.selectedUserId)
    .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
		data => {
      this.resp = data;
      if (this.resp.dedicated.count > 0) {
        this.scArr[0] = {value: '', label: 'Dedicated', group: true};
        for (let a = 0; a < this.resp.dedicated.count; a++) {
          const elementa = this.resp.dedicated.rows[a];
          this.scArr.push({
            value: elementa.sc_id,
            label: (elementa.sc_number + ' - Value = R' + elementa.sc_value.toFixed(2))
          })
        }
      }
      this.scArr.push({value: '', label: 'Shared', group: true})
      for (let b = 0; b < this.resp.shared.count; b++) {
        const elementb = this.resp.shared.rows[b];
        this.scArr.push({
          value: elementb.sc_id,
          label: (elementb.sc_number + ' - Value = R' + elementb.sc_value.toFixed(2))
        })
      }
      this.optionsSmsValue = this.scArr;
      this.resp = {};
      this.scArr = [];
		},
    err => {
      this.ngOnInit();
    }
    );
  }

  selectedShortcode() {
    this.step2Form.get('sc_id').valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (value) => {
        this.selectedScId = {sc_id: value};
        this.campaignService.checkIfShortcodeIsSharedOrDedicated(this.selectedScId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
		    data => {
          this.scResp = data;
          this.keywordList = [];
          if (this.scResp === null) {return this.isShortcodeDedicated = false}
          if (this.scResp.sc_type === 'd' || this.scResp.sc_type === 'ds' || this.scResp.sc_type === 'sd') {
            this.isShortcodeDedicated = true;
          } else { this.isShortcodeDedicated = false}
        },
        err => {

        }
        );
    });
  }

  onAddKeyword() {
    this.keywordAdded = true;

    // stop if invalid
    if (this.step3Form.invalid) {
      this.openModalErrorStep3();
        return;
      }
    
    if (!this.selectedScId) {
      this.openModalErrorStep3NoShortcode();
      return;
    }

    if (this.keywordList.length >= 3 && !this.isShortcodeDedicated)
    {
      this.openModalErrorStep3MaxKeywords();
      this.keywordFormClear();
      return;
    }
      this.getKeywords();
  }
  

  getKeywords() {
    this.payload = {
      sc_id: this.selectedScId.sc_id,
      ckeyw_word: this.ckeyw_word.value
    }
    this.campaignService.getKeywordsForSelectedShortcode(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.keywordResp = data;
        if (this.keywordResp.error) {
          this.openModalErrorStep3KeywordTaken();
          this.keywordFormClear();
          return;
        }

        for (let index = 0; index < this.keywordList.length; index++) {
          const element = this.keywordList[index];
          if (this.ckeyw_word.value === element.ckeyw_word) {
            this.openModalErrorStep3KeywordUsed();
            this.keywordFormClear();
            return;
          }
        }
       
        if (!this.keywordResp.error) {
          this.addKeywordToList();
          return
        }
      },
      err => {

      }
      )
  }

  addKeywordToList() {
    this.keywordList.push({
      camp_id: '',
      sc_id: this.selectedScId.sc_id,
      ckeyw_word: this.ckeyw_word.value,
      ckeyw_del: 0,
      ckeyw_desc: this.ckeyw_desc.value,
    });
    this.keywordFormClear();
  }

  onCheckForm3() {
    if (this.keywordList.length === 0 && !this.isShortcodeDedicated) {
      this.step3error = true;
      this.openModalErrorStep3NoKeyword();
      return;
    }

    this.step3error = false;
    this.stepper.next();
    window.scroll(0,0)
  }

  onCheckWinnerOpts() {
    
    if ((this.flag_winnerCrit === 0 || this.flag_winnerCrit === 2)) {
      if(this.max_winPerCellno.valid && this.max_winPerCellno_type_id.valid && this.max_total_winners.valid) {
        this.stepper.next();
        this.winnerOptionsError = false;
        return;
      } else {
        this.openModalErrorStep4WinnerCrit();
        this.winnerOptionsError = true;
        return;
      }
      
    }
    
    if (this.flag_winnerCrit === 1) {
      if(this.max_winPerCellno.valid && this.max_winPerCellno_type_id.valid && this.max_total_winners.valid && this.per_entry_winnerCount.valid) {
        this.stepper.next();
        this.winnerOptionsError = false;
        return;
      } else {
        this.openModalErrorStep4WinnerCrit();
        this.winnerOptionsError = true;
        return;
      }
    }

    
  }

  onCheckReports() {
    
    if (this.reportSchedule === 0) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    } 

    if ((this.reportSchedule > 0) && this.camp_ReportMail.invalid) {
      this.openModalErrorStep4Email();
      return;
    }

    if ((this.reportSchedule === 1) && this.camp_ReportMail.valid) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    }

    if ((this.reportSchedule === 2) && this.step4Form.invalid) {
      this.openModalErrorStep4Email2();
      return;
    }
    
    if ((this.reportSchedule === 2) && this.step4Form.valid) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    }

    
  }

  onCheckSms2Email() {
    if (this.sms2email === false) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    }

    if ((this.sms2email === true) && this.email_to.valid) {
      this.stepper.next();
      window.scroll(0,0)
      return;
    } else {
      this.openModalErrorStep5();
      this.step4error = true;
    }
  }

  onDeleteOne(id) {
    this.keywordList.splice(id, 1);
  }

  keywordFormClear() {
    this.step3Form.reset();
    this.keywordAdded = false;
  }

  get s1() {return this.step1Form.controls; }
  get s2() {return this.step2Form.controls; }
  get s3() {return this.step3Form.controls; }
  get s4() {return this.step4Form.controls; }
  get s5() {return this.step5Form.controls; }
  get s6() {return this.step6Form.controls; }
  get s7() {return this.step7Form.controls; }

  // STEP 1 GETTERS
  get camp_name() {return this.step1Form.get('camp_name'); }
  get camp_desc() {return this.step1Form.get('camp_desc'); }
  get camp_startDte() {return this.step1Form.get('camp_startDte'); }
  get camp_endDte() {return this.step1Form.get('camp_endDte'); }
  get camp_startTime() {return this.step1Form.get('camp_startTime'); }
  get camp_endTime() {return this.step1Form.get('camp_endTime'); }
  get flag_normAdv() {return this.step1Form.get('flag_normAdv'); }
  get ticket_code_msgPos() {return this.step1Form.get('ticket_code_msgPos'); }

  // STEP 2 GETTERS
  get sc_id() {return this.step2Form.get('sc_id'); }
  get notWin_replyMsg() {return this.step2Form.get('notWin_replyMsg'); }
  get prize_won_replyMsg() {return this.step2Form.get('prize_won_replyMsg'); }
  get camp_replyMsgEnd() {return this.step2Form.get('camp_replyMsgEnd'); }
  get invalid_ticket_replyMsg() {return this.step2Form.get('camp_replyMsgEnd'); }
  get used_ticket_replyMsg() {return this.step2Form.get('camp_replyMsgEnd'); }
  get invalid_msg_replyMsg() {return this.step2Form.get('invalid_msg_replyMsg'); }

  // STEP 3 GETTERS
  get ckeyw_word() {return this.step3Form.get('ckeyw_word'); }
  get ckeyw_desc() {return this.step3Form.get('ckeyw_desc'); }

  // STEP 4 GETTERS
  get max_winPerCellno() {return this.step4Form.get('max_winPerCellno'); }
  get max_winPerCellno_type_id() {return this.step4Form.get('max_winPerCellno_type_id'); }
  get max_total_winners() {return this.step4Form.get('max_total_winners'); }
  get per_entry_winnerCount() {return this.step4Form.get('per_entry_winnerCount'); }
  get prizeIsAirtime() {return this.step4Form.get('prizeIsAirtime'); }

  // STEP 5 GETTERS
  get camp_ReportMail() {return this.step5Form.get('camp_ReportMail'); }
  get camp_rep_day() {return this.step5Form.get('camp_rep_day'); }
  get camp_rep_hour() {return this.step5Form.get('camp_rep_hour'); }

  // STEP 6 GETTERS
  get email_to() {return this.step6Form.get('email_to'); }
  get email_cc() {return this.step6Form.get('email_cc'); }

  // STEP 7 GETTER
  get tc() { return this.step7Form.get('tc'); }



  onSubmit() {
    if (this.keywordList.length === 0 && !this.isShortcodeDedicated) {
      this.openModalErrorStep6Keyword();
      return;
    }

    if ((this.flag_winnerCrit === 0 || this.flag_winnerCrit === 2) && (this.max_winPerCellno.invalid || this.max_winPerCellno_type_id.invalid || this.max_total_winners.invalid)) {
      this.openModalErrorStep4WinnerOptions();
      this.winnerOptionsError = true;
      return;
    }

    if (this.flag_winnerCrit === 1 && this.step4Form.invalid) {
      this.openModalErrorStep4WinnerOptions();
      this.winnerOptionsError = true;
      return;
    }


    this.step5error = true;
    // do something here
    if (this.step7Form.valid) {
      this.openModalConfirm();
    } else {
      this.openModalErrorStep6TNC();
      return;
    }

  
  }

  onUploadNewCampaign() {
    if (this.reportSchedule === 0) {
      this.tempemail = '';
      this.reportDayArr = [];
      this.reportTimeValue = '';
    } else if (this.reportSchedule === 1) {
      this.tempemail = this.camp_ReportMail.value
      this.reportDayArr = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
      this.reportTimeValue = '07:00'
    } else {
      this.tempemail = this.camp_ReportMail.value
      this.reportDayArr = this.camp_rep_day.value;
      this.reportTimeValue = this.camp_rep_hour.value;
    }
    if (this.sms2email === true) {this.tempemail2 = 1} else {this.tempemail2 = 0}
    

    this.campaignInfo = {
      user_id: this.selectedUserId.user_id,
      camp_name: this.camp_name.value,
      camp_desc: this.camp_desc.value,
      camp_startDte: this.camp_startDte.value,
      camp_endDte: this.camp_endDte.value,
      flag_normAdv: this.flag_normAdv.value,
      ticket_code_msgPos: this.ticket_code_msgPos.value,
      sc_id: this.selectedScId.sc_id,
      notWin_replyMsg: this.notWin_replyMsg.value,
      prize_won_replyMsg: this.prize_won_replyMsg.value,
      camp_replyMsgEnd: this.camp_replyMsgEnd.value,
      invalid_ticket_replyMsg: this.invalid_ticket_replyMsg.value,
      used_ticket_replyMsg: this.used_ticket_replyMsg.value,
      invalid_msg_replyMsg: this.invalid_msg_replyMsg.value,
      camp_hasReport: this.reportSchedule,
      camp_ReportMail: this.tempemail,
      camp_hasEmail: this.tempemail2,
      flag_winnerCrit: this.flag_winnerCrit,
      max_winPerCellno: this.max_winPerCellno.value,
      max_winPerCellno_type_id: this.max_winPerCellno_type_id.value,
      max_total_winners: this.max_total_winners.value,
      per_entry_winnerCount: this.per_entry_winnerCount.value,
      prizeIsAirtime: this.prizeIsAirtime.value,
      varKeywordArr: this.keywordList,
      camp_rep_day: this.reportDayArr,
      camp_rep_hour: this.reportTimeValue,
      email_to: this.email_to.value,
      email_cc: this.email_cc.value
    }

    this.campaignService.addNewTicketCampaign(this.campaignInfo)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.tempArr = data;
        this.tempArr = this.tempArr.data;
        this.openModalSuccess();
        this.router.navigate(['/dashboard/campaign'])
      },
      err => {

      }
      )
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', amount: 0, description: '', btnText: ''}
    }
  }

  openModalConfirm() {
    this.modalOptions.data = {
      heading: 'All done?', 
      content: {
        heading: 'Are you 100% sure that everything is correct?',
        amount: 0,
        description: ' Don\'t worry if you forgot something you can always make changes afterwards.',
        btnText: 'Create My Campaign'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.onUploadNewCampaign();
      } 
    });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Congratulations!',
      content: {
        heading: 'You are now the proud owner of a spectacular campaign. May you receive countless entries. Redirecting you to your campaign dashboard...',
        btnText: 'Easy-Peasy'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }


  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorStep1() {
    this.modalOptionsErrorInput.data = {
      heading: 'Wait just one second...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two. This is preventing us from moving on to step #2.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required fields.',
        fix2: 'Look for any red text screaming at you.',
        fix3: 'Try to touch your elbow with your tongue.',
        btnText: 'Let\'s see'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep1Taken() {
    this.modalOptionsErrorInput.data = {
      heading: 'Wait just one second...',
      content: {
        heading: 'The campaign name that you have provided is already in use by one of your other campaigns.',
        suggest: 'Suggested Actions:',
        fix1: 'Please use a different campaign name.',
        fix2: 'Try to add slight variations to remove the conflict.',
        fix3: 'Always remember to Never say "Always" and "Never".',
        btnText: 'Let\'s see'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep1Time() {
    this.modalOptionsErrorInput.data = {
      heading: 'Warning: Space-Time Violation!',
      content: {
        heading: 'I have just received confirmation that you want to end your campaign before it has even started. This is a direct violation of Section 356, Par.97 of the Laws of Time.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that the start date is equal to or before the ending date.',
        fix2: 'Ensure that the end date is equal to or after the starting date.',
        fix3: 'Don\'t take the little things in life for granted.',
        btnText: 'Crisis Averted'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep2() {
    this.modalOptionsErrorInput.data = {
      heading: 'Wait just one second...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two. This is preventing us from moving on to step #3.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required fields.',
        fix2: 'Look for any red text screaming at you.',
        fix3: 'Always wear sunscreen when outside on hot days.',
        btnText: 'Let\'s see'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3() {
    this.modalOptionsErrorInput.data = {
      heading: 'Keyword problems!',
      content: {
        heading: 'What did the laptop say to the dentist? - "My Bluetooth hurts". No, but seriously, there is an error with your keyword.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you provide a keyword.',
        fix2: 'Ensure that you provide a description.',
        fix3: '*Keywords may not contain any spaces.',
        btnText: 'Fix Keyword'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3NoShortcode() {
    this.modalOptionsErrorInput.data = {
      heading: 'Task failed successfully.',
      content: {
        heading: 'It seems as though you might have slipped through the cracks and never chose a shortcode.',
        suggest: 'Suggested Actions:',
        fix1: 'Please go back to Step #2 and select a shortcode value.',
        fix2: 'After selecting a shortcode value come back to Step #3.',
        fix3: 'Try to add your shortcode again.',
        btnText: 'Lets go'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3MaxKeywords() {
    this.modalOptionsErrorInput.data = {
      heading: 'Task failed successfully.',
      content: {
        heading: 'While I admire your drive, we can only allow each campaign to have a maximum of three (3x) keywords.',
        suggest: 'Suggested Actions:',
        fix1: 'Delete one of your 3x keywords to make room for one.',
        fix2: 'Only have 3x keywords.',
        fix3: 'If you really need more - contact us and we\'ll make an exception just for you.',
        btnText: 'Oh Darn'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3KeywordTaken() {
    this.modalOptionsErrorInput.data = {
      heading: 'What are the odds?',
      content: {
        heading: 'There are around 218 632 words in the Oxford English Dictionary‬. Unfortunately, "' + this.ckeyw_word.value + '" is already being used by another campaign on the selected shortcode.',
        suggest: 'Suggested Actions:',
        fix1: 'Consider using a different keyword.',
        fix2: 'Consider using a different shortcode.',
        fix3: '',
        btnText: 'it happens'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3KeywordUsed() {
    this.modalOptionsErrorInput.data = {
      heading: 'Add keyword failed successfully.',
      content: {
        heading: '"' + this.ckeyw_word.value + '" is currently already in your keyword list. To keep everything nice and clean I can not allow you to add duplicate keywords.',
        suggest: 'Suggested Actions:',
        fix1: 'Don\'t use the same keyword multiple times.',
        fix2: 'Some people like to add slight variations of the same keyword in case of typing errors on the user\'s side.',
        fix3: 'Try removing "' + this.ckeyw_word.value + '" from your current list then you can add "' + this.ckeyw_word.value + '".',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep3NoKeyword() {
    this.modalOptionsErrorInput.data = {
      heading: 'No keyword found.',
      content: {
        heading: 'You did not provide a keyword. Because you are using a shared shortcode you are required to provide at least one keyword.',
        suggest: 'Suggested Actions:',
        fix1: 'Provide at least one keyword.',
        fix2: 'You can even provide two or three keywords.',
        fix3: 'Select a dedicated shortcode.',
        btnText: 'on it'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep4WinnerCrit() {
    this.modalOptionsErrorInput.data = {
      heading: 'Wait just one second...',
      content: {
        heading: 'It seems like someone might have forgotten about a field or two. This is preventing us from moving on to step #5.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided all the required fields.',
        fix2: 'Look for any red text screaming at you.',
        fix3: 'Then try again.',
        btnText: 'Let\'s see'
      }
    } 
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep4Email() {
    this.modalOptionsErrorInput.data = {
      heading: '404 Email not found.',
      content: {
        heading: 'I can\'t seem to find the email address you provided for receiving daily reports.',
        suggest: 'Suggested Actions:',
        fix1: 'Provide a valid email address for receiving daily reports.',
        fix2: 'Multiple email addresses should be separated by ";" (semicolon).',
        fix3: 'Ensure that your keyboard is connected to your device.',
        btnText: 'Fix email'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep4Email2() {
    this.modalOptionsErrorInput.data = {
      heading: 'Report day not found.',
      content: {
        heading: 'It seems like you want a customized report but did not select any days to receive reports on.',
        suggest: 'Suggested Actions:',
        fix1: 'Select at least one day of the week.',
        fix2: 'If you want to receive reports everyday select "Get Daily Reports".',
        fix3: 'If you do not want any reports select "Get No Reports".',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep5() {
    this.modalOptionsErrorInput.data = {
      heading: 'Email address error.',
      content: {
        heading: 'It seems like you didn\'t provide a valid email address for receiving entry notifications.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you provide an email address.',
        fix2: 'Ensure that the provided email address is valid.',
        fix3: 'Multiple email addresses should be separated by ";" (semicolon).',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep6Keyword() {
    this.modalOptionsErrorInput.data = {
      heading: 'Oops... My bad.',
      content: {
        heading: 'I just realized that you did not provide any keywords. Please return to Step #3 and add at least one keyword.',
        suggest: 'Suggested Actions:',
        fix1: 'Go back to step #3 and add at least one keyword.',
        fix2: 'After adding at least one keyword come back and try again.',
        fix3: '',
        btnText: 'i forgive you'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep4WinnerOptions() {
    this.modalOptionsErrorInput.data = {
      heading: 'Oops... My bad.',
      content: {
        heading: 'I just realized that you did not provide any winner options. Please return to Step #4 and complete all required fields.',
        suggest: 'Suggested Actions:',
        fix1: 'Go back to step #4 and complete required fields.',
        fix2: 'Then come back & try again.',
        fix3: '',
        btnText: 'i forgive you'
      }
    } 
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorStep6TNC() {
    this.modalOptionsErrorInput.data = {
      heading: 'Terms & Conditions.',
      content: {
        heading: 'While you were busy creating your campaign I went ahead and read through the terms & conditions for you. You only need to accept them.',
        suggest: 'Suggested Actions:',
        fix1: 'Read through the terms & conditions and accept them.',
        fix2: 'Say that you have read through the terms & conditions and accept them.',
        fix3: 'Ask a colleague to read the terms & conditions for you and accept them.',
        btnText: 'I will read it now'
      }
    } 
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  //  ,{
  //   id: '',
  //   title: '',
  //   text: '.<br><br>\
  //   .<br><br>\
  //   <i>- </i><br><br>\
  //   <mark>- </mark>',
  //   attachTo: {element: '.t', on: 'top'},
  //   advanceOn: {selector: '.', event: 'click'},
  //   canClickTarget: false,
  //   modalOverlayOpeningPadding: 10,
  //   beforeShowPromise: function() { 
  //     return new Promise(function(resolve) {
  //       setTimeout(function() {
  //         resolve();
  //       }, 200);
  //     });
  //   },
  //   buttons: [
  //     {action() {return this.next();},text: 'Next',}
  //   ],
  // }

  ngAfterViewInit() {
  //   var that = this;
  //     tour.on('complete', () => {that.tutorialActive = false; that.stepper.resetAll(); that.ngOnInit()})
  //     tour.on('cancel', () => {that.tutorialActive = false; that.stepper.resetAll(); that.ngOnInit()})
  //     tour.addSteps([{
  //     id: 'start',
  //     title: 'Welcome',
  //     text: 'This is a quick tutorial.<br><br>\
  //     I will be your tour guide for this tutorial. Together we will navigate through the steps to create your very first campaign.<br><br>\
  //     <mark>- At some steps I will require you to interact with the page before we can move on. These required actions will be highlighted like this</mark>',
  //     buttons: [
  //       {action() {return this.next();},text: 'Start Tutorial'}
  //     ],
  //   },{
  //     id: 'step1',
  //     title: 'New Instant Winner Campaign',
  //     text: 'Create your very own campaign based on keywords sent from entries.<br><br>\
  //     Winners will be determined on a per-entry basis.<br><br>\
  //     <i>- "Keyword": a word or concept of great significance.</i><br><br>',
  //     attachTo: {element: '.s1t1', on: 'bottom'},
  //     canClickTarget: false,
  //     modalOverlayOpeningPadding: 10,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step2',
  //     title: 'Steps',
  //     text: 'This tutorial will guide you through the 7x steps you need to complete to successfully create your campaign.<br><br>\
  //     Step #1 will require you to provide some basic details on your campaign.<br><br>\
  //     <i>- Name, Description, Type, Start- & End Dates</i><br><br>',
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step3',
  //     title: 'Campaign Name',
  //     text: 'The first thing you should do is think of a catchy name that we can call your campaign.<br><br>\
  //     Your campaign\'s name will be used to uniquely identify it.<br><br>\
  //     <i>- Once you start having multiple campaigns you will be thankful for choosing descriptive names.</i><br><br>\
  //     <mark>- Please provide your campaign with a name before we can proceed.</mark>',
  //     attachTo: {element: '.s1t3', on: 'bottom'},
  //     buttons: [
  //       {action() { if(that.camp_name.invalid) {return} else {return this.next();}},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step4',
  //     title: 'Campaign Description',
  //     text: 'Now you need to describe your campaign.<br><br>\
  //     What is the campaign for, the goal, function or just something that you could easily recognize your campaign with.<br><br>\
  //     <i>- A description is considered as "good" if someone else were to read your description and understand the goal of your campaign.</i><br><br>\
  //     <mark>- Try providing a description for your campaign before we can proceed.</mark>',
  //     attachTo: {element: '.s1t4', on: 'top'},
  //     buttons: [
  //       {action() { if(that.camp_desc.invalid) {return} else {return this.next();}},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step5',
  //     title: 'Campaign Start Date',
  //     text: 'This indicates the date on which your campaign will go live.<br><br>\
  //     Once a campaign is live it will be able to start receiving entries.<br><br>',
  //     attachTo: {element: '.s1t5', on: 'top'},
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step6',
  //     title: 'Campaign End Date',
  //     text: 'This indicates the date on which your campaign will close.<br><br>\
  //     Once a campaign has been closed it will stop receiving any entries and respond with it\'s campaign closed message.<br><br>',
  //     attachTo: {element: '.s1t6', on: 'top'},
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step7',
  //     title: 'Selecting Start & End',
  //     text: 'Normally you would click on the respective fields to select start & ending dates.<br><br>\
  //     But for this tutorial I will insert the date values for you.<br><br>\
  //     <mark>- When you are ready click on "Insert" on this box and I will select dates. Then you may proceed.</mark>',
  //     attachTo: {element: '.s1t7', on: 'top'},
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return that.step1Form.setValue({
  //         camp_startDte: '3333-03-03', camp_endDte: '4444-04-04', camp_name: that.camp_name.value,
  //          camp_desc: that.camp_desc.value, flag_normAdv: that.flag_normAdv.value, ticket_code_msgPos: that.ticket_code_msgPos.value
  //         })},text: 'Insert'},
  //       {action() {if(that.step1Form.invalid) {return} return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step8',
  //     title: 'Campaign Type',
  //     text: 'Here you have 2x options: "Normal Campaign" & "Ticket-Based Campaign".<br><br>\
  //     Ticket Based Campaign: If your campaign will be running on unique code based entries ie: Barcode numbers, receipt numbers, etc.... <br><br>\
  //     Normal Campaign: If your campaign will not be using any unique code based entries.<br><br>\
  //     <i>- For this tutorial we will be setting up a ticket based campaign.</i><br><br>\
  //     <mark>- Please click on "Ticket-based Campaign" to proceed to the next step.</mark>',
  //     attachTo: {element: '.s1t8a', on: 'top'},
  //     advanceOn: {selector: '.s1t8b', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step9',
  //     title: 'Ticket Code Position',
  //     text: 'This number indicates the position of where the Unique Code will be in the entry SMS.<br><br>\
  //     Eg: If an entry message would be "Keyword Name Surname UniqueCode" then you would need to select "3" because the Code is then the third word after the keyword.<br><br>\
  //     <i>- We will keep this on "1" for setting up "Keyword UniqueCode"</i><br><br>',
  //     attachTo: {element: '.s1t9', on: 'top'},
  //     canClickTarget: false,
  //     modalOverlayOpeningPadding: 20,
  //     beforeShowPromise: function() { 
  //       return new Promise(function(resolve) {
  //         setTimeout(function() {
  //           resolve();
  //         }, 200);
  //       });
  //     },
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step10',
  //     title: 'Next',
  //     text: 'Now that you have completed step #1 you can click on "Next" to proceed.<br><br>\
  //     <mark>- Click on "Next" to proceed to the next step.</mark>',
  //     attachTo: {element: '.s1t10', on: 'top'},
  //     advanceOn: {selector: '.s1t10', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step11',
  //     title: 'Step #2 - SMS',
  //     text: 'This step will require you to provide a shortcode, and various reply messages.<br><br>\
  //     Let\'s have a look.<br><br>',
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step12',
  //     title: 'Campaign Short Code',
  //     text: 'A shortcode is the number to which all entries will be sent to for entering your campaign.<br><br>\
  //     Each shortcode has it\'s own value - This is what the entry is charged at.<br><br>\
  //     <mark>- Select a shortcode to proceed to the next step.</mark>',
  //     attachTo: {element: '.s2t12', on: 'top'},
  //     modalOverlayOpeningPadding: 20,
  //     beforeShowPromise: function() { 
  //       return new Promise(function(resolve) {
  //         setTimeout(function() {
  //           resolve();
  //         }, 200);
  //       });
  //     },
  //     buttons: [
  //       {action() {if(that.sc_id.invalid) {return} return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step13',
  //     title: 'Not Winner Reply Message:',
  //     text: 'This is the reply message that gets sent to a successful entry that did not win.<br><br>\
  //     The cost of this message is included in the shortcode value which the entree is charged.<br><br>\
  //     <mark>- Try to enter a suitable Not Winner Reply Message: before we can proceed.</mark>',
  //     attachTo: {element: '.s2t13', on: 'top'},
  //     buttons: [
  //       {action() { if(that.notWin_replyMsg.invalid) {return} else {return this.next();}},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step14',
  //     title: 'Winner Reply Message',
  //     text: 'This is the reply message that gets sent to any entry that has is confirmed as a winner.<br><br>\
  //     The cost of this message is included in the shortcode value which the entree is charged.<br><br>\
  //     <mark>- Try to enter a winner reply message before we can proceed.</mark>',
  //     attachTo: {element: '.s2t14', on: 'top'},
  //     buttons: [
  //       {action() {if(that.prize_won_replyMsg.invalid) {return} return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step15',
  //     title: 'Closed Campaign Reply Message',
  //     text: 'This is the reply message that gets sent to entries if the campaign is has already passed it\'s end date and is closed.<br><br>\
  //     The cost of this message is included in the shortcode value which the entree is charged.<br><br>\
  //     <mark>- Try to enter a suitable closed campaign reply message before we can proceed.</mark>',
  //     attachTo: {element: '.s2t15', on: 'top'},
  //     buttons: [
  //       {action() {if(that.camp_replyMsgEnd.invalid) {return} return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step16',
  //     title: 'Invalid Ticket Reply Message',
  //     text: 'This is the reply message that gets sent to entries if the Unique Code provided is not valid.<br><br>\
  //     The cost of this message is included in the shortcode value which the entree is charged.<br><br>\
  //     <mark>- Try to enter a suitable invalid ticket reply message before we can proceed.</mark>',
  //     attachTo: {element: '.s2t16', on: 'top'},
  //     buttons: [
  //       {action() {if(that.invalid_ticket_replyMsg.invalid) {return} return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step17',
  //     title: 'Used Ticket Reply Message',
  //     text: 'This is the reply message that gets sent to entries if the Unique Code provided has already been redeemed.<br><br>\
  //     The cost of this message is included in the shortcode value which the entree is charged.<br><br>\
  //     <mark>- Try to enter a suitable used ticket reply message before we can proceed.</mark>',
  //     attachTo: {element: '.s2t17', on: 'top'},
  //     buttons: [
  //       {action() {if(that.used_ticket_replyMsg.invalid) {return} return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step18',
  //     title: 'Invalid Message Reply Message',
  //     text: 'This is the reply message that gets sent to entries if the Message was not in the correct format.<br><br>\
  //     The cost of this message is included in the shortcode value which the entree is charged.<br><br>\
  //     <mark>- Try to enter a suitable invalid message reply message before we can proceed.</mark>',
  //     attachTo: {element: '.s2t18', on: 'top'},
  //     buttons: [
  //       {action() {if(that.invalid_msg_replyMsg.invalid) {return} return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step19',
  //     title: 'Next',
  //     text: 'After completing all required fields on step #2 page you may click on "Next" to proceed to the next step.<br><br>\
  //     <mark>- Click on "Next" when you are ready to go to step #3</mark>',
  //     attachTo: {element: '.s2t19', on: 'top'},
  //     advanceOn: {selector: '.s2t19', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step20',
  //     title: 'Step #3 - Keywords',
  //     text: 'On this step you will be required to provide at least 1x keyword and description.<br><br>\
  //     This keyword will be used to associate an entry with your campaign.<br><br>\
  //     <i>- No duplicate keywords are allowed on the same shortcode - regardless of the campaign.</i><br><br>',
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step21',
  //     title: 'Keyword',
  //     text: 'Before a keyword can be added it will be checked for possible conflicts.<br><br>\
  //     If a conflict is found you will have to use a different keyword.<br><br>\
  //     <i>- You require at least 1x keyword and may have up to 3x keywords.</i><br><br>\
  //     <mark>- Try to type in a suitable keyword before we can proceed.</mark>',
  //     attachTo: {element: '.s3t21', on: 'bottom'},
  //     beforeShowPromise: function() { 
  //       return new Promise(function(resolve) {
  //         setTimeout(function() {
  //           resolve();
  //         }, 200);
  //       });
  //     },
  //     buttons: [
  //       {action() {if(that.ckeyw_word.invalid) {return} return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step22',
  //     title: 'Keyword Description',
  //     text: 'After choosing a suitable keyword you need to provide a description for said keyword before it can be added.<br><br>\
  //     This description serves a similar purpose to that of the campaign description on step #1.<br><br>\
  //     <mark>- Try to type in a suitable description before we can proceed.</mark>',
  //     attachTo: {element: '.s3t22', on: 'bottom'},
  //     buttons: [
  //       {action() {if(that.ckeyw_desc.invalid) {return} return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step23',
  //     title: 'Add Keyword',
  //     text: 'Once you have provided both a keyword and a description you may click on "Add Keyword".<br><br>\
  //     This will check your keyword is already being used on the shortcode that you selected on step #2.<br><br>\
  //     <mark>- Click on "Add Short Code" to proceed.</mark>',
  //     attachTo: {element: '.s3t23', on: 'bottom'},
  //     advanceOn: {selector: '.s3t23', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step24',
  //     title: 'Keyword Table',
  //     text: 'Well done! It seems as if your keyword is available and has been added to your keyword list.<br><br>\
  //     You have the option of adding 2x additional keywords. You can also remove a keyword by clicking on "remove".<br><br>\
  //     <i>- For this tutorial we will only be adding this one keyword to your campaign.</i><br><br>',
  //     attachTo: {element: '.s3t24', on: 'top'},
  //     canClickTarget: false,
  //     beforeShowPromise: function() { 
  //       return new Promise(function(resolve) {
  //         setTimeout(function() {
  //           resolve();
  //         }, 200);
  //       });
  //     },
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step25',
  //     title: 'Next',
  //     text: 'When you are happy with your chosen keyword(s) you may click on "Next" to proceed.<br><br>\
  //     <mark>- Click on "Next" to proceed to step #4</mark>',
  //     attachTo: {element: '.s3t25', on: 'top'},
  //     advanceOn: {selector: '.s3t25', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step26',
  //     title: 'Step #4 - Winner Options',
  //     text: 'On this page you will need to setup how I will determine winners. You will also need to indicate the winner frequency, amount of prizes and if one number can win multiple times.<br><br>',
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step27',
  //     title: 'Winner Selection Type',
  //     text: 'All: Winners will be randomly selected throughout the entire lifetime of your campaign.<br><br>\
  //     Interval: Winners will be selected based on the interval which you choose. Eg. Every 19th entry will be a winner.<br><br>\
  //     Time Based: Winners will be selected based on time intervals. Note that a schedule needs to be provided to our IT team.<br><br>\
  //     <i>- We will be setting up this campaign based on Intervals.</i><br><br>\
  //     <mark>- Please select "Interval" to proceed</mark>',
  //     attachTo: {element: '.s4t27a', on: 'bottom'},
  //     advanceOn: {selector: '.s4t27b', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //     beforeShowPromise: function() { 
  //       return new Promise(function(resolve) {
  //         setTimeout(function() {
  //           resolve();
  //         }, 200);
  //       });
  //     }
  //   },{
  //     id: 'step28',
  //     title: 'Maximum Wins per Cell Phone Number',
  //     text: 'This field will indicate the amount of times a cellphone number can be selected as a winner.<br><br>\
  //     <i>- Eg: Setting this to "1" means each number can only win once.</i><br><br>\
  //     <mark>- Please enter win per cell count to proceed.</mark>',
  //     attachTo: {element: '.s4t28', on: 'bottom'},
  //     modalOverlayOpeningPadding: 10,
  //     buttons: [
  //       {action() { if(that.max_winPerCellno.invalid) {return} else {return this.next();}},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step29',
  //     title: 'Maximum Wins Per Cell Interval',
  //     text: 'This indicates the intervals of which winners will be chosen - If multiple winners are allowed per cellphone number.<br><br>\
  //     <mark>- Please select "Daily" to proceed. This means that the same cellphone number can be selected as a winner every day.</mark>',
  //     attachTo: {element: '.s4t29', on: 'top'},
  //     modalOverlayOpeningPadding: 10,
  //     buttons: [
  //       {action() { if(that.max_winPerCellno_type_id.invalid) {return} else {return this.next();}},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step30',
  //     title: 'Total Amount of Prizes',
  //     text: 'This field will indicate the total amount of prizes that you have to give away.<br><br>\
  //     <i>- This translates to the maximum amount of possible winners.</i><br><br>\
  //     <mark>- Please provide a prize count to proceed.</mark>',
  //     attachTo: {element: '.s4t30', on: 'top'},
  //     modalOverlayOpeningPadding: 10,
  //     buttons: [
  //       {action() { if(that.max_total_winners.invalid) {return} else {return this.next();}},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step31',
  //     title: 'Winner Intervals',
  //     text: 'Because you have selected "Interval" you have to provide a interval on which winners will be selected.<br><br>\
  //     <i>- Eg: If you go with "21" then every 21st entry will be selected as a winner.</i><br><br>\
  //     <mark>- Please provide a Winner interval to proceed.</mark>',
  //     attachTo: {element: '.s4t31', on: 'top'},
  //     modalOverlayOpeningPadding: 10,
  //     buttons: [
  //       {action() { if(that.per_entry_winnerCount.invalid) {return} else {return this.next();}},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step32',
  //     title: 'Prize Type',
  //     text: 'If your prizes are airtime vouchers you should select Airtime.<br><br>',
  //     attachTo: {element: '.s4t32', on: 'top'},
  //     modalOverlayOpeningPadding: 20,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step33',
  //     title: 'Next',
  //     text: 'When you are happy with your winner options you may click on "Next" to proceed.<br><br>\
  //     <mark>- Click on "Next" to proceed to step #5</mark>',
  //     attachTo: {element: '.s4t33', on: 'top'},
  //     advanceOn: {selector: '.s4t33', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step34',
  //     title: 'Step #5 - Reports',
  //     text: 'If you would like to receive email reports on your campaign stats you can set it up on this page.<br><br>',
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step35',
  //     title: 'Setting Up Reports',
  //     text: 'Here you can see there are 3x different options to choose.<br><br>\
  //     "Get No Reports" will opt you out of receiving any sort of reports via email.<br><br>\
  //     "Get Daily Reports" will automatically schedule reports to be sent to the email provided every day @ 7am.<br><br>\
  //     "Customize Reports" will allow you to select specific days & times on which to receive reports.<br><br>\
  //     <mark>- Please select "Get Daily Reports" to proceed</mark>',
  //     attachTo: {element: '.s5t35a', on: 'right'},
  //     advanceOn: {selector: '.s5t35b', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step36',
  //     title: 'Email Address',
  //     text: 'After selecting daily reports you need to provide an email address to receive reports on.<br><br>\
  //     You have the option of adding multiple email addresses.<br><br>\
  //     <i>- If you provide more than 1x email address you need to separate each of them with a semicolon (;).</i><br><br>\
  //     <mark>- Try providing an email address before we can proceed</mark>',
  //     attachTo: {element: '.s5t36', on: 'top'},
  //     beforeShowPromise: function() { 
  //       return new Promise(function(resolve) {
  //         setTimeout(function() {
  //           resolve();
  //         }, 200);
  //       });
  //     },
  //     modalOverlayOpeningPadding: 10,
  //     buttons: [
  //       {action() {if(that.camp_ReportMail.invalid) {return} else {return this.next();}},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step37',
  //     title: 'Next',
  //     text: 'When you are ready you may click on "Next" to proceed.<br><br>\
  //     <mark>- Click on "Next" to proceed to step #6</mark>',
  //     attachTo: {element: '.s5t37', on: 'top'},
  //     advanceOn: {selector: '.s5t37', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step38',
  //     title: 'Step #6 - SMS2Email',
  //     text: 'On this page you have the option of receiving email notifications every time your campaign receives an entry.<br><br>\
  //     This is ideal for campaigns where human response is required. i.e. A danger alert campaign.<br><br>\
  //     <i>- This feature is not recommended if you expect to have large amounts of daily entries.</i><br><br>',
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step39',
  //     title: 'Enable SMS2Email',
  //     text: 'To enable this service you simply select "Send me Emails for each entry received".<br><br>\
  //     Then you will be required to provide at least one email address.<br><br>\
  //     <mark>- To proceed please select "Send me Emails for each entry received"</mark>',
  //     attachTo: {element: '.s6t39b', on: 'bottom'},
  //     advanceOn: {selector: '.s6t39b', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step40',
  //     title: 'Email Address',
  //     text: 'After opting in for SMS2Email you need to provide an email address.<br><br>\
  //     You may also add multiple email addresses.<br><br>\
  //     <i>- If you add multiple addresses you need to separate them with semicolons (;).</i><br><br>\
  //     <mark>- Provide at least one email address to proceed.</mark>',
  //     attachTo: {element: '.s6t40', on: 'top'},
  //     modalOverlayOpeningPadding: 10,
  //     beforeShowPromise: function() { 
  //       return new Promise(function(resolve) {
  //         setTimeout(function() {
  //           resolve();
  //         }, 200);
  //       });
  //     },
  //     buttons: [
  //       {action() { if(that.email_to.invalid) {return} else {return this.next();}},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step41',
  //     title: 'CC Email Address',
  //     text: 'You have the option to provide email addresses to be CCed in these entry alert emails.<br><br>\
  //     This field is optional.<br><br>\
  //     <i>- Like with the previous field - If you provide multiple email addresses to CC they need to be separated by semicolons (;).</i><br><br>\
  //     <mark>- You now have the option to provide a CC email or to simply move on and leave this field open.</mark>',
  //     attachTo: {element: '.s6t41', on: 'top'},
  //     modalOverlayOpeningPadding: 10,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step42',
  //     title: 'Next',
  //     text: 'When you are ready you may click on "Next" to proceed.<br><br>\
  //     <mark>- Click on "Next" to proceed to step #7</mark>',
  //     attachTo: {element: '.s6t42', on: 'top'},
  //     advanceOn: {selector: '.s6t42', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'step43',
  //     title: 'Step #7 - Terms & Conditions',
  //     text: 'Congratulations! You have made it to the final step.<br><br>\
  //     The last hurdle between you and your campaign is to Read and Accept our Terms & Conditions.<br><br>',
  //     canClickTarget: false,
  //     buttons: [
  //       {action() {return this.next();},text: 'Next',}
  //     ],
  //   },{
  //     id: 'step44',
  //     title: 'Terms & Conditions',
  //     text: 'To read our Terms & Conditions you can click on the "Terms & Conditions" blue text.<br><br>\
  //     After reading through every page you can click on the checkbox to the left of "I Accept the...".<br><br>\
  //     <i>- I will know if you have actually read the terms & conditions.</i><br><br>\
  //     <mark>- When you are ready you can accept the T&Cs and we will proceed to a quick 70 mark T&C pop-quiz.</mark>',
  //     attachTo: {element: '.s7t44a', on: 'bottom'},
  //     advanceOn: {selector: '.s7t44b', event: 'click'},
  //   },{
  //     id: 'step45',
  //     title: 'Save Campaign',
  //     text: 'Once you click on this button your campaign will be created.<br><br>\
  //     ***It should be noted that this tutorial campaign will NOT be created and all data provided will be reset on completion of this tutorial***.<br><br>\
  //     <i>- You are now ready to craft your very own campaigns.</i><br><br>\
  //     <mark>- To proceed to the next step please click on "Save Campaign"</mark>',
  //     attachTo: {element: '.s7t45', on: 'top'},
  //     advanceOn: {selector: '.s7t45', event: 'click'},
  //     modalOverlayOpeningPadding: 10,
  //   },{
  //     id: 'end',
  //     title: 'Tutorial Completed',
  //     text: 'You have successfully & skillfully mastered the art of creating your own keyword campaign.<br><br>\
  //     This concludes our tutorial on Creating Keyword Campaigns.<br><br>\
  //     <i>- Thank you for taking the time to know me a little better.</i><br><br>',
  //     buttons: [
  //       {action() {return this.next();},text: 'End Tutorial',}
  //     ],
  //   }
  // ])
  }

  // tutorial() {
  //   this.ngOnInit();
  //   this.stepper.resetAll();
  //   this.tutorialActive = true;
  //   tour.start();
  // }
}
