import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ReportsCampaignsService } from '../../../services/reports-campaigns.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { XlsxService } from "../../../services/xlsx.service";
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-valid-entries',
  templateUrl: './valid-entries.component.html',
  styleUrls: ['./valid-entries.component.scss']
})
export class ValidEntriesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  public link: any;
  public downloadReady: boolean;
  public tutorialActive: boolean;
  public processing: boolean;
  public submitted: boolean
  private payload: any;
  private resp: any;
  public selectedCampaignId: any;
  public loading: boolean;
  public loadingfail: boolean;
	public selectedCampaignName: string;

  private campaigns: any = [];

  campaignForm: FormGroup;
  ticketForm: FormGroup;
  
  optionsCampaigns: Array<any>;

  constructor(
    private xlsx: XlsxService,
    private campaignService: ReportsCampaignsService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
		this.selectedCampaignName = '';
    this.loading = false;
    this.loadingfail = false;
    this.link = {};
    this.downloadReady = false;
    this.tutorialActive = false;
    this.processing = false;
    this.submitted = false;
    this.payload = {};
    this.selectedCampaignId = {};
    this.resp = {};
    this.campaigns = [];
    this.loadAllCampaigns();

    this.campaignForm = this.fb.group({
      camp_id: ['', Validators.required]
    });
    this.ticketForm = this.fb.group({
      ticket_code: ['', Validators.required]
    });
    this.selectedCampaign();

  }

  get p() {return this.ticketForm.controls; }
  get ticket_code() {return this.ticketForm.get('ticket_code'); }

  loadAllCampaigns() {
    this.campaignService.getUserInstantCampaigns()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp = data;
        for (let index = 0; index < this.resp.count; index++) {
            const element = this.resp.rows[index];
            this.campaigns.push({
              value: element.camp_id,
              label: element.camp_name,
            });
          }
        this.optionsCampaigns = this.campaigns;
        this.resp = {};
      },
      error => {
        this.loadingfail = true;
      }
    );
  }

  selectedCampaign() {
    this.campaignForm.get('camp_id').valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (value) => {
				this.selectedCampaignId = {camp_id: value};
				for (let index = 0; index < this.optionsCampaigns.length; index++) {
					const element = this.optionsCampaigns[index];
					if (element.value === value) {
						this.selectedCampaignName = element.label;
						break
					}
				}
    });
  }

  onDownloadReport() {
    this.submitted = true;
    if (this.campaignForm.invalid){
      this.openModalErrorInput();
      return
    }
    this.payload = {
      camp_id: this.selectedCampaignId.camp_id
    }

    this.xlsx.CampaignValidEntries(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
      this.link = data;

      if (this.link === 'No Entries Found') {
        this.openModalErrorNotFound();
        this.processing = false;
        this.submitted = false;
        return;
      }

     var newBlob = new Blob([this.link], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const data2 = window.URL.createObjectURL(newBlob);
      var dllink = document.createElement('a');
      dllink.href = data2;
      dllink.download = this.selectedCampaignName + '_Campaign_Valid_Entries_Report.xlsx';
      dllink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        dllink.remove();
    }, 100)
  }
    ); 
  }

  onRefresh() {
    this.ngOnDestroy();
    this.ngOnInit();
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Your report is ready!',
      content: {
        heading: 'I just finished compiling this report you requested. My apologies for taking so long - I tend to procrastinate a lot.',
        btnText: 'Awesome'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorInput() {
    this.modalOptionsErrorInput.data = {
      heading: 'There seems to be a problem...',
      content: {
        heading: 'You haven\'t provided me with a campaign to look up.',
        suggest: 'Suggested Actions:',
        fix1: 'Click on the campaign select.',
        fix2: 'Select one of your campaigns.',
        fix3: 'Then try again.',
        btnText: 'select campaign'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorNotFound() {
    this.modalOptionsErrorInput.data = {
      heading: 'The results are in...',
      content: {
        heading: 'I just finished the wild goose chase you sent me on. I couldn\'t even find a single entry.',
        suggest: 'Suggested Actions:',
        fix1: 'Give the entrees some more time to enter.',
        fix2: 'Advertise your campaign to raise awareness',
        fix3: 'Think of 3x things you are thankful for today.',
        btnText: 'Will do'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
