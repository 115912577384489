// **********************LIVE***************************
export const environment = {
    production: true,
    orgId:	'digichat',
    baseUrl:        'https://webapi.digichat.co.za',
    fileUpURL:      'https://webapi.digichat.co.za/v1/upload/',
    adminUrl:       'https://webapi.digichat.co.za/v1/admin',
    campaignsUrl:   'https://webapi.digichat.co.za/v1/campaigns',
    campaignUrl:    'https://webapi.digichat.co.za/v1/campaign',  
    smsInUrl:       'https://webapi.digichat.co.za/v1/sms_in',
    creditsUrl:     'https://webapi.digichat.co.za/v1/credits',
    dashboardUrl:   'https://webapi.digichat.co.za/v1/dashboard/campaigns',
    passwordUrl:    'https://webapi.digichat.co.za/v1/password/forgot/reset',
    liveReadUrl:    'https://webapi.digichat.co.za/v1/campaigns/reader/live',
    liveReadUrl2:   'https://webapi.digichat.co.za/v1/report/live/sms/reply',
    navbarUrl:      'https://webapi.digichat.co.za/v1/navbar',
    phonebooksUrl:  'https://webapi.digichat.co.za/v1/phonebooks',
    randomWinUrl:   'https://webapi.digichat.co.za/v1/campaign/randomwinner',
    smsUrl:         'https://webapi.digichat.co.za/v1/sms',
    usersUrl:       'https://webapi.digichat.co.za/v1/users',
    xlsxReportUrl:  'https://webapi.digichat.co.za/v1/report/xlsx',
    contactUsUrl:   'https://webapi.digichat.co.za/v1/contact/send',
    optoutUrl:		'https://webapi.digichat.co.za/v1/optout'
  };


  // **********************LOCAL***************************
  // export const environment = {
  //   production: false,
  //   orgId: 'digichat',
  //   baseUrl: 'http://localhost:3000',
  //   fileUpURL: 'http://localhost:3000/v1/upload/',
  //   adminUrl: 'http://localhost:3000/v1/admin',
  //   campaignsUrl: 'http://localhost:3000/v1/campaigns',
  //   campaignUrl: 'http://localhost:3000/v1/campaign',
  //   smsInUrl: 'http://localhost:3000/v1/sms_in',
  //   creditsUrl: 'http://localhost:3000/v1/credits',
  //   dashboardUrl: 'http://localhost:3000/v1/dashboard/campaigns',
  //   passwordUrl: 'http://localhost:3000/v1/password/forgot/reset',
  //   liveReadUrl: 'http://localhost:3000/v1/campaigns/reader/live',
  //   liveReadUrl2: 'http://localhost:3000/v1/report/live/sms/reply',
  //   navbarUrl: 'http://localhost:3000/v1/navbar',
  //   phonebooksUrl: 'http://localhost:3000/v1/phonebooks',
  //   randomWinUrl: 'http://localhost:3000/v1/campaign/randomwinner',
  //   smsUrl: 'http://localhost:3000/v1/sms',
  //   usersUrl: 'http://localhost:3000/v1/users',
  //   xlsxReportUrl: 'http://localhost:3000/v1/report/xlsx',
  //   contactUsUrl: 'http://localhost:3000/v1/contact/send',
  //   optoutUrl: 'http://localhost:3000/v1/optout'
  // };