import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const baseUrl = environment.fileUpURL + 'airtime';
@Injectable({
  providedIn: 'root'
})
export class AirtimeUploadService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };
  

  constructor(private httpClient: HttpClient) {}


  UploadAirtimeVoucher(file) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/read', file, this.httpOptions);
    }return;
  }


  commitAirtimeVoucher(file) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/commit', file, this.httpOptions);
    }return;
  }
}
