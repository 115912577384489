import { Injectable } from '@angular/core';
import Shepherd from "shepherd.js";
import { Router } from "@angular/router";
import { TutPhonebookViewService } from "../guide/tut-phonebook-view.service";

const phonebookTour = new Shepherd.Tour ({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: 'smooth', block: 'center' },
  }, 
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root'
})
export class TutPhonebookCreateService {

  constructor(private router: Router, private tutPhonebookView: TutPhonebookViewService) {}

  phonebookTour() {
    phonebookTour.on('complete', () => {
      this.router.navigate(['/sms/send/phone-book/view']);
      this.tutPhonebookView.phonebookTour2();
    })

    phonebookTour.addSteps([{
      id: 'start',
      title: 'Create & Manage Phone Books',
      text: 'This is an interactive tutorial and will cover both phonebook creation and effective management thereof.<br><br>\
      As per usual I have appointed myself to be your relentless tour guide. At the end you will have mastered your phone books.<br><br>\
      <mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {return this.next();},text: 'Next'}
      ],
    },{
      id: 'step1',
      title: 'Create New Phonebook',
			text: 'From this page you can create new phone books - quite self explanatory really.<br><br>\
      <i>- Because one phonebook is never enough.</i><br><br>',
      attachTo: {element: '.mainS10', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step2',
      title: 'Phonebook Name',
      text: 'Step 1 will require you to think up a neat name for your new phonebook.<br><br>\
      <i>- Once you have a name in mind - You can type it into this field.</i><br><br>\
      <mark>- Try typing in the first name that comes to mind now before we can continue.</mark>',
      attachTo: {element: '.pb2', on: 'top'},
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step3',
      title: 'Next',
      text: 'When you are happy with the name you can click on this button to continue to step 2.<br><br>\
      <mark>- Click on "Next" to proceed to the next step.</mark>',
      attachTo: {element: '.pb3', on: 'bottom'},
      advanceOn: {selector: '.pb3', event: 'click'},
      modalOverlayOpeningPadding: 5,
    },{
      id: 'stepCheck',
      title: 'What a name!',
      text: 'At this point you have either just thought up the best name ever OR you haven\'t given me anything to work with.',
      canClickTarget: false,
      buttons: [
				{action() {if(document.getElementById('errCheck') === null) {
          return this.show('step2')} else {
          return this.show('step4');  
          }},text: 'Next'}
      ],
    },{
      id: 'step4',
      title: 'Step 2 - Phonebook Columns',
      text: 'Step 2 will be selecting custom optional column names to give your phonebook that personalized touch.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step5',
      title: 'Number Field',
      text: 'This first field is mandatory and not editable. This represents your contact number column.<br><br>',
      attachTo: {element: '.pb5', on: 'bottom'},
      canClickTarget: false,
      beforeShowPromise: function() { 
        return new Promise(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step6',
      title: 'Optional Fields',
      text: 'You are provided with these 4x optional fields. Each representing a column of your phonebook.<br><br>\
      <i>- In the next steps to follow we will try to use 2 of these optional fields.</i><br><br>',
      attachTo: {element: '.pb6', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step7',
      title: 'Optional Field #1',
      text: 'Okay so here we have our first optional field.<br><br>\
      <i>- For the purpose of this tutorial I will require you to provide this field and the next.</i><br><br>\
      <mark>- Try typing in a descriptive column name now before we continue.</mark>',
      attachTo: {element: '.pb7', on: 'top'},
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step8',
      title: 'Optional Field #2',
      text: 'Great! Now on to the second optional field. As you can see all fields have suggested inputs.<br><br>\
      <i>- This one suggests that you use "Surname" as a value.</i><br><br>\
      <mark>- Try typing in any column name besides "Surname".</mark>',
      attachTo: {element: '.pb8', on: 'top'},
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step9',
      title: 'Optional Field #3',
      text: 'I will let you decide if you want to use this column and the next one or just let them be.<br><br>\
      If you do not want to have more than 2 optional columns you could just leave this field blank.<br><br>\
      <i>- It is up to you.</i><br><br>',
      attachTo: {element: '.pb9', on: 'top'},
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step10',
      title: 'Optional Field #4',
      text: 'This final optional column standing between you and your very first phonebook!<br><br>\
      <i>- Your call: Use it or leave it open?</i><br><br>',
      attachTo: {element: '.pb10', on: 'top'},
      modalOverlayOpeningPadding: 5,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step11',
      title: 'Navigation Buttons',
      text: 'These buttons allow you to navigate though the phonebook wizard.<br><br>\
      "Back": You guessed it - takes you back to the previous page where you could change your phonebook name.<br><br>\
      <i>- "Next": Takes you to the final review step. You chose an AWESOME name, so we will be going to the next step.</i><br><br>',
      attachTo: {element: '.pb11', on: 'top'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step12',
      title: 'Next Button',
      text: 'This button will take you to the preview step, the final step.<br><br>\
      <mark>- To proceed to the final step click on "Next"</mark>',
      attachTo: {element: '.pb12', on: 'bottom'},
      advanceOn: {selector: '.pb12', event: 'click'},
      modalOverlayOpeningPadding: 5,
    },{
      id: 'step13',
      title: 'Final Step',
      text: 'Well done! You are among the top 34% who made it this far.<br><br>\
      <i>- Here you just have to review and approve all your phonebook details.</i><br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step14',
      title: 'Review Details',
      text: 'Here you can see your chosen phonebook name, the columns that you chose and an example entry.<br><br>',
      attachTo: {element: '.pb14', on: 'top'},
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      beforeShowPromise: function() { 
        return new Promise(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step15',
      title: 'Finalize',
      text: 'If you are happy with this you may click on "Done".<br><br>\
      You now posses the knowledge to craft your own phone books!<br><br>\
      <i>- I really enjoyed this ;)</i><br><br>',
      attachTo: {element: '.pb15', on: 'bottom'},
      advanceOn: {selector: '.pb15', event: 'click'},
      modalOverlayOpeningPadding: 5,
    },{
      id: 'step16',
      title: 'Well Done! (1/2)',
      text: 'You have just successfully completed Part 1: Creating New Phone Books.<br><br>\
      <i>This concludes Part 1/2 of our tutorial so don\'t end now! I highly recommend that you complete part 2 Of this tutorial.</i><br><br>\
      <mark>- Click on "Manage Phone Books" to start part 2 of this tutorial where we will be dissecting the phonebook that you just made.</mark>',
      buttons: [
        {action() {return this.complete();},text: 'Part 2: Manage Phone Books'}
      ],
    },
  ]);

  
  phonebookTour.start();

    
  }
}

  
