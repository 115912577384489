import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { OptOutService } from '../../../services/optout.service';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-optout-modal',
  templateUrl: './optout-modal.component.html',
  styleUrls: ['./optout-modal.component.scss']
})
export class OptoutModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject<any> = new Subject();

  editNumberForm: FormGroup;
  public content: {ou_cell: string, ou_id: number, ougr_id: number};
  public headings: any;
  public title: string;
  public buttonText: any;
  public submitted: boolean;
  public numberDuplicate: boolean;
  public loading: boolean;
  public loadingtext: string;
  private payload: any;
  private payload2: any;
  private resp: any;

  constructor(public modalRef: MDBModalRef, private fb: FormBuilder, private optoutService: OptOutService, private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}



  ngOnInit() {
    this.numberDuplicate = false;
    this.resp = {};
    this.payload2 = {};
    this.submitted = false;
    this.loadingtext = '';
    this.editNumberForm = this.fb.group({
      ou_cell: [this.content.ou_cell, [Validators.required, Validators.pattern(/^(0|27)[0-9]{9}$/)]],
    });

    
  }

  get p() {return this.editNumberForm.controls; }
  get ou_cell() {return this.editNumberForm.get('ou_cell'); }

  onUpdateNumber() {
    this.submitted = true;

    if (this.ou_cell.invalid) {return;}

    this.payload = {
      ou_id: this.content.ou_id,
      ou_cell: this.ou_cell.value,
      ougr_id: this.content.ougr_id
    }

    this.checkIfDuplicateNumber()
  }

  checkIfDuplicateNumber() {
    if(this.content.ou_id === 0) {
			if (this.ou_cell.value[0] === '0') {
				this.payload2 = {
					ougr_id: this.content.ougr_id,
					cellArr: [this.ou_cell.value, this.ou_cell.value.replace(0, 27)]
				}
			} else if (this.ou_cell.value[0] === '2') {
				this.payload2 = {
					ougr_id: this.content.ougr_id,
					cellArr: [this.ou_cell.value, this.ou_cell.value.replace(27, 0)]
				}
			}
      
      this.optoutService.checkListForConflicts(this.payload2)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.resp = data;
          if (this.resp.count === 0) {
            this.numberDuplicate = false;
            this.updateNumber();
          } else {
            this.numberDuplicate = true;
            return
          }
        }
      )
    } else {
			if (this.ou_cell.value[0] === '0') {
				this.payload2 = {
					ougr_id: this.content.ougr_id,
					cellArr: [this.ou_cell.value, this.ou_cell.value.replace(0, 27)],
					ou_id: this.content.ou_id
				}
			} else if (this.ou_cell.value[0] === '2') {
				this.payload2 = {
					ougr_id: this.content.ougr_id,
					cellArr: [this.ou_cell.value, this.ou_cell.value.replace(27, 0)],
					ou_id: this.content.ou_id
				}
			}
      
      this.optoutService.checkListForConflictsOnlyEdit(this.payload2)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.resp = data;
          if (this.resp.count === 0) {
            this.numberDuplicate = false;
            this.updateNumber();
          } else {
            this.numberDuplicate = true;
            return
          }
        }
      )
    }

    
  }

  updateNumber(){
    if(this.content.ou_id !== 0) {
    this.loadingtext = 'Updating Number...'
    this.optoutService.updateNumber(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
      this.action.next(true);
      this.modalRef.hide();  
      }
      )
  } else {
    this.loadingtext = 'Creating Number...'
    this.optoutService.addNumber(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
      this.action.next(true);
      this.modalRef.hide();  
      })
  }
    
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
	}
}
