import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DatePipe } from '@angular/common';

import { ReportsSmsService } from "../../../services/reports-sms.service";
import {ExcelService} from '../../../services/excel.service';
import { XlsxService } from "../../../services/xlsx.service";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { LoaderService } from '../../../services/loader.service';


@Component({
  selector: 'app-sent-sms',
  templateUrl: './sent-sms.component.html',
  styleUrls: ['./sent-sms.component.scss']
})
export class SentSmsComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  public chartType: string = 'doughnut';
  public exportMonthName: string;

  public loaded: boolean;
  public downloadReady: boolean;
  public processing: boolean;

  public headTotal = ['Day', 'Total Sent', 'Delivered', 'Network Delivered', 'Rejected', 'Queued/Scheduled'];
  public sentArrAll: Array<any>;
  optionsSelect: Array<any>;

  private resp: any;
  private payload: any;
  public total: any;
  public delivered: any;
  public network: any;
  public rejected: any;
  public other: any;
  
  private exportArr: Array<any>
  public link: any;
  public loading: boolean;

  

  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = new Date(Date.now());
  tempDate = new Date();
  currentDate = this.pipe.transform(this.now, 'MMMM');
  oneMonthBack = this.pipe.transform(this.tempDate.setMonth(this.now.getMonth() - 1),'MMMM');
  twoMonthBack = this.pipe.transform(this.tempDate.setMonth(this.now.getMonth() - 2),'MMMM');
  threeMonthBack = this.pipe.transform(this.tempDate.setMonth(this.now.getMonth() - 3),'MMMM');

  public selectedMonth: string;
  
  // scheduledDate = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');

  public chartDatasets: Array<any> = [
    { data: [0, 0, 0, 0], label: 'Current Month' }
  ];

  public chartLabels: Array<any> = ['Delivered', 'Network Delivered', 'Rejected', 'Queued/Scheduled'];

  public chartColors: Array<any> = [
    {
      backgroundColor: ['#00C851', '#ffbb33', '#ff4444', '#4B515D'],
      hoverBackgroundColor: ['#007E33', '#FF8800', '#CC0000', '#3E4551'],
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  
  
  constructor(
    private reportService: ReportsSmsService,
    private excelService:ExcelService,
    private xlsx: XlsxService,
    private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
    this.exportArr = [];
    this.exportMonthName = '';
    this.selectedMonth = '0';
    this.loaded = false;
    this.downloadReady = false;
    this.processing = false;
    this.sentArrAll = [];
    this.resp = {};
    this.total = {}; this.delivered = {}; this.network = {}; this.rejected = {}; this.other = {};
    this.payload = {monthsBack: 0};
    this.link = {};
    this.getSelectedMonthOutSms();
    this.optionsSelect = [
      { value: '0', label: this.currentDate },
      { value: '1', label: this.oneMonthBack },
      { value: '2', label: this.twoMonthBack },
      { value: '3', label: this.threeMonthBack }
      ];
      this.exportMonthName = this.optionsSelect[0].label;
  }

  getSelectedMonth(event: any) {
    this.loaded = false;
    this.downloadReady = false;
    this.payload = {monthsBack: event};
    this.exportMonthName = this.optionsSelect[event].label;
    this.getSelectedMonthOutSms()
  }


  getSelectedMonthOutSms() {
    this.reportService.sentMessagesReport(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
       this.resp = data;
       
       this.total =       this.resp.mtotal;
       this.delivered =   this.resp.mdelivered;
       this.network =     this.resp.mnetwork;
       this.rejected =    this.resp.mrejected;
       this.other =       this.resp.mother;
       this.sentArrAll =  this.resp.sendArr;

       const newData = [this.delivered,this.network,this.rejected,this.other];
       this.chartDatasets = [
         {data: newData}
       ]
       this.loaded = true; 
      }
    );
    
  }

  onExport() {
    for (let index = 0; index < this.sentArrAll.length; index++) {
      const element = this.sentArrAll[index];
      this.exportArr[index] = {
        'Day': element.date,
        'Total Sent': element.totalcount,
        'Delivered': element.deliveredcount,
        'Network Delivered': element.networkcount,
        'Rejected': element.rejectedcount,
        'Queued/Scheduled': element.othercount
      }
    }
    this.excelService.exportAsExcelFile(this.exportArr, 'Sent_Messages_'+this.exportMonthName);
  }

  //WORKS !!!!!!!! Repeat for other components, services & Controllers ^_^
  onDownloadReport() {
    this.xlsx.SentSms(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.link = data
        var newBlob = new Blob([this.link], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        const data2 = window.URL.createObjectURL(newBlob);

        var dllink = document.createElement('a');
        dllink.href = data2;
        dllink.download = 'Sent_SMS_Report_' + this.optionsSelect[this.payload.monthsBack].label + '_2020.xlsx';
        dllink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data2);
          dllink.remove();
      }, 100);
      }
    ); 
  }

  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Your report is ready!',
      content: {
        heading: 'I just finished compiling this report you requested. My apologies for taking so long - I tend to procrastinate a lot.',
        btnText: 'Awesome'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorNotFound() {
    this.modalOptionsErrorInput.data = {
      heading: 'The results are in...',
      content: {
        heading: 'I just finished the wild goose chase you sent me on. There isn\'t even a single sent message for the selected month - But you probably already knew this.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have selected the correct month.',
        fix2: 'Send some messages.',
        fix3: 'Compliment 3x people today.',
        btnText: 'Will do'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  //  ,{
  //   id: '',
  //   title: '',
  //   text: '.<br><br>\
  //   .<br><br>\
  //   <i>- </i><br><br>\
  //   <mark>- </mark>',
  //   attachTo: {element: '.t', on: 'top'},
  //   advanceOn: {selector: '.', event: 'click'},
  //   canClickTarget: false,
  //   modalOverlayOpeningPadding: 10,
  //   beforeShowPromise: function() { 
  //     return new Promise(function(resolve) {
  //       setTimeout(function() {
  //         resolve();
  //       }, 200);
  //     });
  //   },
  //   buttons: [
  //     {action() {return this.next();},text: 'Next',}
  //   ],
  // }

  ngAfterViewInit() {
  }

}
