import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { ConfirmComponent } from '../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../modals/other/error/error.component';

import { UserService } from '../../../services/user.service';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  passwordForm: FormGroup;
  public submitted: boolean;
  private payload: any;
  public tutorialActive: boolean;
  public loading: boolean;

  constructor(
    private passwordService: UserService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}

  ngOnInit() {
    this.payload = {};
    this.tutorialActive = false;
    this.submitted = false;
    this.passwordForm = this.fb.group({
      user_pw: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      user_pw_cnf: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  updatePassword() {
    this.payload = {user_pw: this.gpassword.value}
      this.passwordService.updateAccountDetails(this.payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.openModalSuccess();
        this.ngOnInit();
      });
  }

  get p() {return this.passwordForm.controls; }
  get gpassword() {return this.passwordForm.get('user_pw'); }
  get gpasswordConf() {return this.passwordForm.get('user_pw_cnf'); }

  onChangePassword() {
    this.submitted = true;

    // stop if invalid
    if (this.passwordForm.invalid) {
      this.openModalError1();
      return;
    }
    if (this.gpassword.value === this.gpasswordConf.value) {
      this.openModalConfirm();
    } else {
      this.openModalError2();
      return;
    }
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', amount: 0, description: '', btnText: ''}
    }
  }

  openModalConfirm() {
    this.modalOptions.data = {
      heading: 'Are you sure?', 
      content: {
        heading: 'Are you sure you want to update your password?',
        amount: 0,
        description: '',
        btnText: 'Update'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.updatePassword();
      } 
    });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Update Successful',
      content: {
        heading: 'Your password has been updated successfully, remember it this time!',
        btnText: 'I will'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalError1() {
    this.modalOptionsErrorInput.data = {
      heading: 'Tsk Tsk Tsk...',
      content: {
        heading: 'The password that you have provided does not appear to be valid.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that your password is at least 6 characters long.',
        fix2: 'Ensure that you have provided both a password and a confirm password.',
        fix3: 'Try again.',
        btnText: 'Retry'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalError2() {
    this.modalOptionsErrorInput.data = {
      heading: 'Tsk Tsk Tsk...',
      content: {
        heading: 'It appears that you have provided two different passwords that do not match.',
        suggest: 'Suggested Actions:',
        fix1: 'Retype your desired password.',
        fix2: 'Then confirm your desired password exactly.',
        fix3: 'Try again.',
        btnText: 'Retry'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}

ngAfterViewInit() {
  
}


}
