import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

const baseUrl = environment.smsUrl + '/scheduled'

@Injectable({
  providedIn: 'root'
})
export class SmsScheduledUnnamedService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };


  constructor(private httpClient: HttpClient) {}

  getAllScheduledSms() {
    if (this.token !== null) {
        this.httpOptions = {
          headers: {
            'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
            'Content-Type': 'application/json'
          }
        };
        return this.httpClient.get(baseUrl + '/unnamed', this.httpOptions);
      }return;
}

deleteScheduledSms(id) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/unnamed/delete', id, this.httpOptions);
    }return;
}

SendNowScheduledSms(id) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/unnamed/now', id, this.httpOptions);
    }return;
}


getAllScheduledNames() {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(baseUrl + '/named', this.httpOptions);
    }return;
}

getAllScheduledSMSNamed(id) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/named', id, this.httpOptions);
    }return;
}


SendNowScheduledBatch(id) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(baseUrl + '/named/now', id, this.httpOptions);
    }return;
}


deleteScheduledBatch(id) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/named/delete', id, this.httpOptions);
    }return;
}


deleteOneScheduledMessage(id) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/named/delete/one', id, this.httpOptions);
    }return;
}

RescheduleScheduledBatch(id) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(baseUrl + '/named/reschedule', id, this.httpOptions);
    }return;
}
}
