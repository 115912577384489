import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

const apiURL = environment.creditsUrl;
const apiURLusers = environment.usersUrl;
@Injectable({
  providedIn: 'root'
})
export class CreditsService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };

  constructor(private httpClient: HttpClient) {}

  public getCredits() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(apiURL, this.httpOptions);
    }return;
  }

  /**
   * updateCreditBalance
   */
  public updateCreditBalance(deduct) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(apiURL + '/update/balance', deduct, this.httpOptions);
    }return;
  }

  public getCreditHistory() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(apiURL + '/log', this.httpOptions);
    }return;
  }

  public getCreditPackages() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(apiURL + '/packages', this.httpOptions);
    }return;
  }

  public purchaseCreditPackage(info) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(apiURL + '/purchase', info, this.httpOptions);
    }return;
	}
	
	public getUserCreditAlertDetails() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(apiURLusers + '/creditWarning', this.httpOptions);
    }return;
	}
	
	public updateUserCreditAlertDetails(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.put(apiURLusers + '/creditWarning', payload, this.httpOptions);
    }return;
  }
}
