import { Component, OnInit, OnDestroy, ViewChild, HostListener, EventEmitter, Output, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import {ExcelService} from '../../../../services/excel.service';
import { ConfirmComponent } from '../../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../../modals/other/error/error.component';
import { MdbTablePaginationComponent, MdbTableDirective } from 'ng-uikit-pro-standard';
import { OptOutService } from '../../../../services/optout.service';
import { NewOptoutModalComponent } from "../../../../modals/edit/new-optout-modal/new-optout-modal.component";


@Component({
  selector: 'app-optout-lists',
  templateUrl: './optout-lists.component.html',
  styleUrls: ['./optout-lists.component.scss']
})
export class OptoutListsComponent implements OnInit, OnDestroy, AfterViewInit {

	@Output() onEditList = new EventEmitter<{edit: boolean, ougr_name: string, ougr_id: number}>();
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  private ngUnsubscribe: Subject<any> = new Subject();
	modalRef: MDBModalRef;
	
	public lists: Array<any>;
  private listToExport2: Array<any>;

  public submitted: boolean;
	public edit: boolean;
	
	public searchText: string;
  public previous: string;

  private tempArr: any;
  private resp: any;
  private listToDelete: any;
  private listToEdit: any;
  private listToExport: any;
  

  private ougr_name: string;

  constructor(
		private optoutService: OptOutService,
    private cdRef: ChangeDetectorRef,
    private modalService: MDBModalService,
    private excelService:ExcelService
	) { }

	@HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {
		this.lists = [];
		
		this.searchText = '';
    this.previous = '';

    this.submitted = false;
    this.edit = false;

    this.tempArr = {};
    this.resp = {};
    this.listToDelete = {};
    this.listToEdit = {};
    this.listToExport = {};
    this.listToExport2 = [];

    this.ougr_name = '';
    this.getLists();
	}
	
	getLists() {
		this.optoutService.getUserListsAndCount()
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(
			data => {
				this.tempArr = data;
				for (let index = 0; index < this.tempArr.length; index++) {
					const element = this.tempArr[index];
					this.lists[index] = element;
					if(!this.lists[index].numbers){this.lists[index].numbers = 0}
				}
				this.tempArr = [];

				this.mdbTable.setDataSource(this.lists);
				this.lists = this.mdbTable.getDataSource();
				this.previous = this.mdbTable.getDataSource();
			}
		);
	}

	searchItems() {
		const prev = this.mdbTable.getDataSource();

		if (!this.searchText) {
			this.mdbTable.setDataSource(this.previous);
			this.lists = this.mdbTable.getDataSource();
		}

		if (this.searchText) {
			this.lists = this.mdbTable.searchLocalDataBy(this.searchText);
			this.mdbTable.setDataSource(prev);
		}

		this.mdbTablePagination.calculateFirstItemIndex();
		this.mdbTablePagination.calculateLastItemIndex();

		this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
			this.mdbTablePagination.calculateFirstItemIndex();
			this.mdbTablePagination.calculateLastItemIndex();
		});
	}

	onNewList() {
			this.modalRef = this.modalService.show(NewOptoutModalComponent);
	
			this.modalRef.content.action.subscribe( (result: any) => { 
				if (result === true) {
					this.openModalSuccess(); 
					this.ngOnInit();
				}
			 });
	}

	remove(id: any) {
		this.listToDelete = {
			ougr_id: this.lists[id].ougr_id
		};
		this.optoutService.deleteList(this.listToDelete)
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(data => {
			this.lists.splice(id, 1);
			this.openModalSuccessDelete();
			this.ngOnInit();
		});
}

onEdit(id: any) {
	this.onEditList.emit({
		edit: true,
		ougr_name: this.lists[id].ougr_name,
		ougr_id: this.lists[id].ougr_id
	});
	window.scroll(0,0);
}

onExport(id: any) {
	this.ougr_name = this.lists[id].ougr_name
	this.listToExport = { ougr_id: this.lists[id].ougr_id};
	this.optoutService.getListNumbers(this.listToExport)
	.pipe(takeUntil(this.ngUnsubscribe))
	.subscribe(
		data => {
			this.resp = data;
			for (let index = 0; index < this.resp.count; index++) {
				const element = this.resp.rows[index];
				this.listToExport2[index] = {
					'Number': element.ou_cell
				}
			}
			this.resp = {};
			this.excelService.exportAsExcelFile(this.listToExport2, 'OptOut_List_'+this.ougr_name);
			this.listToExport2 = [];
			this.ngOnInit();
		}
	)
}

modalOptions = {
	backdrop: true,
	keyboard: true,
	focus: true,
	show: false,
	ignoreBackdropClick: false,
	class: 'modal-dialog modal-notify modal-info',
	containerClass: 'modal fade',
	animated: true,
	data: {
			heading: '',
			content: { heading: '', amount: 0, description: '', btnText: ''}
	}
}

openModalConfirmDelete(id: any) {
	this.modalOptions.data = {
		heading: 'Really remove this opt out list?', 
		content: {
			heading: 'Are you sure you want to permanently remove this opt out list, which you have cleverly named: ',
			amount: 0,
			description: '"'+ this.lists[id].ougr_name +'"? This is a permanent action! All numbers contained herein will be lost forever...',
			btnText: 'I really want to'
		}
	}
	this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
	this.modalRef.content.action
	.pipe(takeUntil(this.ngUnsubscribe))
	.subscribe( (result: any) => { 
		if (result) {
			this.remove(id);
		} 
	});
}

modalOptionsSuccess = {
	backdrop: true,
	keyboard: true,
	focus: true,
	show: false,
	ignoreBackdropClick: false,
	class: 'modal-dialog modal-notify modal-success',
	containerClass: 'modal fade',
	animated: true,
	data: {
			heading: '',
			content: { heading: '', btnText: ''}
	}
}

openModalSuccessDelete() {
	this.modalOptionsSuccess.data = {
		heading: 'Taken care of!',
		content: {
			heading: 'The opt out list in question has ceased to exist. I have taken care of every single one of its numbers myself.',
			btnText: 'Good riddance'
		}
	}
	this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
}

openModalSuccess() {
	this.modalOptionsSuccess.data = {
		heading: 'More Lists!',
		content: {
			heading: 'I just verified and your List has been successfully created!',
			btnText: 'Acknowledge'
		}
	}
	this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
}

ngOnDestroy(): any {
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
}

ngAfterViewInit() {
	this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

	this.mdbTablePagination.calculateFirstItemIndex();
	this.mdbTablePagination.calculateLastItemIndex();
	this.cdRef.detectChanges();
}

}
